import React from "react";
import { useAuth } from "../Authentication/AuthContext";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoutes() {
  const { currentUser } = useAuth();
  return !currentUser ? <Outlet /> : <Navigate to="/dashboard/" />;
}

export default PrivateRoutes;
