import React from "react";
import Header from "./Header/Header";
import SideBar from "./SideBar/SideBar";
// import Home from "./Content/Home/Home";
import { Outlet } from "react-router-dom";
import Popup from "../Popup/Popup";
import { useSelector } from "react-redux";
import "./layout.css";
import Loader from "../Loader/Loader";

function Layout() {
  const popup = useSelector((state) => state.popupLoaderStates);
  const loader = useSelector((state) => state.popupLoaderStates.isLoaderActive);

  const body = (
    <div className="global">
      <div className="header-bar">
        <Header />
      </div>

      <div className="side-bar">
        <SideBar />
      </div>

      <div className="body-content">
        <div className="dynamic-content">
          <Outlet />
        </div>
      </div>

      <div className="footer">
        <p>
          <strong>Helpline:</strong> +88 01894 904121
        </p>
        <p>
          Copyright @2021 <span>Civil Aviation Authority of Bangladesh</span>
        </p>
      </div>
    </div>
  );

  const popupComponent = (
    <Popup
      title={popup.popupTitle}
      subTitle={popup.popupSubtitle}
      variety={popup.popupVariety}
    >
      {body}
    </Popup>
  );

  return (
    <>
      {" "}
      {loader ? (
        <Loader />
      ) : popup.isPopupActive ? (
        <>{popupComponent}</>
      ) : (
        <>{body}</>
      )}
    </>
  );
}

export default Layout;
