import React, { useState } from "react";
import logoOrganization from "../../../../../../assets/images/caabLogo.png";
import { useDispatch } from "react-redux";
import {
  applicationDataPartA,
  // applicationDataPartC,
  changeNextState,
  settingsData,
  resetApplicationState,
  resetApplicationData,
} from "../../../../../../redux/actions";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../../Authentication/AuthContext";
import useHttp from "../../../../../../Http/useHttp";
import { useTranslation } from "react-i18next";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// custom style
import styles from "./OrganizationCode.module.css";

const OrganizationCode = () => {
  const [input, setInput] = useState(new Array(5).fill(""));
  const [btnDisabled, setBtnDisabled] = useState(true);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const api = useHttp("authorized");
  const { logout } = useAuth();

  const handleChange = (e, index) => {
    const element = e.target;
    if (isNaN(element.value)) return false;
    setInput([
      ...input.map(
        (d, idx) => (idx === index ? element.value : d)
        // idx === index ? element.value.toUpperCase() : d //for upper case for alphanumeric
      ),
    ]);

    //Focus next input
    if (element.value !== "") {
      e.preventDefault();
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleRemove = (e, index) => {
    const element = e.target;
    if (isNaN(element.value)) return false;
    setInput([...input.map((d, idx) => (idx === index ? element.value : d))]);
    //Focus next input
    if (e.keyCode === 8 && e.currentTarget.value === "") {
      if (element.previousSibling) {
        element.previousSibling.focus();
        element.value = "";
      }
    }

    if (input[0] && input[1]) setBtnDisabled(false);
    else setBtnDisabled(true);
  };

  const handleOrganizationCode = () => {
    toast.warn(
      "Please Attach Color PDF Of Your Existing Airport Pass, Where Applicable.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
    // console.log(`${input[0]}${input[1]}${input[2]}`);
    setBtnDisabled(true);
    dispatch(resetApplicationState());
    dispatch(resetApplicationData());
    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        const resp = await api.get(
          `organization/${input[0]}${input[1]}${input[2]}${input[3]}${input[4]}`
        );
        // console.log(resp.data);
        const availableCards =
          resp.data.organization.organization_personal_cards.map((c) => ({
            id: c.id,
            name: c.name,
            fullName: c.full_name,
            limit: c.pivot.limit,
            used: c.pivot.used,
          }));

        dispatch(
          applicationDataPartA({
            orgCode: `${input[0]}${input[1]}${input[2]}${input[3]}${input[4]}`,
            orgId: resp.data.organization.id,
            availableCards: availableCards,
            availableSites: resp.data.sites,
          })
        );
        dispatch(changeNextState(1));
        dispatch(
          settingsData({
            organizationName: resp.data.organization.name,
            natureOfOrganization: resp.data.organization.nature,
          })
        );
        resolve();
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          if (error.response.data.status === 401) {
            reject("Not Authorized Please Login");
            logout();
          } else reject("Provide A Valid Code");
        } else reject("Unknown Error!");
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Organization Is Selected",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  return (
    <>
      <div className={styles.header}>
        <img
          src={logoOrganization}
          alt="Logo Organization"
          className={styles.headerImage}
        />
        <h4 className={styles.headerTitle}>
          {t("NEWAPPLICATION.organizationCode.title")}
        </h4>
        <p className={styles.headerSubtitle}>
          {t("NEWAPPLICATION.organizationCode.subTitle")}
        </p>
      </div>
      <div className={styles.inputArea}>
        {input.map((data, index) => {
          return (
            <input
              type="text"
              key={index}
              className={`${styles.inputField} mx-2`}
              maxLength="1"
              size="1"
              max="1"
              value={data}
              pattern="[0-9]{1}"
              onInput={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleRemove(e, index)}
            />
          );
        })}
      </div>
      <div className={styles.btnArea}>
        <button
          className={styles.blueBtn}
          onClick={() => handleOrganizationCode()}
          disabled={btnDisabled}
        >
          {t("NEWAPPLICATION.nextBtn")}
          <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />
        </button>
      </div>
    </>
  );
};

export default OrganizationCode;
