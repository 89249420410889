import React from "react";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Fontawesome Icons
import {
  faFileArchive,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";
// custom style
import styles from "./AllFileList.module.css";
const AllFileList = ({ acceptedFiles }) => {
  return (
    <>
      <aside className="mt-2">
        <ul className={styles.view}>
          <li className={styles.item}>
            <span className={`${styles.content} ms-3`}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faFileArchive}
              />
              <p className={`${styles.text} ms-2`}>
                {acceptedFiles.name}
              </p>
            </span>
            <div className={styles.btnArea}>
              <a
                href={acceptedFiles.value}
                target="_blank"
                rel="noreferrer noopener"
              >
                <FontAwesomeIcon
                  className={`${styles.iconBlue} me-4`}
                  icon={faCloudDownloadAlt}
                />
              </a>
            </div>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default AllFileList;
