import changeFormReducer from "./registrationForm";
import resetPasswordReducer from "./forgetPasswordForm";
import newApplicationStateTracker from "./newApplicationTracker";
import newApplicationData from "./newApplication";
import vehicleNewApplicationData from "./vehicleNewApplication";
import popupLoaderStates from "./popupLoaderStates";
import settingsData from "./settings";
import commonVariables from "./commonVariables";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  changeFormReducer,
  resetPasswordReducer,
  newApplicationStateTracker,
  newApplicationData,
  vehicleNewApplicationData,
  popupLoaderStates,
  settingsData,
  commonVariables,
});

export default rootReducer;
