import React from "react";
import Step from "./Step";
import "./stepWrapper.css";
import { useTranslation } from "react-i18next";
function StepWrapper(props) {
  const { t } = useTranslation();
  const { activeForm } = props; //form1=> success
  const steps = ["1", "2", "3"];

  const stepValue=(step)=>{
    switch(step) {
      case '1':
        return `${t("STEPPER.first")}`;
      case '2':
        return `${t("STEPPER.second")}`;
      case '3':
        return `${t("STEPPER.third")}`;
      default:
        return false;
    }
  }

  return (
    <div className="step-wrapper">
      {steps.map((step, index) => (
        <Step
          step={
            stepValue(step)
          }
          key={index}
          renderClass={
            activeForm.substring(4) === step
              ? "active-step"
              : activeForm.substring(4) > step
              ? "success"
              : null
          }
        />
      ))}

      {/* <Step step="1" renderClass="active-step" />
      <Step step="2" renderClass="" />
      <Step step="3" renderClass="" /> */}
    </div>
  );
}

export default StepWrapper;
