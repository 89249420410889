import React from "react";
import {
  Page,
  View,
  Text,
  Image,
  Document,
  StyleSheet,
  Link,
  PDFViewer,
} from "@react-pdf/renderer";
import profileImage from "../../../../assets/images/defaultUser.jpg";
import caabLogo from "../../../../assets/images/caabLogo.png";
import { useLocation } from "react-router-dom";

// custom style
import st from "./PdfDocument.module.css";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: "0.7in",
  },
  header: {
    // border: "1px solid red",
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    fontSize: 20,
    // fontWeight: "bold",
    marginLeft: 5,
  },
  logo: { width: "16mm", maxHeight: "16mm", marginRight: 5 },
  subHeaderText: {
    marginTop: 10,
    fontSize: 12,
    textAlign: "center",
    color: "grey",
  },
  profilePic: {
    right: "0.7in",
    top: 170,
    width: "25mm",
    height: "33mm",
    position: "absolute",
    border: "1px solid white",
  },
  partLabel: {
    marginTop: 10,
    fontSize: 14,
    color: "#0979f9",
  },
  hl: {
    height: 5,
    width: "45%",
    borderBottom: "1px solid #e3ebf6",
    marginTop: "8px",
    marginBottom: "10px",
  },
  part: {
    // border: "1px solid yellow",
    marginBottom: "20px",
  },
  partBody: {
    // border: "1px solid black",
    marginTop: "-5px",
  },
  selfDeclaration: {
    border: "2px solid #44c524",
    padding: "15px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  row: {
    // border: "1px solid red",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  twoCol: {
    // border: "1px solid green",
    display: "flex",
    flexDirection: "column",
    width: "45%",
    marginTop: "10px",
  },
  oneCol: {
    // border: "1px solid green",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "10px",
  },
  fieldLabel: {
    fontSize: 11,
  },
  fieldValue: {
    fontSize: 10,
  },
  fieldValueBox: {
    width: "100%",
    backgroundColor: "#e9ecef",
    borderRadius: "3px",
    padding: "8px",
    marginTop: "5px",
    border: "1px solid #ced4da",
  },
  bulletRow: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    // justifyContent: "center",
    paddingVertical: "3px",
    // border: "1px solid red",
    paddingRight: "5px",
  },
  bulletIcon: {
    height: "3px",
    width: "3px",
    // border: "2px solid black",
    backgroundColor: "#000",
    borderRadius: "50%",
    marginRight: "5px",
    marginTop: "4px",
  },
  iconContainer: {
    paddingVertical: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    height: "10px",
    width: "10px",
    borderRadius: "3px",
    border: "1px solid #0979f9",
    marginRight: "4px",
  },
  iconChecked: {
    height: "10px",
    width: "10px",
    borderRadius: "3px",
    backgroundColor: "#0979f9",
    marginRight: "4px",
  },
  signature: {
    border: "1px solid white",
    maxHeight: "115px",
    width: "150px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const PdfDocument = () => {
  const application = useLocation();
  const applicationData = application.state.data;

  const defaultImage =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=";

  const signature = application.state.signature
    ? application.state.signature
    : defaultImage;
  const profilePic = application.state.profilePic
    ? application.state.profilePic
    : profileImage;

  console.log(applicationData);

  const timeStamp = () => {
    const current = new Date();
    return current.toTimeString();
  };

  // const parseURI = async (d) => {
  //   var reader = new FileReader();
  //   reader.readAsDataURL(d);
  //   return new Promise((res, rej) => {
  //     reader.onload = (e) => {
  //       // console.log(e.target.result);
  //       res(e.target.result);
  //     };
  //   });
  // };

  // const getDataBlob = async (url) => {
  //   var res = await fetch(`http://165.22.96.93:8080/${url}`);
  //   var blob = await res.blob();
  //   var uri = await parseURI(blob);
  //   return uri;
  // };

  return (
    <div className={st.container}>
      <PDFViewer style={{ height: "100%", width: "100%" }}>
        <Document>
          <Page style={styles.body}>
            {/* ========== CAAB with Logo ========== */}
            <View style={styles.header}>
              <Image style={styles.logo} src={caabLogo} />
              <Text style={styles.headerText} fixed>
                Civil Aviation Authority Of Bangladesh
              </Text>
            </View>

            {/* ========== Sub Header ========== */}
            <Text style={styles.subHeaderText} fixed>
              Online Application Form For Airport Security Pass{" "}
              {applicationData.part_a.passType}
            </Text>

            {/* ========== Applicant Image applicationData.part_b.profilePic ========== */}
            <Image style={styles.profilePic} src={profilePic} />

            {/* ========== Part A ========== */}
            <View style={[styles.part, { marginTop: 40 }]}>
              <Text style={styles.partLabel}>
                Part - A : Application Details
              </Text>
              <View style={styles.hl}></View>
              <View style={styles.partBody}>
                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Organization Code</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_a.orgCode
                          ? applicationData.part_a.orgCode
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Type Of Card</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_a.passType
                          ? applicationData.part_a.passType
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Type Of Issue</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_a.issueType
                          ? applicationData.part_a.issueType
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>

                {/* ========== Row with One Col ========== */}
                {applicationData.part_a.passType === "ASP" && (
                  <View style={styles.row}>
                    <View style={styles.oneCol}>
                      <Text style={styles.fieldLabel}>Selected Airport</Text>
                      <View style={styles.fieldValueBox}>
                        <Text style={styles.fieldValue}>
                          {applicationData.part_a.siteName}
                        </Text>
                      </View>
                    </View>
                  </View>
                )}

                {applicationData.part_a.issueType !== "Initial" && (
                  <>
                    {/* ========== Row with Two Cols ========== */}
                    <View style={styles.row}>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>
                          Existing Pass Number{" "}
                        </Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_a.existingPassNumber
                              ? applicationData.part_a.existingPassNumber
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                      {applicationData.part_a.passType === "ASP" && (
                        <View style={styles.twoCol}>
                          <Text style={styles.fieldLabel}>Color Of Card</Text>
                          <View style={styles.fieldValueBox}>
                            <Text style={styles.fieldValue}>
                              {applicationData.part_a.colorOfPass
                                ? applicationData.part_a.colorOfPass
                                : "N/A"}
                            </Text>
                          </View>
                        </View>
                      )}

                      {applicationData.part_a.passType === "CMAP" && (
                        <View style={styles.twoCol}>
                          <Text style={styles.fieldLabel}>
                            Existing CMAP Category
                          </Text>
                          <View style={styles.fieldValueBox}>
                            <Text style={styles.fieldValue}>
                              {applicationData.part_a.existingCMAPcategory
                                ? applicationData.part_a.existingCMAPcategory
                                : "N/A"}
                            </Text>
                          </View>
                        </View>
                      )}
                    </View>
                    {/* ========== Row with One Cols ========== */}
                    {(applicationData.part_a.passType === "ASP" ||
                      applicationData.part_a.passType === "NAP") && (
                      <View style={styles.row}>
                        <View style={styles.oneCol}>
                          <Text style={styles.fieldLabel}>
                            Authorization/ Limitation
                          </Text>
                          <View style={styles.iconContainer}>
                            <View
                              style={
                                applicationData.part_a.A
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              A
                            </Text>
                            <View
                              style={
                                applicationData.part_a.B
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              B
                            </Text>
                            <View
                              style={
                                applicationData.part_a.C
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              C
                            </Text>
                            <View
                              style={
                                applicationData.part_a.D
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              D
                            </Text>
                            <View
                              style={
                                applicationData.part_a.E
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              E
                            </Text>
                            <View
                              style={
                                applicationData.part_a.R
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              R
                            </Text>
                            <View
                              style={
                                applicationData.part_a.T
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              T
                            </Text>
                            <View
                              style={
                                applicationData.part_a.two
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              2
                            </Text>
                            <View
                              style={
                                applicationData.part_a.three
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              3
                            </Text>
                            <View
                              style={
                                applicationData.part_a.four
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              4
                            </Text>
                            <View
                              style={
                                applicationData.part_a.five
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              5
                            </Text>
                            <View
                              style={
                                applicationData.part_a.six
                                  ? styles.iconChecked
                                  : styles.icon
                              }
                            ></View>
                            <Text
                              style={[
                                styles.fieldValue,
                                { marginRight: "10px" },
                              ]}
                            >
                              6
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                    {applicationData.part_a.issueType ===
                      "Re-issue (Lost /Stolen)" && (
                      <>
                        {/* ========== Row with Two Cols ========== */}
                        <View style={styles.row}>
                          <View style={styles.twoCol}>
                            <Text style={styles.fieldLabel}>GD Number </Text>
                            <View style={styles.fieldValueBox}>
                              <Text style={styles.fieldValue}>
                                {applicationData.part_a.gdNumber
                                  ? applicationData.part_a.gdNumber
                                  : "N/A"}
                              </Text>
                            </View>
                          </View>
                          <View style={styles.twoCol}>
                            <Text style={styles.fieldLabel}>Date Of Entry</Text>
                            <View style={styles.fieldValueBox}>
                              <Text style={styles.fieldValue}>
                                {applicationData.part_a.gdEntry
                                  ? applicationData.part_a.gdEntry
                                  : "N/A"}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {/* ========== Row with Two Cols ========== */}
                        <View style={styles.row}>
                          <View style={styles.twoCol}>
                            <Text style={styles.fieldLabel}>
                              Police Station Name{" "}
                            </Text>
                            <View style={styles.fieldValueBox}>
                              <Text style={styles.fieldValue}>
                                {applicationData.part_a.psName
                                  ? applicationData.part_a.psName
                                  : "N/A"}
                              </Text>
                            </View>
                          </View>
                          {/* <View style={styles.twoCol}>
                  <Text style={styles.fieldLabel}>Date Of Entry</Text>
                  <View style={styles.fieldValueBox}>
                    <Text style={styles.fieldValue}>
                      {applicationData.part_a.gdEntry}
                    </Text>
                  </View>
                </View> */}
                        </View>
                      </>
                    )}
                  </>
                )}
              </View>
            </View>

            {/* ========== Part B Personal Information ========== */}
            <View style={styles.part}>
              <Text style={styles.partLabel}>Part - B : Personal Details</Text>
              <View style={styles.hl}></View>
              <View style={styles.partBody}>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Name</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.name}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Father's Name</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.fathersName
                          ? applicationData.part_b.fathersName
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Mother's Name</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.mothersName
                          ? applicationData.part_b.mothersName
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Spouse Name</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.spouseName
                          ? applicationData.part_b.spouseName
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Nationality</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.nationality
                          ? applicationData.part_b.nationality
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>National Id Number</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.nid
                          ? applicationData.part_b.nid
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Date Of Birth</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.dob
                          ? applicationData.part_b.dob
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Mobile Number</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.mobile
                          ? applicationData.part_b.mobile
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Gender</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.gender
                          ? applicationData.part_b.gender
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Blood Group</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.bloodGroup
                          ? applicationData.part_b.bloodGroup
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Email</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.email
                          ? applicationData.part_b.email
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>
                      Police Verification Valid Till
                    </Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.policeVerificationValidTill
                          ? applicationData.part_b.policeVerificationValidTill
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>GSAT Valid Till</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.gsatValidTill
                          ? applicationData.part_b.gsatValidTill
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/* ========== Part B Applicant Address ========== */}
            <View style={styles.part}>
              <Text style={[styles.partLabel, { color: "#95bce0" }]}>
                Permanent Address
              </Text>
              <View style={styles.partBody}>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>House Number</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.permanentAddress.house
                          ? applicationData.part_b.address.permanentAddress
                              .house
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Street / Road No</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.permanentAddress.street
                          ? applicationData.part_b.address.permanentAddress
                              .street
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>City / Town</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.permanentAddress.city
                          ? applicationData.part_b.address.permanentAddress.city
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Police Station</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.permanentAddress.ps
                          ? applicationData.part_b.address.permanentAddress.ps
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>District</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.permanentAddress.dist
                          ? applicationData.part_b.address.permanentAddress.dist
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Post Code</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.permanentAddress.zip
                          ? applicationData.part_b.address.permanentAddress.zip
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.part}>
              <Text style={[styles.partLabel, { color: "#95bce0" }]}>
                Present Address
              </Text>
              <View style={styles.partBody}>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>House Number</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.presentAddress.house
                          ? applicationData.part_b.address.presentAddress.house
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Street / Road No</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.presentAddress.street
                          ? applicationData.part_b.address.presentAddress.street
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>City / Town</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.presentAddress.city
                          ? applicationData.part_b.address.presentAddress.city
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Police Station</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.presentAddress.ps
                          ? applicationData.part_b.address.presentAddress.ps
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>District</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.presentAddress.dist
                          ? applicationData.part_b.address.presentAddress.dist
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Post Code</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_b.address.presentAddress.zip
                          ? applicationData.part_b.address.presentAddress.zip
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/* ========== Part C ========== */}
            <View style={styles.part}>
              <Text style={styles.partLabel}>
                Part - C : Details Of Employment And Responsibilities
              </Text>
              <View style={[styles.hl, { width: "70%" }]}></View>
              <View style={styles.partBody}>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Organization Name</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_c.organizationName
                          ? applicationData.part_c.organizationName
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>
                      Nature Of Organization
                    </Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_c.natureOfOrganization
                          ? applicationData.part_c.natureOfOrganization
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {(applicationData.part_a.passType === "NIAC" ||
                  applicationData.part_a.passType === "NAP") && (
                  <>
                    {/* ========== Row with Two Cols ========== */}
                    <View style={styles.row}>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>Division Name</Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_c.divisionName
                              ? applicationData.part_c.divisionName
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>
                          Date Of Joining CAAB
                        </Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_c.dateOfJoining
                              ? applicationData.part_c.dateOfJoining
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </>
                )}

                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.twoCol}>
                    {applicationData.part_c.natureOfOrganization ===
                    "Contractor (COTN)" ? (
                      <Text style={styles.fieldLabel}>Company Name</Text>
                    ) : (
                      <Text style={styles.fieldLabel}>Designation</Text>
                    )}

                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_c.designation
                          ? applicationData.part_c.designation
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.twoCol}>
                    <Text style={styles.fieldLabel}>Current Position</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_c.currentPosition
                          ? applicationData.part_c.currentPosition
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>

                {/* ========== Row with One Cols ========== */}
                <View style={styles.row}>
                  <View style={styles.oneCol}>
                    <Text style={styles.fieldLabel}>Type Of Employment</Text>
                    <View style={styles.fieldValueBox}>
                      <Text style={styles.fieldValue}>
                        {applicationData.part_c.typeOfEmployment
                          ? applicationData.part_c.typeOfEmployment
                          : "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ========== Row with One Cols ========== */}
                {applicationData.part_c.typeOfEmployment ===
                  "Other (Specify)" && (
                  <View style={styles.row}>
                    <View style={styles.oneCol}>
                      <Text style={styles.fieldLabel}>Specify</Text>
                      <View style={styles.fieldValueBox}>
                        <Text style={styles.fieldValue}>
                          {applicationData.part_c.typeOfEmploymentOther
                            ? applicationData.part_c.typeOfEmploymentOther
                            : "N/A"}
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
                {/* ========== Row with Two Cols ========== */}
                <View style={styles.row}>
                  {applicationData.part_a.passType === "ASP" && (
                    <View style={styles.twoCol}>
                      <Text style={styles.fieldLabel}>Nature Of Duty</Text>
                      <View style={styles.fieldValueBox}>
                        <Text style={styles.fieldValue}>
                          {applicationData.part_c.natureOfDuty
                            ? applicationData.part_c.natureOfDuty
                            : "N/A"}
                        </Text>
                      </View>
                    </View>
                  )}
                  {(applicationData.part_a.passType === "ASP" ||
                    applicationData.part_a.passType === "CMAP") && (
                    <View style={styles.twoCol}>
                      <Text style={styles.fieldLabel}>Scope Of Operation</Text>
                      <View style={styles.fieldValueBox}>
                        <Text style={styles.fieldValue}>
                          {applicationData.part_c.scopeOfOperation
                            ? applicationData.part_c.scopeOfOperation
                            : "N/A"}
                        </Text>
                      </View>
                    </View>
                  )}
                </View>
                {/* ========== Row with One Cols ========== */}
                {applicationData.part_a.passType === "ASP" && (
                  <View style={styles.row}>
                    <View style={styles.oneCol}>
                      <Text style={styles.fieldLabel}>
                        Location Of Primary Duty Within The Airport
                      </Text>
                      <View style={styles.fieldValueBox}>
                        <Text style={styles.fieldValue}>
                          {applicationData.part_c.locationOfDuty
                            ? applicationData.part_c.locationOfDuty
                            : "N/A"}
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
                {applicationData.part_a.passType === "NIAC" && (
                  <>
                    {/* ========== Row with One Cols ========== */}
                    <View style={styles.row}>
                      <View style={styles.oneCol}>
                        <Text style={styles.fieldLabel}>
                          Type Of Authorization
                        </Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_c.typeOfAuthorization
                              ? applicationData.part_c.typeOfAuthorization
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {/* ========== Row with One Cols ========== */}
                    {applicationData.part_c.typeOfAuthorization !==
                      "Security_AVSEC" && (
                      <View style={styles.row}>
                        <View style={styles.oneCol}>
                          <Text style={styles.fieldLabel}>Specify</Text>
                          <View style={styles.fieldValueBox}>
                            <Text style={styles.fieldValue}>
                              {applicationData.part_c.typeOfAuthorizationSafety
                                ? applicationData.part_c
                                    .typeOfAuthorizationSafety
                                : "N/A"}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                  </>
                )}
                {/* ========== Row with One Cols ========== */}
                {applicationData.part_a.passType === "NAP" && (
                  <View style={styles.row}>
                    <View style={styles.oneCol}>
                      <Text style={styles.fieldLabel}>
                        Describe The Nature Of Duty Here (Other Than CAAB
                        Members)
                      </Text>
                      <View style={styles.fieldValueBox}>
                        <Text style={styles.fieldValue}>
                          {applicationData.part_c.natureOfDutyDescription
                            ? applicationData.part_c.natureOfDutyDescription
                            : "N/A"}
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
              </View>
            </View>

            {(applicationData.part_a.passType === "ASP" ||
              applicationData.part_a.passType === "CMAP") && (
              <>
                {/* ========== Part D Foreign National ========== */}
                <View style={styles.part}>
                  <Text style={styles.partLabel}>
                    Part - D : Additional Information Of Foreign National
                  </Text>
                  <View style={[styles.hl, { width: "70%" }]}></View>
                  <View style={styles.partBody}>
                    {/* ========== Row with One Cols ========== */}
                    <View style={styles.row}>
                      <View style={styles.oneCol}>
                        <Text style={styles.fieldLabel}>Nationality</Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_e.foreignNationality
                              ? applicationData.part_e.foreignNationality
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {/* ========== Row with One Cols ========== */}
                    {applicationData.part_a.passType === "ASP" && (
                      <View style={styles.row}>
                        <View style={styles.oneCol}>
                          <Text style={styles.fieldLabel}>
                            Nature Of Assignment In Bangladesh
                          </Text>
                          <View style={styles.fieldValueBox}>
                            <Text style={styles.fieldValue}>
                              {applicationData.part_e.natureOfAssignmentBD
                                ? applicationData.part_e.natureOfAssignmentBD
                                : "N/A"}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}
                  </View>
                </View>

                {/* ========== Part D Passport/Travel Document ========== */}

                <View style={styles.part}>
                  <Text style={[styles.partLabel, { color: "#95bce0" }]}>
                    Passport / Travel Document
                  </Text>
                  <View style={styles.partBody}>
                    {/* ========== Row with Two Cols ========== */}
                    <View style={styles.row}>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>Number</Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_e.passportDocumentNumber
                              ? applicationData.part_e.passportDocumentNumber
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>Place Of Issue</Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_e.placeOfIssue
                              ? applicationData.part_e.placeOfIssue
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {/* ========== Row with Two Cols ========== */}
                    <View style={styles.row}>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>Expiry Date</Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_e.passportDocumentExpiryDate
                              ? applicationData.part_e
                                  .passportDocumentExpiryDate
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                {/* ========== Part D Visa ========== */}
                <View style={styles.part}>
                  <Text style={[styles.partLabel, { color: "#95bce0" }]}>
                    Visa
                  </Text>
                  <View style={styles.partBody}>
                    {/* ========== Row with Two Cols ========== */}
                    <View style={styles.row}>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>Type Of Visa</Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_e.typeOfVisa
                              ? applicationData.part_e.typeOfVisa
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>Validity Of Visa</Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_e.visaExpiryDate
                              ? applicationData.part_e.visaExpiryDate
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {/* ========== Row with Two Cols ========== */}
                    <View style={styles.row}>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>Visa Number</Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_e.visaNumber
                              ? applicationData.part_e.visaNumber
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                {/* ========== Part D Work Permit ========== */}
                <View style={styles.part}>
                  <Text style={[styles.partLabel, { color: "#95bce0" }]}>
                    Work Permit Where Applicable
                  </Text>
                  <View style={styles.partBody}>
                    {/* ========== Row with Two Cols ========== */}
                    <View style={styles.row}>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>Number</Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_e.workPermitNumber
                              ? applicationData.part_e.workPermitNumber
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.twoCol}>
                        <Text style={styles.fieldLabel}>
                          Validity Of Work Permit
                        </Text>
                        <View style={styles.fieldValueBox}>
                          <Text style={styles.fieldValue}>
                            {applicationData.part_e.workPermitExpiryDate
                              ? applicationData.part_e.workPermitExpiryDate
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            )}

            {/* ========== Part E Applicants Declaration ========== */}
            <View style={styles.part}>
              {/* <Link src={`${applicationData.part_b.policeVerificationFile}`}>
                Police Verification
              </Link> */}

              <Text style={styles.partLabel}>
                Part -{" "}
                {applicationData.part_a.passType === "ASP" ||
                applicationData.part_a.passType === "CMAP"
                  ? "E"
                  : "D"}{" "}
                : Applicants Declaration
              </Text>
              <View style={styles.hl}></View>
              <View style={styles.partBody}>
                <Text style={[styles.fieldLabel, { marginVertical: "10px" }]}>
                  The Information I Have Provided In This Application Is True
                  And Correct. I Understand That
                </Text>
                <View style={styles.bulletContainer}>
                  <View style={styles.bulletRow}>
                    <View style={styles.bulletIcon}></View>
                    <Text style={styles.fieldValue}>Is Not Transferrable.</Text>
                  </View>
                  <View style={styles.bulletRow}>
                    <View style={styles.bulletIcon}></View>
                    <Text style={styles.fieldValue}>
                      Shall Be Used For Duty Purposes Only
                    </Text>
                  </View>
                  <View style={styles.bulletRow}>
                    <View style={styles.bulletIcon}></View>
                    <Text style={styles.fieldValue}>
                      Shall Be Presented For Verification On Entry To And Where
                      Required Exit From Airside, SRA, Sterile Areas And
                      Security Designated Areas.
                    </Text>
                  </View>
                  <View style={styles.bulletRow}>
                    <View style={styles.bulletIcon}></View>
                    <Text style={styles.fieldValue}>
                      Shall Be Worn In A Clearly Visible And Readable Manner On
                      The Outer Garments, Chest Height At All Times When
                      Entering, Remaining And Exiting The Airside, Security
                      Restricted Areas Or Designated Security Areas of Airport.
                    </Text>
                  </View>
                  <View style={styles.bulletRow}>
                    <View style={styles.bulletIcon}></View>
                    <Text style={styles.fieldValue}>
                      Shall Not Be Left Unattended.
                    </Text>
                  </View>
                  <View style={styles.bulletRow}>
                    <View style={styles.bulletIcon}></View>
                    <Text style={styles.fieldValue}>
                      Shall Remain A Property of CAAB.
                    </Text>
                  </View>
                  <View style={styles.bulletRow}>
                    <View style={styles.bulletIcon}></View>
                    <Text style={styles.fieldValue}>
                      Shall Be Returned Immediately On Expiry, At The End of
                      Duty / Assignment.
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.selfDeclaration}>
              <Text style={[styles.fieldLabel, { marginVertical: "10px" }]}>
                Self Declaration
              </Text>
              <View style={styles.bulletContainer}>
                <View style={styles.bulletRow}>
                  <View style={styles.bulletIcon}></View>
                  <Text style={styles.fieldValue}>
                    Have you ever been involved in any unlawful activities in
                    any airport in Bangladesh? (NO)
                  </Text>
                </View>
                <View style={styles.bulletRow}>
                  <View style={styles.bulletIcon}></View>
                  <Text style={styles.fieldValue}>
                    Have you ever violated any airport, immigration and customs
                    rules in any airport in Bangladesh? (NO)
                  </Text>
                </View>
                <View style={styles.bulletRow}>
                  <View style={styles.bulletIcon}></View>
                  <Text style={styles.fieldValue}>
                    Have you ever been blacklisted by Civil Aviation Authority
                    of Bangladesh for any misconduct in any airport in
                    Bangladesh? (NO)
                  </Text>
                </View>
              </View>
            </View>

            {applicationData.part_d.signature && (
              <Image
                style={styles.signature}
                src={signature}
                // src={getDataBlob(applicationData.part_d.signature)}
              />
            )}
            <View>
              <Text style={[styles.fieldLabel, { marginVertical: "10px" }]}>
                Application Date: {applicationData.created_at}
              </Text>
              <Text style={[styles.fieldLabel]}>
                Tracking ID: {applicationData.id}
              </Text>
            </View>

            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}     Downloaded At: ${timeStamp()}`
              }
            />
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default PdfDocument;
