import React, { useRef, useState, useEffect } from "react";
//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimes } from "@fortawesome/free-solid-svg-icons";
import UserImage from "../../../../../../assets/images/defaultUser.jpg";
import { useDispatch, useSelector } from "react-redux";
import { settingsData } from "../../../../../../redux/actions";
import useHttp from "../../../../../../Http/useHttp";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../../Authentication/AuthContext";

import { useTranslation } from "react-i18next";

// custom style
import styles from "./ImageHolder.module.css";

const ImageHolder = ({ label }) => {
  const { t } = useTranslation();
  const imageUpload = useRef(null);
  const [imageFile, setImageFile] = useState("");
  const dispatch = useDispatch();
  const profilePic = useSelector((state) => state.settingsData.profilePic);

  const handleInputToggler = () => {
    // console.log(imageUpload.current);
    imageUpload.current.click();
  };

  const api = useHttp("authorized");
  const { logout } = useAuth();

  useEffect(() => {
    if (profilePic) setImageFile(profilePic);
    else setImageFile("");
  }, [profilePic]);

  const updateSavedUser = (latestPic) => {
    const savedUser = JSON.parse(localStorage.getItem("user"));
    const latestProfilePic = {
      ...savedUser,
      profilePic: latestPic,
    };
    // console.log(latestProfilePic);
    localStorage.setItem("user", JSON.stringify(latestProfilePic));
  };

  const changeImage = async (data) => {
    // console.log(console.log(data.target.files[0]));
    // const localFileUrl = window.URL.createObjectURL(data.target.files[0]);
    const formData = new FormData();

    if (data.target.files.length > 0) {
      formData.append("profilePic", data.target.files[0]);
      if (data.target.files[0].size > 5242880) {
        toast.warning("Too Big File", {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else {
        const resolveAfterResponse = new Promise(async (resolve, reject) => {
          try {
            const resp = await api.post("settings/picture-update", formData);
            // console.log(resp);
            setImageFile(resp.data.profilePic);
            dispatch(
              settingsData({
                profilePic: resp.data.profilePic,
              })
            );
            updateSavedUser(resp.data.profilePic);
            // toast.success("Profile Picture Updated", {
            //   autoClose: 3000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: false,
            //   draggable: true,
            //   progress: undefined,
            // });
            resolve();
          } catch (error) {
            console.log(error.response);
            if (error.response.data.status === 401) {
              reject("Not Authorized Please Login");
              logout();
            } else reject("Operation Failed");
          }
        });

        toast.promise(
          resolveAfterResponse,
          {
            pending: "Please Wait...",
            success: "Profile Picture Updated",
            error: {
              render({ data }) {
                return data;
              },
            },
          },
          {
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );

        // try {
        //   const resp = await api.post("settings/picture-update", formData);
        //   // console.log(resp);
        //   setImageFile(resp.data.profilePic);
        //   dispatch(
        //     settingsData({
        //       profilePic: resp.data.profilePic,
        //     })
        //   );
        //   updateSavedUser(resp.data.profilePic);
        //   toast.success("Profile Picture Updated", {
        //     autoClose: 3000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // } catch (error) {
        //   // console.log(error.response);
        //   if (error.response.data.status === 401) {
        //     // localStorage.clear();
        //     // navigate("/");
        //     logout();
        //   } else {
        //     toast.error("Operation Failed", {
        //       autoClose: 3000,
        //       hideProgressBar: true,
        //       closeOnClick: true,
        //       pauseOnHover: false,
        //       draggable: true,
        //       progress: undefined,
        //     });
        //   }
        // }
      }
    }
  };

  const deleteImage = async () => {
    if (!imageFile) return;
    const formData = new FormData();
    formData.append("profilePic", "");

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        // const resp =
        await api.post("settings/picture-update", formData);
        // console.log(resp);
        setImageFile("");
        dispatch(
          settingsData({
            profilePic: "",
          })
        );
        updateSavedUser("");
        // toast.success("Profile Picture Removed", {
        //   autoClose: 3000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        // });
        resolve();
      } catch (error) {
        console.log(error.response);
        if (error.response.data.status === 401) {
          reject("Not Authorized Please Login");
          logout();
        } else reject("Operation Failed");
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Profile Picture Removed",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );

    // try {
    //   // const resp =
    //   await api.post("settings/picture-update", formData);
    //   // console.log(resp);
    //   setImageFile("");
    //   dispatch(
    //     settingsData({
    //       profilePic: "",
    //     })
    //   );
    //   updateSavedUser("");
    //   toast.success("Profile Picture Removed", {
    //     autoClose: 3000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } catch (error) {
    //   // console.log(error.response);
    //   if (error.response.data.status === 401) logout();
    //   else {
    //     toast.error("Unable to process please try again", {
    //       autoClose: 3000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: false,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // }
  };

  return (
    <>
      <div className="row">
        <label className={`form-label ${styles.label} mb-2`}>{label}</label>
        <div className="col-md-3 col-sm-12 col-12">
          <div className={styles.imageArea}>
            <div className={styles.imageWrapper}>
              <img
                src={imageFile ? imageFile : UserImage}
                alt="profile-edit"
                className={styles.image2}
              />
              <div className={styles.iconText} onClick={handleInputToggler}>
                <FontAwesomeIcon icon={faCamera} />
                <span>{t("SETTINGS.updateBtn")}</span>
              </div>
              <input
                type="file"
                name="profileImage"
                className={styles.input}
                ref={imageUpload}
                accept="image/png, image/jpeg"
                onChange={changeImage}
              />
            </div>
            <div className={styles.iconOuter} onClick={() => deleteImage()}>
              <FontAwesomeIcon icon={faTimes} className={styles.iconInner} />
            </div>
          </div>
        </div>
        <div className="col-md-9 col-sm-12 col-12">
          <div className={styles.text}>
            <p className="p-0 m-0">
              <>
                {t("SETTINGS.imageAdviceText.upload")}{" "}
                <span className={styles.textBold}>
                  {t("SETTINGS.imageAdviceText.jpg")}
                </span>{" "}
                {t("SETTINGS.imageAdviceText.or")}{" "}
                <span className={styles.textBold}>
                  {t("SETTINGS.imageAdviceText.png")}
                </span>{" "}
                {t("SETTINGS.imageAdviceText.file")}{" "}
                <span className={styles.textBold}>
                  {t("SETTINGS.imageAdviceText.size")}.
                </span>
              </>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageHolder;
