import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationDataPartBPresentAddress,
  applicationDataPartBPermanentAddress,
  copyPermanentToPresent,
  resetPresentAddress,
  changeActiveApplicationPart,
} from "../../../../../../redux/actions";

import InputField from "../../../../../../Utilities/InputField/InputField";

import { useTranslation } from "react-i18next";

// custom style
import styles from "./AddressContents.module.css";

const AddressContents = ({ header, checkState, checkText, validation }) => {
  const { t } = useTranslation();
  const myCurrentStates = useSelector(
    (state) => state.newApplicationData.newApplication.partB.address
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(myCurrentStates);
    myCurrentStates.permanentAddress.city &&
    myCurrentStates.permanentAddress.dist &&
    myCurrentStates.permanentAddress.zip &&
    myCurrentStates.permanentAddress.house &&
    myCurrentStates.permanentAddress.ps &&
    myCurrentStates.permanentAddress.street &&
    myCurrentStates.presentAddress.city &&
    myCurrentStates.presentAddress.dist &&
    myCurrentStates.presentAddress.zip &&
    myCurrentStates.presentAddress.house &&
    myCurrentStates.presentAddress.ps &&
    myCurrentStates.presentAddress.street
      ? validation(true)
      : validation(false);

    dispatch(changeActiveApplicationPart("Part B"));
  }, [myCurrentStates, validation, dispatch]);

  const house = (inputData) => {
    if (checkState) {
      dispatch(
        applicationDataPartBPresentAddress({
          house: inputData,
        })
      );
    } else {
      dispatch(
        applicationDataPartBPermanentAddress({
          house: inputData,
        })
      );
    }
  };

  const street = (inputData) => {
    if (checkState) {
      dispatch(
        applicationDataPartBPresentAddress({
          street: inputData,
        })
      );
    } else {
      dispatch(
        applicationDataPartBPermanentAddress({
          street: inputData,
        })
      );
    }
  };

  const city = (inputData) => {
    if (checkState) {
      dispatch(
        applicationDataPartBPresentAddress({
          city: inputData,
        })
      );
    } else {
      dispatch(
        applicationDataPartBPermanentAddress({
          city: inputData,
        })
      );
    }
  };

  const ps = (inputData) => {
    if (checkState) {
      dispatch(
        applicationDataPartBPresentAddress({
          ps: inputData,
        })
      );
    } else {
      dispatch(
        applicationDataPartBPermanentAddress({
          ps: inputData,
        })
      );
    }
  };

  const dist = (inputData) => {
    if (checkState) {
      dispatch(
        applicationDataPartBPresentAddress({
          dist: inputData,
        })
      );
    } else {
      dispatch(
        applicationDataPartBPermanentAddress({
          dist: inputData,
        })
      );
    }
  };

  const zip = (inputData) => {
    if (checkState) {
      dispatch(
        applicationDataPartBPresentAddress({
          zip: inputData,
        })
      );
    } else {
      dispatch(
        applicationDataPartBPermanentAddress({
          zip: inputData,
        })
      );
    }
  };

  const defaultValueDecider = (propertyName) => {
    if (checkState) {
      switch (propertyName) {
        case "house":
          return myCurrentStates.presentAddress.house
            ? myCurrentStates.presentAddress.house
            : "";
        case "street":
          return myCurrentStates.presentAddress.street
            ? myCurrentStates.presentAddress.street
            : "";
        case "city":
          return myCurrentStates.presentAddress.city
            ? myCurrentStates.presentAddress.city
            : "";
        case "ps":
          return myCurrentStates.presentAddress.ps
            ? myCurrentStates.presentAddress.ps
            : "";
        case "dist":
          return myCurrentStates.presentAddress.dist
            ? myCurrentStates.presentAddress.dist
            : "";
        case "zip":
          return myCurrentStates.presentAddress.zip
            ? myCurrentStates.presentAddress.zip
            : "";

        default:
          return null;
      }
    } else {
      switch (propertyName) {
        case "house":
          return myCurrentStates.permanentAddress.house
            ? myCurrentStates.permanentAddress.house
            : "";
        case "street":
          return myCurrentStates.permanentAddress.street
            ? myCurrentStates.permanentAddress.street
            : "";
        case "city":
          return myCurrentStates.permanentAddress.city
            ? myCurrentStates.permanentAddress.city
            : "";
        case "ps":
          return myCurrentStates.permanentAddress.ps
            ? myCurrentStates.permanentAddress.ps
            : "";
        case "dist":
          return myCurrentStates.permanentAddress.dist
            ? myCurrentStates.permanentAddress.dist
            : "";
        case "zip":
          return myCurrentStates.permanentAddress.zip
            ? myCurrentStates.permanentAddress.zip
            : "";

        default:
          return null;
      }
    }
  };

  return (
    <>
      <div className={styles.header}>{header}</div>
      <hr />
      <div className="row">
        {checkState ? (
          <div className="mt-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              onChange={(e) =>
                e.target.checked
                  ? dispatch(copyPermanentToPresent())
                  : dispatch(resetPresentAddress())
              }
            />
            <label className="form-check-label ms-2">{checkText}</label>
          </div>
        ) : null}

        <div className="col-12 mt-3">
          <InputField
            defaultValue={defaultValueDecider("house")}
            execute={house}
            type="text"
            label={`${t(
              "NEWAPPLICATION.addressInformation.houseNumber.label"
            )}`}
            name="house_number"
            placeholder={`${t(
              "NEWAPPLICATION.addressInformation.houseNumber.placeholder"
            )}`}
          />
        </div>

        <div className="col-12 mt-3">
          <InputField
            defaultValue={defaultValueDecider("street")}
            execute={street}
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.street.label")}`}
            name="road_number"
            placeholder={`${t(
              "NEWAPPLICATION.addressInformation.street.placeholder"
            )}`}
          />
        </div>

        <div className="col-12 mt-3">
          <InputField
            defaultValue={defaultValueDecider("city")}
            execute={city}
            type="text"
            label={`${t("NEWAPPLICATION.addressInformation.city.label")}`}
            name="city"
            placeholder={`${t(
              "NEWAPPLICATION.addressInformation.city.placeholder"
            )}`}
          />
        </div>

        <div className="col-12 mt-3">
          <InputField
            defaultValue={defaultValueDecider("ps")}
            execute={ps}
            type="text"
            label={`${t(
              "NEWAPPLICATION.addressInformation.policeStation.label"
            )}`}
            name="police_station"
            placeholder={`${t(
              "NEWAPPLICATION.addressInformation.policeStation.placeholder"
            )}`}
          />
        </div>

        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                defaultValue={defaultValueDecider("dist")}
                execute={dist}
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.district.label"
                )}`}
                name="district"
                placeholder={`${t(
                  "NEWAPPLICATION.addressInformation.district.placeholder"
                )}`}
              />
            </div>

            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                defaultValue={defaultValueDecider("zip")}
                execute={zip}
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.postCode.label"
                )}`}
                name="post_code"
                placeholder={`${t(
                  "NEWAPPLICATION.addressInformation.postCode.placeholder"
                )}`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressContents;
