// import logo from './logo.svg';
// import './App.css';

// import Background from "./components/Background/Background";
import Registration from "./components/Registration";
import Login from "./components/Login";

import TermsAndConditions from "./components/Terms&Conditions";
import { Routes, Route, Navigate } from "react-router-dom";
import ForgetPassword from "./components/ForgetPassword";
import Layout from "./components/Layout/Layout";
import Home from "./components/Layout/Content/Home/Home";
import NewApplication from "./components/Layout/Content/NewApplication/NewApplication";
import Settings from "./components/Layout/Content/Settings/Settings";
import AllNotification from "./components/Layout/Content/AllNotification/AllNotification";
import ApplicantDetail from "./components/Layout/Content/ApplicantDetail/ApplicantDetail";
import PdfDocument from "./components/Layout/Content/PdfDocument/PdfDocument";
import { ToastContainer } from "react-toastify";
import { AuthContextProvider } from "./Authentication/AuthContext";
import PrivateRoutes from "./Authentication/PrivateRoutes";
import PublicRoutes from "./Authentication/PublicRoutes";
import ApplicationList from "./components/Layout/Content/UrgentAction/ApplicationList/ApplicationList";
import ActionReports from "./components/Layout/Content/UrgentAction/ActionReports/ActionReports";
import FAQ from "./components/Layout/Content/FAQ/FAQ";

function App() {
  return (
    <div>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="customFont">
          Testing P tag Edit and save to reload.
        </p>
      </header> */}
      {/* ==================== for lower version of react: set PORT=3006 && react-scripts --openssl-legacy-provider start */}
      <ToastContainer />
      <AuthContextProvider>
        <Routes>
          {/* ======================== Public Routes ========================*/}

          <Route element={<PublicRoutes />}>
            <Route path="/" element={<Login />} />
            <Route
              path="/termsAndConditions"
              element={<TermsAndConditions />}
            />
            <Route path="/registration" element={<Registration />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
          </Route>

          {/* ======================== Protected Routes ========================*/}

          <Route element={<PrivateRoutes />}>
            <Route
              path="/dashboard/"
              element={<Navigate to="/dashboard/home" />}
            />
            <Route path="/dashboard/*" element={<Layout />}>
              <Route
                path="home/application/download/:id"
                element={<PdfDocument />}
              />
              <Route
                path="home/application/:id"
                element={<ApplicantDetail />}
              />
              <Route path="home" element={<Home />} />
              <Route path="newApplication" element={<NewApplication />} />
              <Route path="settings" element={<Settings />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="notification" element={<AllNotification />} />
              <Route path="urgentApplication" element={<ApplicationList />} />
              <Route
                path="urgentApplication/:id"
                element={<ApplicantDetail />}
              />
              <Route path="urgentReport" element={<ActionReports />} />
              {/* <Route path="urgentReport/:id" element={<ApplicantDetail />} /> */}
            </Route>
          </Route>
        </Routes>
      </AuthContextProvider>

      {/* <Background>
        <Registration />
      </Background> */}
    </div>
  );
}

export default App;
