import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetApplicationState,
  resetApplicationData,
} from "../../../redux/actions";
import { toast } from "react-toastify";

//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faMoon,
  faAngleUp,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

import "./sideBar.css";

function SideBar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const draftCount = useSelector((state) => state.commonVariables.draftCount);

  // console.log(draftCount);

  let menus = [
    { title: `${t("MENU.home")}`, path: "home", subMenu: [] },
    {
      title: `${t("MENU.newApplication")}`,
      path: "newApplication",
      subMenu: [],
    },
    {
      title: `Urgent Action`,
      path: "dynamicManagement",
      subMenu: [
        {
          title: `Application List`,
          path: "urgentApplication",
        },
        {
          title: `Report`,
          path: "urgentReport",
        },
      ],
    },
    { title: `${t("MENU.settings")}`, path: "settings", subMenu: [] },
    { title: `FAQ`, path: "faq", subMenu: [] },
  ];

  const [active, setActive] = useState("home");
  const [clock, setClock] = useState(new Date());
  const [openManagement, setOpenManagement] = useState(false);

  //^newApplication.*$
  const validPath = (pathName, location) => {
    const pathMatchResult = new RegExp(`^${pathName}.*$`).test(location);
    // console.log(" path : ", pathName);
    // console.log(" location : ", location);
    // console.log(" result : ", pathMatchResult);
    return pathMatchResult;
  };

  useEffect(() => {
    const interval = setInterval(() => setClock(new Date()), 1000);
    setActive(location.pathname.slice(11));

    return () => {
      clearInterval(interval);
    };
  }, [location]);

  return (
    <>
      <div className="menu">
        <div>
          {menus.map((item, index) => (
            <div key={index}>
              <div
                className={`menu-item ${
                  active === item.path ? "active-item" : null
                }`}
                onClick={() => {
                  setActive(item.path);
                  // navigate(item.path);
                  // setOpenManagement((prev) => !prev);
                  if (item.path !== "dynamicManagement") {
                    navigate(item.path);
                  } else {
                    setOpenManagement((prev) => !prev);
                  }
                  if (item.path === "newApplication") {
                    dispatch(resetApplicationState());
                    dispatch(resetApplicationData());
                    draftCount > 0 &&
                      toast.warn(
                        "If You Continue, Your Current Draft Will Be Replaced",
                        {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                  }
                }}
              >
                {item.title}
                {item.subMenu.length !== 0 ? (
                  <FontAwesomeIcon
                    icon={faAngleUp}
                    className={`menu-dropdown ms-5 ${
                      openManagement
                        ? "menu-dropdown-rotate-0"
                        : "menu-dropdown-rotate-180"
                    }`}
                  />
                ) : null}
              </div>

              {item.subMenu.length !== 0 ? (
                <>
                  <div
                    className={`${
                      openManagement
                        ? "menu-dropdown-area-visible"
                        : "menu-dropdown-area-hidden"
                    }`}
                  >
                    {item.subMenu.map((item, index) => (
                      <div className="menu-dropdown-item" key={index}>
                        <div
                          className={`menu-dropdown-link ${
                            // active === item.path
                            validPath(item.path, active)
                              ? "menu-dropdown-link-active"
                              : null
                          }`}
                          onClick={() => {
                            setActive(item.path);
                            navigate(item.path);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="menu-dropdown-icon-circle"
                          />
                          {item.title}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          ))}

          {/* <div className="menu-item">Home</div>
            <div className="menu-item">New Application</div>
            <div className="menu-item">Settings</div> */}
        </div>
      </div>
      <div className="welcome">
        {clock.getHours() < 18 ? (
          <FontAwesomeIcon icon={faSun} className="icon-sun" />
        ) : (
          <FontAwesomeIcon icon={faMoon} className="icon-sun" />
        )}
        <br />
        <span id="welcomeMsg">
          {clock.getHours() < 12
            ? `${t("GREETING.morning")}`
            : clock.getHours() < 18
            ? `${t("GREETING.afternoon")}`
            : `${t("GREETING.evening")}`}
        </span>
        <br />
        <span id="clock">
          {t("MENU.timeFormat") === "bn-BD"
            ? clock.toLocaleTimeString("bn-BD")
            : clock.toLocaleTimeString("en-US")}{" "}
          |{" "}
          {/* {`${clock.getMonth() + 1}-${clock.getDate()}-${clock.getFullYear()}`} */}
          {t("MENU.timeFormat") === "bn-BD"
            ? clock.toLocaleDateString("bn-BD")
            : clock.toLocaleDateString("en-US").replace(/\//g, "-")}
        </span>
      </div>
    </>
  );
}

export default SideBar;
