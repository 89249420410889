import React from "react";
import { useSelector } from "react-redux";

// FontAwesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Fontawesome Icons
// import { faCircle } from '@fortawesome/free-solid-svg-icons';
// custom style

import styles from "./Step.module.css";
const Step = (props) => {
  const activeApplicationPart = useSelector(
    (state) => state.newApplicationStateTracker.activePart
  );

  const { location } = props;
  return (
    <>
      <li
        className={`${styles.item} text-center ${
          activeApplicationPart === location ? styles.activeItem : null
        }`}
      >
        <p className={`${styles.text} mb-0`}>{location}</p>
        <div className={styles.circleArea}>
          <div
            className={`${styles.dot} ${
              activeApplicationPart === location ? styles.activeDot : null
            }`}
          ></div>
        </div>
      </li>
    </>
  );
};

export default Step;
