const initialState = {
  isLoaderActive: false,
  isPopupActive: false,
  popupVariety: "",
  popupTitle: "",
  popupSubtitle: "",
};

const popupLoaderStates = (state = initialState, action) => {
  let formData = action.payload;

  switch (action.type) {
    case "RESET_POPUP_LOADER_STATES":
      return initialState;

    case "LOADER_ACTIVE":
      return {
        ...state,
        isLoaderActive: true,
      };

    case "LOADER_INACTIVE":
      return {
        ...state,
        isLoaderActive: false,
      };

    case "POPUP_ACTIVE":
      return {
        ...state,
        isPopupActive: true,
      };

    case "POPUP_INACTIVE":
      return {
        ...state,
        isPopupActive: false,
      };

    case "CHANGE_POPUP_VARIETY":
      return {
        ...state,
        ...formData,
      };

    default:
      return state;
  }
};

export default popupLoaderStates;
