import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import useHttp from "../../../../../Http/useHttp";
import { useAuth } from "../../../../../Authentication/AuthContext";

// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   // resetApplicationState,
//   resetApplicationData,
//   applicationDataPartA,
//   applicationDataPartB,
//   applicationDataPartC,
//   applicationDataPartD,
//   applicationDataPartE,
//   applicationDataPartF,
//   applicationDataAttachment,
//   // changeNextState,
//   // setDraftData,
//   // setDraftCount,
//   // setUnreadNotificationCount,
// } from "../../../../../redux/actions";
// import FormTitle from "../../NewApplication/Utilities/FormTitle/FormTitle";
// import DateRangePicker from "react-bootstrap-daterangepicker";

import { Modal } from "react-bootstrap";
import InputField from "../../../../../Utilities/InputField/InputField";
import DateInput from "../../../../../Utilities/DateInput/DateInput";
import TextInputArea from "../../../../../Utilities/TextInputArea/TextInputArea";

//  images
import profileImage from "../../../../../assets/images/defaultUser.jpg";

//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  //   faEdit,
  //   faSearch,
  //   faEye,
} from "@fortawesome/free-solid-svg-icons";

//  custom style
import styles from "./ActionReports.module.css";

const ActionReports = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const api = useHttp("authorized");
  const { logout } = useAuth();

  const [show, setShow] = useState(false);
  const [allApplicationList, setAllApplicationList] = useState([]);

  const [action, setAction] = useState("");
  const [reason, setReason] = useState("");
  const [specify, setSpecify] = useState("");

  const [gdNumber, setGdNumber] = useState("");
  const [gdDate, setGdDate] = useState("");
  const [gdPoliceStation, setGdPoliceStation] = useState("");

  const [organization, setOrganization] = useState("");
  const [existingOrganization, setExistingOrganization] = useState("");

  const [designation, setDesignation] = useState("");
  const [existingDesignation, setExistingDesignation] = useState("");

  // {
  //   action: "Lost / Stolen",
  //   status: "Pending",
  //   id: 15,
  //   applicant_name: "Samindra",
  //   card: { name: "NIAC" },
  // },
  //   const [searchPlaceholder, setSearchPlaceholder] = useState("");
  //   const [searchType, setSearchType] = useState("");

  //   const searchOptions = [
  //     { name: `${t("REPORTANDQUERY.search.label")}`, value: "", placeholder: "" },
  //     {
  //       name: `${t("REPORTANDQUERY.search.applicant.label")}`,
  //       value: "name",
  //       placeholder: `${t("REPORTANDQUERY.search.applicant.placeholder")}`,
  //     },
  //     {
  //       name: `${t("REPORTANDQUERY.search.organization.label")}`,
  //       value: "organization",
  //       placeholder: `${t("REPORTANDQUERY.search.organization.placeholder")}`,
  //     },
  //     {
  //       name: `${t("REPORTANDQUERY.search.DAS.label")}`,
  //       value: "das",
  //       placeholder: `${t("REPORTANDQUERY.search.DAS.placeholder")}`,
  //     },
  //     {
  //       name: `${t("REPORTANDQUERY.search.dateRange.label")}`,
  //       value: "dtRange",
  //       placeholder: "",
  //     },
  //   ];

  //   const handleSearchOption = (e) => {
  //     // console.log(e.target.value);
  //     setSearchType(e.target.value);

  //     searchOptions.map((option) => {
  //       if (option.value === e.target.value)
  //         setSearchPlaceholder(option.placeholder);
  //     });
  //   };

  // const header = [
  //   "Report Lost / Stolen Pass",
  //   "Change / Update Employment Status",
  //   "Change of Organization",
  // ];

  const handleClose = () => setShow(false);

  const sortingIcon = (
    <div className={styles.tableHeaderIconContainer}>
      <FontAwesomeIcon icon={faChevronUp} className={styles.tableHeaderIcon} />
      <FontAwesomeIcon
        icon={faChevronDown}
        className={styles.tableHeaderIcon}
      />
    </div>
  );

  const tableHeaders = [
    { title: `ID`, space: "5%" },
    {
      title: `Action Name`,
      space: "25%",
    },
    {
      title: `AvSecId`,
      space: "5%",
    },
    {
      title: `Status`,
      space: "10%",
    },
    {
      title: `Applicant Name`,
      space: "35%",
    },
    {
      title: `Card Type`,
      space: "20%",
    },
  ];

  const ContainerTableHeader = (
    <>
      <thead className={styles.tableHeader}>
        <tr>
          {tableHeaders.map((header, index) => (
            <th key={index} style={{ width: header.space }}>
              <div className={`${styles.tableHeaderText} ${styles.subText}`}>
                {header.title} {sortingIcon}
              </div>
            </th>
          ))}
        </tr>
      </thead>
    </>
  );

  const openModal = (data) => {
    // console.log(data);
    setAction(data.action ? data.action : "");
    setReason(data.detail.reason ? data.detail.reason : "");
    setSpecify(data.detail.specify ? data.detail.specify : "");

    setGdNumber(data.detail.gdNumber ? data.detail.gdNumber : "");
    setGdDate(data.detail.gdDate ? data.detail.gdDate : "");
    setGdPoliceStation(
      data.detail.gdPoliceStation ? data.detail.gdPoliceStation : ""
    );

    setOrganization(data.detail.organization ? data.detail.organization : "");
    setExistingOrganization(
      data.detail.existingOrganization ? data.detail.existingOrganization : ""
    );

    setDesignation(data.detail.designation ? data.detail.designation : "");
    setExistingDesignation(
      data.detail.existingDesignation ? data.detail.existingDesignation : ""
    );

    setShow(true);
  };

  const ContainerTableBody = (
    <>
      <tbody className={styles.tableBody}>
        {allApplicationList.length > 0 &&
          allApplicationList.map((data, index) => (
            <tr
              key={index}
              onClick={() => openModal(data)}
              // onClick={() => openApplication(data.application_id)}
            >
              <th>{data.application_id}</th>

              <td>{data.action ? data.action : "N/A"}</td>
              <td>{data.avsec_id ? data.avsec_id : "N/A"}</td>
              <td>
                {data.status ? (
                  <span
                    className={
                      data.status === "Pending"
                        ? styles.textPending
                        : styles.textColorRedCol
                    }
                  >
                    {data.status}
                  </span>
                ) : (
                  "N/A"
                )}
              </td>

              <td>
                <div className={styles.tableNameContain}>
                  <div className={styles.areaImage}>
                    <img
                      src={
                        data.applicant_image
                          ? data.applicant_image
                          : profileImage
                      }
                      alt={data.applicant_name}
                      className={styles.nameImage}
                    />
                  </div>
                  <span className={styles.nameText}>{data.applicant_name}</span>
                </div>
              </td>

              <td>{data.card ? data.card.name : "N/A"}</td>
            </tr>
          ))}
      </tbody>
    </>
  );

  //   const handleApply = (event, picker) => {
  //     picker.element.val(
  //       picker.startDate.format("MM/DD/YYYY") +
  //         " - " +
  //         picker.endDate.format("MM/DD/YYYY")
  //     );

  //     console.log(
  //       `${picker.startDate.format("MM/DD/YYYY")} - ${picker.endDate.format(
  //         "MM/DD/YYYY"
  //       )}`
  //     );
  //   };

  //   const handleCancel = (event, picker) => {
  //     picker.element.val("");
  //   };

  //   const headerDecider = (from) => {
  //     switch (from) {
  //       case "lostStolen":
  //         return `Lost / Stolen`;
  //       case "employmentStatus":
  //         return `Employment Status Changed`;

  //       default:
  //         return `Organization Changed`;
  //     }
  //   };

  const getApplicationList = useCallback(async () => {
    try {
      //==================== Fetching Application List ====================
      const resp = await api.get("urgent-action/report");
      console.log(resp.data);

      setAllApplicationList(resp.data.response);
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.status === 401) logout();
        else {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Unknown Error", {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [api, logout]);

  // const openApplication = async (id) => {
  //   // console.log(id);

  //   const resolveAfterResponse = new Promise(async (resolve, reject) => {
  //     try {
  //       const resp = await api.get(`application/${id}`);
  //       console.log(resp.data);

  //       dispatch(resetApplicationData());
  //       dispatch(
  //         applicationDataPartA({
  //           ...resp.data.application.part_a,
  //           applicationId: resp.data.application.application_id,
  //           createdAt: resp.data.application.created_at,
  //         })
  //       );
  //       dispatch(applicationDataPartB({ ...resp.data.application.part_b }));
  //       dispatch(applicationDataPartC({ ...resp.data.application.part_c }));
  //       dispatch(applicationDataPartD({ ...resp.data.application.part_d }));
  //       dispatch(applicationDataPartE({ ...resp.data.application.part_e }));
  //       dispatch(applicationDataPartF({ ...resp.data.application.part_f }));
  //       dispatch(
  //         applicationDataAttachment({ ...resp.data.application.attachments })
  //       );

  //       navigate(`${id}`);

  //       resolve();
  //     } catch (error) {
  //       console.log(error.response);
  //       if (error.response.data.status === 401) {
  //         reject("Not Authorized Please Login");
  //         logout();
  //       } else reject(error.response.data.message);
  //     }
  //   });

  //   toast.promise(
  //     resolveAfterResponse,
  //     {
  //       pending: "Please Wait...",
  //       success: "Your Application",
  //       error: {
  //         render({ data }) {
  //           return data;
  //         },
  //       },
  //     },
  //     {
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       progress: undefined,
  //     }
  //   );
  // };

  useEffect(() => {
    getApplicationList();
    return () => {
      setAllApplicationList([]);
    };
  }, []);

  return (
    <>
      <div className={styles.contentAdjuster}>
        <div className={`${styles.innerContent} mb-5`}>
          <div className={styles.headerContent}>
            {/* <FormTitle
              topHeader="Urgent Action"
              header="Application List"
              subTitle="Perform Your Urgent Actions Here"
            /> */}
            <div className={styles.titleArea}>
              <div className={styles.starterText}>Urgent Action</div>
              <div className={styles.headerText}>Application Report List</div>
              <div className={styles.subText}>
                View Your Application Report Status
              </div>
            </div>
          </div>
          <>
            {/* <div className="row">
            <div className="col-12 mt-4 mb-4">
              <div className={styles.searchContainer}>
                <div className={styles.selectField}>
                  <div className={`input-group`}>
                    <label
                      className={`input-group-text ${styles.searchArea}`}
                      htmlFor="homeSearch"
                    >
                      <FontAwesomeIcon className="" icon={faSearch} />
                    </label>
                    <select
                      className={`form-select ${styles.searchInput}`}
                      id="homeSearch"
                      placeholder="Search By"
                      onChange={(e) => handleSearchOption(e)}
                    >
                      {searchOptions.map((c, index) => (
                        <option
                          className={styles.selectOption}
                          key={index}
                          value={c.value}
                        >
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {searchType && (
                  <div className={styles.inputArea}>
                    {searchType !== "dtRange" && (
                      <div className={`input-group`}>
                        <input
                          type="text"
                          className={`form-control ${styles.searchInputField}`}
                          placeholder={searchPlaceholder}
                        />
                        <button
                          className={`btn ${styles.btnSearchSubmit}`}
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    )}
                    {searchType === "dtRange" && (
                      <div className={`input-group`}>
                        <DateRangePicker
                          initialSettings={{
                            autoUpdateInput: false,
                            locale: {
                              cancelLabel: "Clear",
                            },
                            dateLimit: {
                              days: 5,
                            },
                          }}
                          onApply={handleApply}
                          onCancel={handleCancel}
                        >
                          <input
                            type="text"
                            className={`form-control ${styles.searchDateInput}`}
                            defaultValue=""
                          />
                        </DateRangePicker>
                        <button
                          className={`btn ${styles.btnSearchSubmit}`}
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div> */}
          </>
        </div>
        {/* <div className={`${styles.tableContainer} table-responsive`}>  */}
        <div className={`${styles.tableContainer} `}>
          <table className={`table table-hover ${styles.overviewTable}`}>
            {ContainerTableHeader}
            {ContainerTableBody}
          </table>
        </div>

        <Modal
          // className={styles.applicantDetailModel}
          contentClassName={styles.applicantDetailModel}
          show={show}
          size="lg"
          onHide={handleClose}
          // animation={false}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body className={styles.applicantDetailModelBody}>
            <div className={styles.areaModelContent}>
              <div className={styles.modelTopContent}>
                <div className={styles.areaModelHeader}>Urgent Action</div>
                <div className={styles.areaModelText}>
                  <h5 className={styles.areaModelListHeader}>Report Summary</h5>
                </div>
                <div className="row">
                  <div className="col-12">
                    <InputField
                      type="text"
                      label={`Reason`}
                      name="reason"
                      placeholder={`Enter Reason`}
                      defaultValue={action}
                      disable
                    />
                  </div>

                  {(action === "Lost" || action === "Stolen") && (
                    <>
                      <div className="col-12 mt-3">
                        <InputField
                          type="text"
                          label={`GD Number`}
                          name="gd"
                          placeholder={`Enter GD Number`}
                          defaultValue={gdNumber}
                          disable
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <DateInput
                          label={`GD Date`}
                          defaultValue={gdDate}
                          disable
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <InputField
                          type="text"
                          label={`Police Station Name`}
                          name="ps_name"
                          placeholder={`Enter Police Station Name`}
                          defaultValue={gdPoliceStation}
                          disable
                        />
                      </div>
                    </>
                  )}

                  {/* {action === "Stolen" && (
                    <>
                      <div className="col-12 mt-3">
                        <InputField
                          type="text"
                          label={`GD Number`}
                          name="gd"
                          placeholder={`Enter GD Number`}
                          defaultValue={gdNumber}
                          disable
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <DateInput
                          label={`GD Date`}
                          defaultValue={gdDate}
                          disable
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <InputField
                          type="text"
                          label={`Police Station Name`}
                          name="ps_name"
                          placeholder={`Enter Police Station Name`}
                          defaultValue={gdPoliceStation}
                          disable
                        />
                      </div>
                    </>
                  )} */}

                  {action === "Designation Changed" && (
                    <>
                      <div className="col-12 mt-3">
                        <InputField
                          type="text"
                          label={`Existing Designation`}
                          name="e_designation"
                          placeholder={`Enter Existing Designation`}
                          defaultValue={existingDesignation}
                          disable
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <InputField
                          type="text"
                          label={`New Designation`}
                          name="n_designation"
                          placeholder={`Enter New Designation`}
                          defaultValue={designation}
                          disable
                        />
                      </div>
                    </>
                  )}

                  {action === "Organization Changed" && (
                    <>
                      <div className="col-12 mt-3">
                        <InputField
                          type="text"
                          label={`Existing Organization`}
                          name="e_company"
                          placeholder={`Enter Existing Organization`}
                          defaultValue={existingOrganization}
                          disable
                        />
                      </div>

                      <div className="col-12 mt-3">
                        <InputField
                          type="text"
                          label={`New Organization`}
                          name="n_company"
                          placeholder={`Enter New Organization`}
                          defaultValue={organization}
                          disable
                        />
                      </div>
                    </>
                  )}

                  {(action === "Review / Correction" || action === "Other") && (
                    <>
                      <div className="col-12 mt-3">
                        <TextInputArea
                          label="Your Comment"
                          name="orgAdminComment"
                          defaultValue={specify}
                          // execute={(comment) => setReason(comment)}
                          disabled
                        />
                      </div>
                    </>
                  )}

                  <div className="col-12 mt-3">
                    <TextInputArea
                      label="Your Comment"
                      name="orgAdminComment"
                      defaultValue={reason}
                      // execute={(comment) => setReason(comment)}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles.modelBtnArea} mt-5`}>
                <button
                  type="button"
                  className={`btn ${styles.btnModel} ${styles.btnModelRed}`}
                  onClick={handleClose}
                >
                  {" "}
                  OK{" "}
                </button>
                {/* <button
                  type="button"
                  className={`btn ${styles.btnModel} ${styles.btnModelPrimary}`}
                  onClick={submitApplication}
                >
                  {" "}
                  Proceed{" "}
                </button> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ActionReports;
