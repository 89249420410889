import React from "react";
import { toast } from "react-toastify";
import useHttp from "../../../../../Http/useHttp";
import { useAuth } from "../../../../../Authentication/AuthContext";
import { setUnreadNotificationCount } from "../../../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

// custom style
import "./CardNotify.css";

const CardNotify = ({ activeIconCLass, header, bodyText, time, read, id }) => {
  const api = useHttp("authorized");
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const unreadNotification = useSelector(
    (state) => state.commonVariables.unreadNotificationCount
  );

  const readNotification = async () => {
    if (read) return;
    try {
      const resp = await api.get(`user/notification/marked-as-read/${id}`);
      dispatch(setUnreadNotificationCount(unreadNotification - 1));
      // console.log(resp.data);
    } catch (error) {
      // console.log(error.response);
      if (error.response.data.status === 401) logout();
      else {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      <div
        className={`container-card ${!read && "notification-unread"}`}
        onClick={readNotification}
      >
        <div className="text-content-left">
          <div className={`${activeIconCLass}`}>
            <FontAwesomeIcon icon={faFileAlt} className="icon-file" />
          </div>
          <div className="body-container-contents">
            <div className="header">{header}</div>
            <div className="body">{bodyText}</div>
          </div>
        </div>
        <div className="time-container-top">{time}</div>
      </div>
    </>
  );
};

export default CardNotify;
