import React from "react";

// custom style
import styles from "./RadioLabel.module.css";

const RadioLabel = ({
  id,
  name,
  label,
  value,
  execute,
  defaultValue,
  disable = false,
}) => {
  const handleChange = (e) => {
    execute(e.target.value);
  };

  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={name}
          value={value}
          id={id}
          onChange={handleChange}
          checked={value === defaultValue}
          disabled={disable}
        />
        <label className={`${styles.label} form-check-label`}>{label}</label>
      </div>
    </>
  );
};

export default RadioLabel;
