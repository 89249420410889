import React, { useEffect, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useHttp from "../../../../Http/useHttp";
import { useAuth } from "../../../../Authentication/AuthContext";
import NotificationTitle from "./Utilities/NotificationTitle/NotificationTitle";
import NotificationCard from "./Utilities/NotificationCard/NotificationCard";
import { setUnreadNotificationCount } from "../../../../redux/actions";
import { useDispatch } from "react-redux";

import "./AllNotification.css";

const AllNotification = () => {
  const api = useHttp("authorized");
  const [notifications, setNotifications] = useState([]);
  const [allRead, setAllRead] = useState(false);
  const { logout } = useAuth();

  const dispatch = useDispatch();
  // const unreadNotification = useSelector(
  //   (state) => state.commonVariables.unreadNotificationCount
  // );

  const colorContainer = [
    { accepted: "icon-bg-green", headerAccepted: "Application Accepted" },
    { rejected: "icon-bg-red", headerRejected: "Application Rejected" },
    { pending: "icon-bg-yellow", headerPending: "Application Pending" },
    { drafted: "icon-bg-gray", headerDrafted: "Application Drafted" },
  ];

  const getAllNotification = useCallback(async () => {
    try {
      const resp = await api.get("user/notification/all");
      console.log(resp.data.response);
      setNotifications(resp.data.response);
    } catch (error) {
      console.log(error.response);
      if (error.response.data.status === 401) logout();
      else {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [api, logout]);

  const headerDecider = (status) => {
    switch (status) {
      case "Accepted":
        return colorContainer[0].headerAccepted;
      case "Rejected":
        return colorContainer[1].headerRejected;
      case "Submitted":
        return colorContainer[2].headerPending;
      case "Drafted":
        return colorContainer[3].headerDrafted;

      default:
        return;
    }
  };

  const iconClassDecider = (status) => {
    switch (status) {
      case "Accepted":
        return colorContainer[0].accepted;
      case "Rejected":
        return colorContainer[1].rejected;
      case "Submitted":
        return colorContainer[2].pending;
      case "Drafted":
        return colorContainer[3].drafted;

      default:
        return;
    }
  };

  const markAllRead = async () => {
    if (allRead) return;
    try {
      const resp = await api.get("user/notification/marked-as-read-all");
      console.log(resp.data.response);
      setAllRead(true);
      dispatch(setUnreadNotificationCount(0));
    } catch (error) {
      console.log(error.response);
      if (error.response.data.status === 401) logout();
      else {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  useEffect(() => {
    getAllNotification();
  }, []);

  return (
    <div className="notification-content">
      <div className="notification-inner-content">
        <NotificationTitle
          header="All Notification"
          subTitle="You have unread notifications"
        />
        <div className="mark-all-read" onClick={markAllRead}>
          Mark All Read
        </div>
        <div className="row mt-4">
          {notifications.length > 0 &&
            notifications.map((notification, index) => (
              <div className="col-12" key={index}>
                <NotificationCard
                  activeIconCLass={iconClassDecider(notification.type)}
                  header={headerDecider(notification.type)}
                  bodyText={notification.data.message}
                  time={notification.created_at}
                  read={allRead ? true : notification.read_at}
                  id={notification.id}
                />
              </div>
            ))}

          {/* <div className="col-12">
            <NotificationCard
              activeIconCLass={colorContainer[1].rejected}
              header={colorContainer[1].headerRejected}
              bodyText="Contrary to popular belief is not Contrary to popular belief is not simply random text. simply random text."
              time="1 Day"
            />
          </div>
          <div className="col-12">
            <NotificationCard
              activeIconCLass={colorContainer[2].pending}
              header={colorContainer[2].headerPending}
              bodyText="Contrary to popular belief is not Contrary to popular belief is not simply random text. simply random text."
              time="1 Day"
            />
          </div>
          <div className="col-12">
            <NotificationCard
              activeIconCLass={colorContainer[3].drafted}
              header={colorContainer[3].headerDrafted}
              bodyText="Contrary to popular belief is not Contrary to popular belief is not simply random text. simply random text."
              time="1 Day"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AllNotification;
