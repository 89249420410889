import React, { useState, useEffect } from "react";
import FileUploader from "../../Utilities/FileUploader/FileUploader";
import DateInput from "../../../../../../Utilities/DateInput/DateInput";
import { useDispatch, useSelector } from "react-redux";
import { settingsData } from "../../../../../../redux/actions";
import useHttp from "../../../../../../Http/useHttp";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../../Authentication/AuthContext";

import { useTranslation } from "react-i18next";

// custom style
import styles from "./UploadPage.module.css";

const UploadPage = () => {
  const [pvValidTill, setPvValidTill] = useState("");
  const [gstValidTill, setGstValidTill] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myApplication = useSelector((state) => state.settingsData);

  const api = useHttp("authorized");
  const { logout } = useAuth();

  useEffect(() => {
    setPvValidTill(myApplication.policeVerificationValidTill);
    setGstValidTill(myApplication.gsatValidTill);
  }, [myApplication]);

  const textUploadPoliceVerification = (
    <>
      {t("SETTINGS.uploadForm.adviceTextPolice.upload")}{" "}
      <strong>{t("SETTINGS.uploadForm.adviceTextPolice.pdf")}</strong>{" "}
      {t("SETTINGS.uploadForm.adviceTextPolice.file")}
    </>
  );

  const textUploadGSAT = (
    <>
      {t("SETTINGS.uploadForm.adviceTextGSAT.upload")}{" "}
      <strong>{t("SETTINGS.uploadForm.adviceTextGSAT.pdf")}</strong>{" "}
      {t("SETTINGS.uploadForm.adviceTextGSAT.file")}
    </>
  );

  const gsatValidTill = (inputData) => {
    dispatch(
      settingsData({
        gsatValidTill: inputData,
      })
    );
  };

  const policeVerificationValidTill = (inputData) => {
    dispatch(
      settingsData({
        policeVerificationValidTill: inputData,
      })
    );
  };

  const updateUser = (updatedValue) => {
    const savedUser = JSON.parse(localStorage.getItem("user"));
    const updatedUser = {
      ...savedUser,
      ...updatedValue,
    };
    // console.log(updatedUser);
    localStorage.setItem("user", JSON.stringify(updatedUser));
  };

  const requestUpload = async () => {
    const formData = new FormData();

    //GSAT
    if (myApplication.gsatFile.path) {
      formData.append("gsat", myApplication.gsatFile);
      formData.append("gsat_valid_till", myApplication.gsatValidTill);
    } else myApplication.gsatFile === "deleted" && formData.append("gsat", "");

    //police verification
    if (myApplication.policeVerificationFile.path) {
      formData.append(
        "police_verification",
        myApplication.policeVerificationFile
      );
      formData.append(
        "police_verification_valid_till",
        myApplication.policeVerificationValidTill
      );
    } else
      myApplication.policeVerificationFile === "deleted" &&
        formData.append("police_verification", "");

    // console.log(formData.values());
    let formDataValues = 0;
    for (var value of formData.values()) {
      formDataValues++;
      console.log(value);
    }
    console.log(formDataValues);

    if (formDataValues > 0) {
      try {
        const resp = await api.post("settings/pv-gsat-update", formData);
        // console.log(resp.data.response);
        const keys = Object.keys(resp.data.response);

        keys.map((key) =>
          // console.log(resp.data.response[key].message)

          {
            if (key === "gsat") {
              updateUser({
                gsatFile: resp.data.response[key].url,
                gsatValidTill: myApplication.gsatValidTill,
              });
              dispatch(
                settingsData({
                  gsatFile: resp.data.response[key].url,
                })
              );
              dispatch(
                settingsData({
                  gsatFileName: resp.data.response[key].url
                    ? "GSAT Certificate"
                    : "",
                })
              );
            } else {
              updateUser({
                policeVerificationFile: resp.data.response[key].url,
                policeVerificationValidTill:
                  myApplication.policeVerificationValidTill,
              });
              dispatch(
                settingsData({
                  policeVerificationFile: resp.data.response[key].url,
                })
              );
              dispatch(
                settingsData({
                  policeVerificationFileName: resp.data.response[key].url
                    ? "Police Verification"
                    : "",
                })
              );
            }

            return toast.success(resp.data.response[key].message, {
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
        );
      } catch (err) {
        // console.log(err.response.data);
        if (err.response.data.status === 401) {
          // localStorage.clear();
          // navigate("/");
          logout();
        } else {
          const errors = err.response.data.errors;
          const keys = Object.keys(errors);

          let errorDataLog = [];
          keys.map((key) =>
            errors[key].map((data) => (errorDataLog = [...errorDataLog, data]))
          );
          errorDataLog.map((text) =>
            toast.error(text, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            })
          );
        }
      }
    }
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-12 mt-3">
          <div className={styles.area}>
            <label className={`form-label ${styles.label}`}>
              {t("SETTINGS.uploadForm.uploadPoliceVerification.label")}
            </label>
            <FileUploader
              fileType="policeVerification"
              textAdvise={textUploadPoliceVerification}
            />
          </div>
        </div>
        <div className="col-12 mt-3">
          <DateInput
            label={t("SETTINGS.uploadForm.validTill.label")}
            execute={policeVerificationValidTill}
            defaultValue={pvValidTill ? pvValidTill : ""}
          />
        </div>
        <div className="col-12 mt-3">
          <div className={styles.area}>
            <label className={`form-label ${styles.label}`}>
              {t("SETTINGS.uploadForm.uploadGSAT.label")}
            </label>
            <FileUploader fileType="GSAT" textAdvise={textUploadGSAT} />
          </div>
        </div>
        <div className="col-12 mt-3">
          <DateInput
            label={t("SETTINGS.uploadForm.validTill.label")}
            execute={gsatValidTill}
            defaultValue={gstValidTill ? gstValidTill : ""}
          />
        </div>
        <div className="col-12 mt-5">
          <button
            type="submit"
            className={`btn ${styles.button} float-end`}
            onClick={() => requestUpload()}
          >
            {t("SETTINGS.updateBtn")}
          </button>
        </div>
      </div>
    </>
  );
};

export default UploadPage;
