import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { CountryCode } from "../CountryCode/CountryCode";
import useHttp from "../../Http/useHttp";
import { useDispatch } from "react-redux";
import { formFillUp } from "../../redux/actions";

import { useTranslation } from "react-i18next";

//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// validate
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Validation Rules
let schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Z_.]+( [a-zA-Z_.]+)*$/, {
      message: "Name must consist of dot and letters only",
      excludeEmptyString: true,
    })
    .required("Valid Name is Required !!"),
  email: yup
    .string()
    .email("It has to be a valid Email")
    .matches(
      /[A-Za-z0-9._%+-]{3,}@[a-zA-Z-]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
      {
        message: "Please provide a valid email address.",
        excludeEmptyString: true,
      }
    )
    .required("Valid Email is Required !!"),
  password: yup
    .string()
    .min(6, "It must be at least 6 characters")
    .max(15, "It must be at most 15 characters")
    .matches(/(?=.*[a-z])/, {
      message: "It must have at least one lowercase alphabet",
      excludeEmptyString: true,
    })
    .matches(/(?=.*[A-Z])/, {
      message: "It must have at least one uppercase alphabet",
      excludeEmptyString: true,
    })
    .matches(/(?=.*\d)/, {
      message: "It must have at least one digit exists",
      excludeEmptyString: true,
    })
    .matches(/(?=.*\W)/, {
      message: "It must have at least one Symbol character exists",
      excludeEmptyString: true,
    })
    .required("Valid Password is Required !!"),
  password_confirm: yup
    .string()
    .oneOf([yup.ref("password"), null])
    .required("Valid Confirm Password is Required !!"),
  mobile: yup.string().required("Valid Mobile Number is Required!"),
  checkAgree: yup
    .bool()
    .required("Required!")
    .oneOf([true], "Terms must be accepted"),
});

function RegistrationForm({ loading, popUp, popUpErrorText, nextStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const api = useHttp();

  //Form-1 Values
  const [selectedFlag, setSelectedFlag] = useState("bd");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+880");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [termAgreement, setTermAgreement] = useState(false);

  const handleSelection = (data) => {
    // console.log(`${data.substring(3).toLowerCase()}.svg`);
    setSelectedFlag(data.substring(0, 2).toLowerCase());
    setSelectedCountryCode(data.substring(3).toLowerCase());
  };

  //Font awesome initialization
  const [eyeClose, setEyeClose] = useState(true);
  const [eyeReClose, setEyeReClose] = useState(true);

  const eye = <FontAwesomeIcon icon={faEye} style={{ color: "#0979F9" }} />;
  const eyeSlash = (
    <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#0979F9" }} />
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    let mobileNumber = data.mobile + "";
    const invalidMobile = new RegExp("^0[0-9]*$");
    if (invalidMobile.test(mobileNumber)) mobileNumber = mobileNumber.slice(1);

    loading(true);
    setValidated(true);

    const regFormData = {
      ...data,
      mobile: `${selectedCountryCode}${mobileNumber}`,
      // mobile: `${selectedCountryCode}${data.mobile}`,
      password_confirmation: data.password_confirm,
    };
    // console.log(regFormData);

    try {
      const resp = await api.post(
        "register",
        regFormData
        // {
        //   name: "Samindra",
        //   email: "testgmail.com",
        //   mobile: "+8801924217102",
        //   password: "123",
        //   password_confirmation: "1236546546",
        // }
      );
      // console.log(resp.data);

      dispatch(
        formFillUp({
          pageTitle: `${t("OTP.header")}`,
          pageSubTitle: `${t("OTP.secondaryHeader")}`,
          activeForm: "form2",
          formData: {
            selectedFlag,
            selectedCountryCode,
            name,
            email,
            mobile,
            password,
            rePassword,
            termAgreement,
          },
        })
      );
      nextStep(resp.data.temp_user_id);
      loading(false);
    } catch (err) {
      console.log(err);
      // console.log(err.response.data);
      const keys = Object.keys(err.response.data);

      let errorDataLog = [];
      keys.map((key) =>
        err.response.data[key].map(
          (data) => (errorDataLog = [...errorDataLog, data])
        )
      );

      let errorText = "";
      errorDataLog.map((text) => (errorText = `${errorText} ${text}`));
      // console.log(errorDataLog);

      popUpErrorText(errorText);
      loading(false);
      popUp(true);
      setTimeout(() => popUp(false), 1000);
    }
  };

  return (
    <Form validated={validated} onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label className="fw-bold">
          {t("REGISTRATION.name.label")}
          <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          {...register("name")}
          type="text"
          placeholder={`${t("REGISTRATION.name.placeholder")}`}
          onChange={(e) => setName(e.target.value)}
          className={`${!errors.name ? "was-validated" : ""}`}
          isInvalid={!!errors.name}
          maxLength="30"
        />
        <Form.Control.Feedback type="invalid">
          {errors.name?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="fw-bold">
          {t("REGISTRATION.email.label")}
          <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          {...register("email")}
          type="email"
          placeholder={`${t("REGISTRATION.email.placeholder")}`}
          onChange={(e) => setEmail(e.target.value)}
          className={`${!errors.email ? "was-validated" : ""}`}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="fw-bold">
          {t("REGISTRATION.mobile.label")}
          <span className="text-danger">*</span>
        </Form.Label>
        {/* <Form.Control type="tel" placeholder="Enter Mobile Here" /> */}

        <Row>
          <Col xxl={2} xl={2} lg={2} md={2} sm={3} xs={3}>
            <Image
              className="form-text flag"
              src={`assets/flags/${selectedFlag}.svg`}
              fluid
            />
          </Col>

          <Col>
            {/* <Form.Select onChange={(e) => handleSelection(e.target.value)}>
              {CountryCode.map((country, index) => (
                <option key={index}>
                  {`${country.code} ${country.dial_code}`}
                </option>
              ))}
            </Form.Select> */}
            <Form.Control placeholder="+880" disabled />
          </Col>

          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <Form.Control
              {...register("mobile")}
              type="text"
              maxLength={11}
              placeholder={`17XXXXXXXX`}
              // pattern="[0-9]{1}"
              // placeholder={`${selectedCountryCode} XXXX-XXXXXX`}
              onChange={(e) => setMobile(e.target.value)}
              className={`${!errors.mobile ? "was-validated" : ""}`}
              isInvalid={!!errors.mobile}
            />
            <Form.Control.Feedback type="invalid">
              {errors.mobile?.message}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label className="fw-bold">
          {t("REGISTRATION.password.label")}
          <span className="text-danger">*</span>
        </Form.Label>
        <InputGroup>
          <FormControl
            {...register("password")}
            type={!eyeClose ? "text" : "password"}
            placeholder={`${t("REGISTRATION.password.placeholder")}`}
            onChange={(e) => setPassword(e.target.value)}
            className={`${!errors.password ? "was-validated" : ""}`}
            isInvalid={!!errors.password}
          />
          <InputGroup.Text
            id="basic-addon1"
            onClick={() => setEyeClose(!eyeClose)}
          >
            {eyeClose ? eyeSlash : eye}
          </InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            {errors.password?.message}
          </Form.Control.Feedback>
        </InputGroup>

        {/* <Form.Control type="password" placeholder="Enter Password Here" /> */}
        <p className="text-sm">{t("REGISTRATION.password.subText")}</p>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicRePassword">
        <Form.Label className="fw-bold">
          {t("REGISTRATION.confirmPassword.label")}
          <span className="text-danger">*</span>
        </Form.Label>
        <InputGroup>
          <FormControl
            {...register("password_confirm")}
            type={!eyeReClose ? "text" : "password"}
            placeholder={`${t("REGISTRATION.confirmPassword.placeholder")}`}
            onChange={(e) => setRePassword(e.target.value)}
            className={`${!errors.password_confirm ? "was-validated" : ""}`}
            isInvalid={!!errors.password_confirm}
          />
          <InputGroup.Text
            id="basic-addon1"
            onClick={() => setEyeReClose(!eyeReClose)}
          >
            {eyeReClose ? eyeSlash : eye}
          </InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            {errors.password_confirm?.message}
          </Form.Control.Feedback>
        </InputGroup>
        {/* <Form.Control type="password" placeholder="Enter Re-Password Here" /> */}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check
          {...register("checkAgree")}
          type="checkbox"
          label={`${t("REGISTRATION.termChecker.text")}`}
          onChange={(e) => setTermAgreement(e.target.checked)}
          isInvalid={!!errors.checkAgree}
        />
        <a
          className="a-tag"
          href="/termsAndConditions"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t("REGISTRATION.termChecker.link")}
        </a>
        <Form.Control.Feedback type="invalid">
          {errors.checkAgree?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Button className="col-12 mt-2 fw-bold" variant="primary" type="submit">
        {t("REGISTRATION.nextBtn")}
      </Button>
    </Form>
  );
}

export default RegistrationForm;
