import axios from "axios";

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) return Buffer(token, "base64").toString("ascii");
};

const useHttp = (authorized) => {
  let token;
  if (authorized) token = getToken();

  const api = axios.create({
    // baseURL: "https://avsec.caab.gov.bd:8014/api/",
    baseURL: "https://caabapi.jhorotek.com/api/", // sandbox

    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });
  return api;
};

export default useHttp;
