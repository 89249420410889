import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import fileMedical from "../../../../../../assets/images/utility_icons/file-medical.png";
import AllFileList from "./AllFileList/AllFileList";
import { useDispatch, useSelector } from "react-redux";
import { settingsData } from "../../../../../../redux/actions";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

// custom Style
import styles from "./DropzoneUploaded.module.css";

const DropzoneUploaded = ({ removeUpload, upload, fileType }) => {
  const { t } = useTranslation();
  const [policeVerificationFile, setPoliceVerificationFile] = useState({});
  const [gsatFile, setGsatFile] = useState({});
  const [statusUploaded, setStatusUploaded] = useState(false);

  const dispatch = useDispatch();

  const myApplication = useSelector((state) => state.settingsData);

  const decideFileToState = useCallback(
    (file) => {
      switch (fileType) {
        case "policeVerification":
          dispatch(
            settingsData({
              policeVerificationFile: file.file,
            })
          );
          dispatch(
            settingsData({
              policeVerificationFileName: file.name,
            })
          );
          setPoliceVerificationFile(file);
          break;

        case "GSAT":
          dispatch(
            settingsData({
              gsatFile: file.file,
            })
          );
          dispatch(
            settingsData({
              gsatFileName: file.name,
            })
          );
          setGsatFile(file);
          break;

        default:
          return null;
      }
    },
    [fileType, dispatch, setPoliceVerificationFile, setGsatFile]
  );

  const removeFileFromState = useCallback(() => {
    setStatusUploaded(false);
    switch (fileType) {
      case "policeVerification":
        dispatch(
          settingsData({
            policeVerificationFile: "deleted",
          })
        );
        dispatch(
          settingsData({
            policeVerificationFileName: "",
          })
        );
        dispatch(
          settingsData({
            policeVerificationValidTill: "",
          })
        );
        setPoliceVerificationFile({});
        break;

      case "GSAT":
        dispatch(
          settingsData({
            gsatFile: "deleted",
          })
        );
        dispatch(
          settingsData({
            gsatFileName: "",
          })
        );
        dispatch(
          settingsData({
            gsatValidTill: "",
          })
        );
        setGsatFile({});
        break;

      default:
        break;
    }
  }, [
    fileType,
    dispatch,
    setPoliceVerificationFile,
    setGsatFile,
    setStatusUploaded,
  ]);

  const fileListDecider = useCallback(() => {
    switch (fileType) {
      case "policeVerification":
        return <AllFileList acceptedFiles={policeVerificationFile} />;
      case "GSAT":
        return <AllFileList acceptedFiles={gsatFile} />;

      default:
        return null;
    }
  }, [fileType, policeVerificationFile, gsatFile]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        const localFileUrl = window.URL.createObjectURL(acceptedFiles[0]);
        const file = {
          name: acceptedFiles[0].name,
          value: localFileUrl,
          file: acceptedFiles[0],
        };
        decideFileToState(file);
        setStatusUploaded(true);
        console.log(file);
      }

      if (rejectedFiles.length > 0) {
        toast.error(rejectedFiles[0].errors[0].message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
      // console.log(acceptedFiles);
      // console.log(rejectedFiles);
    },
    [decideFileToState, setStatusUploaded]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 1,
    accept: "application/pdf",
    multiple: false,
    maxSize: 5242880,
    onDrop,
  });

  const openUploadDialogue = useCallback(() => open(), [open]);

  const getPreviousState = useCallback(() => {
    let file = {
      name: "",
      value: "",
    };

    switch (fileType) {
      case "policeVerification":
        file.value = myApplication.policeVerificationFile;
        file.validTill = myApplication.policeVerificationValidTill;
        if (file.validTill) {
          file.name = "Police Verification";
          setPoliceVerificationFile(file);
          setStatusUploaded(true);
        }
        break;

      case "GSAT":
        file.value = myApplication.gsatFile;
        file.validTill = myApplication.gsatValidTill;
        if (file.validTill) {
          file.name = "GSAT Certificate";
          setGsatFile(file);
          setStatusUploaded(true);
        }
        break;

      default:
        break;
    }
  }, [
    fileType,
    setPoliceVerificationFile,
    setGsatFile,
    setStatusUploaded,
    myApplication,
  ]);

  useEffect(() => {
    getPreviousState();
    removeUpload.current = removeFileFromState;
    upload.current = openUploadDialogue;
  }, [
    removeUpload,
    upload,
    removeFileFromState,
    openUploadDialogue,
    getPreviousState,
  ]);

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      /> */}
      <div {...getRootProps({ className: `${styles.dropzone}` })}>
        <input {...getInputProps()} />
        <img src={fileMedical} alt="File Medical" />
        <p className={styles.innerText}>
          {t("SETTINGS.placeholderDropzone.colorBefore")}
        </p>
        <p className={styles.innerText}>
          <span className={styles.textColor}>
            {t("SETTINGS.placeholderDropzone.color")}
          </span>{" "}
          {t("SETTINGS.placeholderDropzone.colorAfter")}
        </p>
      </div>
      {statusUploaded && fileListDecider()}
    </>
  );
};

export default DropzoneUploaded;
