const initialState = {
  stepCount: 1,
  activePart: "Part A",
};

const newApplicationStateTracker = (state = initialState, action) => {
  let currentState = { ...state };
  let formData = action.payload;

  switch (action.type) {
    case "CHANGE_NEXT_STATE":
      if (currentState.stepCount < 10) {
        // currentState.stepCount++;
        currentState.stepCount += formData;
      }
      return currentState;

    case "CHANGE_PREVIOUS_STATE":
      if (currentState.stepCount > 1) {
        // currentState.stepCount--;
        currentState.stepCount -= formData;
      }
      return currentState;

    case "RESET_APPLICATION_STATE":
      currentState.stepCount = 1; //this was initially 1 but since user cannot apply for vehicle this is by default 2
      return currentState;

    case "CHANGE_ACTIVE_APPLICATION_PART":
      return { ...state, activePart: formData };

    default:
      return state;
  }
};

export default newApplicationStateTracker;
