import React, { useState, useEffect } from "react";
import i18n from "i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";

import image1 from "../../assets/images/slides/1.png";
import image2 from "../../assets/images/slides/2.png";
import image3 from "../../assets/images/slides/3.png";
import image4 from "../../assets/images/slides/4.png";
import image5 from "../../assets/images/slides/5.png";
import image6 from "../../assets/images/slides/6.png";
import banner from "../../assets/images/slides/bannerLogo.png";
import logo from "../../assets/images/caabLogo.png";

// custom style
import styles from "./Background.module.css";

function Background(props) {
  const { children } = props;
  const [currentLng, setCurrentLng] = useState("");

  useEffect(() => {
    setCurrentLng(document.cookie);
  }, []);

  return (
    <Container fluid className={styles.containerBackground}>
      <div className={styles.toggleContainer}>
        <input
          type="checkbox"
          id="switchLogin"
          className={styles.checkbox}
          // defaultChecked={currentLng === "i18next=bn-BD" ? false : true}
          checked={currentLng === "i18next=bn-BD" ? false : true}
          onChange={(ev) => {
            // console.log(ev.target.checked);
            // console.log(currentLng);

            // ev.target.checked
            if (currentLng === "i18next=en-US") {
              i18n.changeLanguage("bn-BD");
              setCurrentLng("i18next=bn-BD");
            }
            if (currentLng === "i18next=en-GB") {
              i18n.changeLanguage("bn-BD");
              setCurrentLng("i18next=bn-BD");
            }
            if (currentLng === "i18next=bn-BD") {
              i18n.changeLanguage("en-US");
              setCurrentLng("i18next=en-US");
            }
          }}
        />
        <label htmlFor="switchLogin" className={styles.toggle}>
          <p className="textLanguage">বাং EN</p>
          {/* BN */}
        </label>
      </div>

      <img className={styles.bannerImage} src={banner} alt="Banner Image" />
      <img className={styles.caabImage} src={logo} alt="CAAB Logo" />
      <Row className={styles.containerRow}>
        <Col xl={6} ld={6} md={8} sm={12} className={styles.columnBackground}>
          {/* <div className={styles.childComp}> */}
          {children}
          {/* </div> */}
        </Col>
        <Col className={styles.columnBackground}>
          {/* <Carousel interval={3000}>
            <Carousel.Item>
              <div className={styles.imageContainer}>
                <img
                  className={styles.carouselImage}
                  src={image1}
                  alt="First slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className={styles.imageContainer}>
                <img
                  className={styles.carouselImage}
                  src={image2}
                  alt="Second slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className={styles.imageContainer}>
                <img
                  className={styles.carouselImage}
                  src={image3}
                  alt="Third slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className={styles.imageContainer}>
                <img
                  className={styles.carouselImage}
                  src={image4}
                  alt="Fourth slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className={styles.imageContainer}>
                <img
                  className={styles.carouselImage}
                  src={image5}
                  alt="Fifth slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className={styles.imageContainer}>
                <img
                  className={styles.carouselImage}
                  src={image6}
                  alt="Sixth slide"
                />
              </div>
            </Carousel.Item>
          </Carousel> */}
        </Col>
        {/* <Carousel.Item>
              <img className="d-block w-100" src={image3} alt="Third slide" />
            </Carousel.Item> */}
      </Row>
    </Container>
  );
}

export default Background;
