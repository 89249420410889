import React from 'react'
// custom Style
import "./NotificationTitle.css"
const NotificationTitle=({header,subTitle})=> {
    return (
        <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="starter-text">Notification</div>
            <div className="header-text">{header}</div>
            <div className="sub-title-text">{subTitle}</div>
        </div>
    )
}

export default NotificationTitle;