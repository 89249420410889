import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { resetFormFillUp } from "../../redux/actions";
import useHttp from "../../Http/useHttp";
import { toast } from "react-toastify";

// Validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useTranslation } from "react-i18next";

//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// Validation Rules
let schema = yup.object().shape({
  password: yup
    .string()
    .min(6, "It must be at least 8 characters")
    .max(15, "It must be at most 16 characters")
    .matches(/(?=.*[a-z])/, {
      message: "It must have at least one lowercase alphabet",
      excludeEmptyString: true,
    })
    .matches(/(?=.*[A-Z])/, {
      message: "It must have at least one uppercase alphabet",
      excludeEmptyString: true,
    })
    .matches(/(?=.*\d)/, {
      message: "It must have at least one digit exists",
      excludeEmptyString: true,
    })
    .matches(/(?=.*\W)/, {
      message: "It must have at least one Symbol character exists",
      excludeEmptyString: true,
    })
    .required("Valid Password is Required !!"),
  rePassword: yup
    .string()
    .oneOf([yup.ref("password"), null])
    .required("Valid Re-Password is Required !!"),
});

function ResetPasswordForm2() {
  const { t } = useTranslation();

  const api = useHttp();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.resetPasswordReducer.formData);

  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  //Font awesome initialization
  const [eyeClose, setEyeClose] = useState(true);
  const [eyeReClose, setEyeReClose] = useState(true);

  const eye = <FontAwesomeIcon icon={faEye} style={{ color: "#0979F9" }} />;
  const eyeSlash = (
    <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#0979F9" }} />
  );

  // Validation
  // const [validated, setValidated] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    // console.log(data);
    // console.log("Request Server for Setting new password");

    const formData = {
      new_password: password,
      new_confirm_password: rePassword,
    };

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        // const resp =
        await api.post("password-reset", formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log(resp.data);

        dispatch(
          resetFormFillUp({
            pageTitle: "Success",
            pageSubTitle: "Awesome",
            activeForm: "form4",
            formData: "",
          })
        );
        resolve();
      } catch (error) {
        console.log(error.response);
        reject(error.response.data.message);
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Password Reset Successful",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  return (
    <>
      <Form
        // validated={validated}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className="fw-bold">
            {t("RESETPASSWORD.password.label")}
            <span className="text-danger">*</span>
          </Form.Label>
          <InputGroup>
            <FormControl
              type={!eyeClose ? "text" : "password"}
              placeholder={`${t("RESETPASSWORD.password.placeholder")}`}
              {...register("password")}
              onChange={(e) => setPassword(e.target.value)}
              // required
              className={`${!errors.password ? "was-validated" : ""}`}
              isInvalid={!!errors.password}
            />
            <InputGroup.Text
              id="basic-addon1"
              onClick={() => setEyeClose(!eyeClose)}
            >
              {eyeClose ? eyeSlash : eye}
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors.password?.message}
            </Form.Control.Feedback>
          </InputGroup>

          {/* <Form.Control type="password" placeholder="Enter Password Here" /> */}
          <p className="text-sm">{t("RESETPASSWORD.password.subText")}</p>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicRePassword">
          <Form.Label className="fw-bold">
            {t("RESETPASSWORD.confirmPassword.label")}
            <span className="text-danger">*</span>
          </Form.Label>
          <InputGroup>
            <FormControl
              {...register("rePassword")}
              type={!eyeReClose ? "text" : "password"}
              placeholder={`${t("RESETPASSWORD.confirmPassword.placeholder")}`}
              onChange={(e) => setRePassword(e.target.value)}
              // required
              className={`${!errors.rePassword ? "was-validated" : ""}`}
              isInvalid={!!errors.rePassword}
            />
            <InputGroup.Text
              id="basic-addon1"
              onClick={() => setEyeReClose(!eyeReClose)}
            >
              {eyeReClose ? eyeSlash : eye}
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors.rePassword?.message}
            </Form.Control.Feedback>
          </InputGroup>
          {/* <Form.Control type="password" placeholder="Enter Re-Password Here" /> */}
        </Form.Group>

        <Button className="col-12 mt-2 fw-bold" variant="primary" type="submit">
          {t("RESETPASSWORD.nextBtn")}
        </Button>
      </Form>
    </>
  );
}

export default ResetPasswordForm2;
