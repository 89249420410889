import React from "react";
import { useSelector } from "react-redux";
import RadioLabel from "../../../../Utilities/RadioLabel/RadioLabel";
// import PDFAttachment from "../../../../Utilities/PDFAttachment/PDFAttachment";
import InputField from "../../../../Utilities/InputField/InputField";
import Selector from "../../../../Utilities/Selector/Selector";
import TextInputArea from "../../../../Utilities/TextInputArea/TextInputArea";
import AuthorizationArea from "../../../../Utilities/AuthorizationArea/AuthorizationArea";
// import StickyActivityLog from "./Utilities/StickyActivityLog/StickyActivityLog";

import { useTranslation } from "react-i18next";
import Attachments from "../../../../Utilities/Attachments/Attachments";

// import { PDFDownloadLink } from "@react-pdf/renderer";
// import PdfDocument from "../PdfDocument/PdfDocument";

// custom style
import "./ApplicantDetail.css";

const ApplicantDetail = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  // const application = useLocation();

  // const api = useHttp("authorized");
  // const { logout } = useAuth();

  const applicationData = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const natureOrganization = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.govt"
      )}`,
      value: "GOVT",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.apbe"
      )}`,
      value: "APBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.atbe"
      )}`,
      value: "ATBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.ehci"
      )}`,
      value: "EHCI",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.cotn"
      )}`,
      value: "COTN",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.prvt"
      )}`,
      value: "PRVT",
    },
  ];

  const authorizationTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeAuthorization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `National Aviation Security (AVSEC) Inspector`,
      value: "Security_AVSEC",
    },
    {
      label: `National Aviation Safety (ANS) Inspector`,
      value: "Safety_ANS",
    },
    {
      label: `National Aviation Safety (OPS) Inspector`,
      value: "Safety_OPS",
    },
    {
      label: `National Aviation Safety (AGA) Inspector`,
      value: "Safety_AGA",
    },
    {
      label: `National Aviation Safety (OPS/PEL) Inspector`,
      value: "Safety_OPS_PEL",
    },
    {
      label: `National Aviation Safety (AIR) Inspector`,
      value: "Safety_AIR",
    },
  ];

  const CmapCategories = [
    {
      label: `Select Required CMAP Category`,
      value: "",
    },
    {
      label: `Pilot (Schedule Passenger Operation)`,
      value: "P-SPO",
    },
    {
      label: `Pilot (Helicopter)`,
      value: "P-H",
    },
    {
      label: `Pilot (Flight Cargo)`,
      value: "P-FC",
    },
    {
      label: `Cabin Crew (Schedule Passenger Operation)`,
      value: "CC-SPO",
    },
    {
      label: `Flying School Instructor (FSI)`,
      value: "FSI",
    },
    {
      label: `Flying School Trainee (FST)`,
      value: "FST",
    },
  ];

  // console.log(application.state);

  // const downloadFile = (url) => {
  //   if (url) window.open(url);
  //   else return;
  // };

  const PartC_NIAC = () => {
    return (
      <>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.divisionName.label"
            )}`}
            name="division_name"
            defaultValue={
              applicationData.partC.divisionName
                ? applicationData.partC.divisionName
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={
              applicationData.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            defaultValue={
              applicationData.partC.designation
                ? applicationData.partC.designation
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="currentPosition"
            defaultValue={
              applicationData.partC.currentPosition
                ? applicationData.partC.currentPosition
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
            name="date_Of_joining_in_CAAB"
            defaultValue={
              applicationData.partC.dateOfJoining
                ? applicationData.partC.dateOfJoining
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            defaultValue={
              applicationData.partC.typeOfEmployment
                ? applicationData.partC.typeOfEmployment
                : ""
            }
            disable={true}
          />
        </div>
        {applicationData.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              defaultValue={
                applicationData.partC.typeOfEmploymentOther
                  ? applicationData.partC.typeOfEmploymentOther
                  : ""
              }
              disabled={true}
            />
          </div>
        )}

        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeAuthorization.label"
            )}`}
            name="typeOfAuthorization"
            defaultValue={
              applicationData.partC.typeOfAuthorization
                ? applicationData.partC.typeOfAuthorization
                : ""
            }
            disable={true}
          /> */}

          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeAuthorization.label"
            )}`}
            listItem={authorizationTypes}
            defaultValue={
              applicationData.partC.typeOfAuthorization
                ? applicationData.partC.typeOfAuthorization
                : ""
            }
            disable
          />
        </div>

        {applicationData.partC.typeOfAuthorization !== "Security_AVSEC" && (
          <>
            <div className="col-12 mt-3">
              <TextInputArea
                label="Authorization Specification"
                name="typeOfAuthorizationSafety"
                defaultValue={
                  applicationData.partC.typeOfAuthorizationSafety
                    ? applicationData.partC.typeOfAuthorizationSafety
                    : ""
                }
                disabled={true}
              />
            </div>

            {/* <div className="col-12 mt-3">
              <PDFAttachment
                fileLink={
                  applicationData.partC.typeOfAuthorizationSafetyFile
                    ? applicationData.partC.typeOfAuthorizationSafetyFile
                    : ""
                }
                filePlaceholder="Approved Office Order"
              />
            </div> */}
          </>
        )}
      </>
    );
  };

  const PartC_NAP = () => {
    return (
      <>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.divisionName.label"
            )}`}
            name="division_name"
            defaultValue={
              applicationData.partC.divisionName
                ? applicationData.partC.divisionName
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={
              applicationData.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            defaultValue={
              applicationData.partC.designation
                ? applicationData.partC.designation
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="currentPosition"
            defaultValue={
              applicationData.partC.currentPosition
                ? applicationData.partC.currentPosition
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
            name="date_Of_joining_in_CAAB"
            defaultValue={
              applicationData.partC.dateOfJoining
                ? applicationData.partC.dateOfJoining
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            defaultValue={
              applicationData.partC.typeOfEmployment
                ? applicationData.partC.typeOfEmployment
                : ""
            }
            disable={true}
          />
        </div>
        {applicationData.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              defaultValue={
                applicationData.partC.typeOfEmploymentOther
                  ? applicationData.partC.typeOfEmploymentOther
                  : ""
              }
              disabled={true}
            />
          </div>
        )}

        <div className="col-12 mt-3">
          <TextInputArea
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.describeNatureDuty.label"
            )}`}
            name="natureOfDutyDescription"
            defaultValue={
              applicationData.partC.natureOfDutyDescription
                ? applicationData.partC.natureOfDutyDescription
                : ""
            }
            disabled={true}
          />
        </div>
        {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
          <PDFAttachment
            fileLink={
              applicationData.partC.natureOfDutyDescriptionFile
                ? applicationData.partC.natureOfDutyDescriptionFile
                : ""
            }
            filePlaceholder="Approved Office Order"
          />
        </div> */}
      </>
    );
  };

  const PartC_AAIAP = () => {
    return (
      <>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.divisionName.label"
            )}`}
            name="division_name"
            defaultValue={
              applicationData.partC.divisionName
                ? applicationData.partC.divisionName
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={
              applicationData.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            defaultValue={
              applicationData.partC.designation
                ? applicationData.partC.designation
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="currentPosition"
            defaultValue={
              applicationData.partC.currentPosition
                ? applicationData.partC.currentPosition
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
            name="date_Of_joining_in_CAAB"
            defaultValue={
              applicationData.partC.dateOfJoining
                ? applicationData.partC.dateOfJoining
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            defaultValue={
              applicationData.partC.typeOfEmployment
                ? applicationData.partC.typeOfEmployment
                : ""
            }
            disable={true}
          />
        </div>
        {applicationData.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              defaultValue={
                applicationData.partC.typeOfEmploymentOther
                  ? applicationData.partC.typeOfEmploymentOther
                  : ""
              }
              disabled={true}
            />
          </div>
        )}
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`Type of Assignment`}
            name="typeOfAssignment"
            disable
            defaultValue={applicationData.partC.typeOfAssignment}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`Category of Investigator`}
            name="investigatorCategory"
            disable
            defaultValue={applicationData.partC.investigatorCategory}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`Investigator ID Number`}
            name="investigatorID"
            disable
            defaultValue={applicationData.partC.investigatorID}
          />
        </div>
      </>
    );
  };

  const PartC_ASP = () => {
    return (
      <>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              applicationData.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            defaultValue={
              applicationData.partC.designation
                ? applicationData.partC.designation
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="currentPosition"
            defaultValue={
              applicationData.partC.currentPosition
                ? applicationData.partC.currentPosition
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            defaultValue={
              applicationData.partC.typeOfEmployment
                ? applicationData.partC.typeOfEmployment
                : ""
            }
            disable={true}
          />
        </div>

        {applicationData.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              defaultValue={
                applicationData.partC.typeOfEmploymentOther
                  ? applicationData.partC.typeOfEmploymentOther
                  : ""
              }
              disabled={true}
            />
          </div>
        )}

        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.natureDuty.label")}`}
            name="natureOfDuty"
            defaultValue={
              applicationData.partC.natureOfDuty
                ? applicationData.partC.natureOfDuty
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
            )}`}
            name="scopeOfOperation"
            defaultValue={
              applicationData.partC.scopeOfOperation
                ? applicationData.partC.scopeOfOperation
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
            )}`}
            name="locationOfDuty"
            defaultValue={
              applicationData.partC.locationOfDuty
                ? applicationData.partC.locationOfDuty
                : ""
            }
            disable={true}
          />
        </div>
      </>
    );
  };

  const PartC_CMAP = () => {
    return (
      <>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              applicationData.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            defaultValue={
              applicationData.partC.designation
                ? applicationData.partC.designation
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="currentPosition"
            defaultValue={
              applicationData.partC.currentPosition
                ? applicationData.partC.currentPosition
                : ""
            }
            disable={true}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            defaultValue={
              applicationData.partC.typeOfEmployment
                ? applicationData.partC.typeOfEmployment
                : ""
            }
            disable={true}
          />
        </div>
        {applicationData.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              defaultValue={
                applicationData.partC.typeOfEmploymentOther
                  ? applicationData.partC.typeOfEmploymentOther
                  : ""
              }
              disabled={true}
            />
          </div>
        )}
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
            )}`}
            name="scopeOfOperation"
            defaultValue={
              applicationData.partC.scopeOfOperation
                ? applicationData.partC.scopeOfOperation
                : ""
            }
            disable={true}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {/* <PDFDownloadLink document={<PdfDocument />}>
        {({ loading }) =>
          loading ? <button>Loading...</button> : <button>Download</button>
        }
      </PDFDownloadLink> */}
      <div className="content-adjuster-applicant">
        <div className="inner-content-applicant">
          {/* <StickyActivityLog /> */}
          <div className="applicant-top-header">
            <div className="applicant-id-header">
              {t("NEWAPPLICATION.ApplicantDetail.ApplicationLabel")}{" "}
              <span className="id-header-color">
                {applicationData.partA.applicationId}
              </span>
            </div>
            <div className="applicant-primary-header">
              {t("NEWAPPLICATION.confirmApplication.header.partA")}
            </div>
          </div>

          {/* ================= part A ================= */}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.confirmApplication.organizationCode"
                )}`}
                name="organizationCode"
                defaultValue={
                  applicationData.partA.orgCode
                    ? applicationData.partA.orgCode
                    : " "
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t("NEWAPPLICATION.confirmApplication.typeCard")}`}
                name="typeCard"
                defaultValue={
                  applicationData.partA.passType
                    ? applicationData.partA.passType
                    : ""
                }
                disable={true}
              />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t("NEWAPPLICATION.confirmApplication.typeIssue")}`}
                name="typeIssue"
                defaultValue={
                  applicationData.partA.issueType
                    ? applicationData.partA.issueType
                    : ""
                }
                disable={true}
              />
            </div>

            {applicationData.partA.passType === "ASP" && (
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                <InputField
                  type="text"
                  label="Selected Airport"
                  name="siteName"
                  defaultValue={
                    applicationData.partA.siteName
                      ? applicationData.partA.siteName
                      : ""
                  }
                  disable={true}
                />
              </div>
            )}

            {applicationData.partA.issueType !== "Initial" && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.typeIssueSelected.passNumber.label"
                    )}`}
                    name="passNumber"
                    defaultValue={
                      applicationData.partA.existingPassNumber
                        ? applicationData.partA.existingPassNumber
                        : ""
                    }
                    disable={true}
                  />
                </div>

                {applicationData.partA.passType === "CMAP" && (
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                    <Selector
                      label="Existing CMAP Type"
                      listItem={CmapCategories}
                      placeholder="Select One"
                      disable
                      defaultValue={
                        applicationData.partA.existingCMAPcategory
                          ? applicationData.partA.existingCMAPcategory
                          : ""
                      }
                    />
                  </div>
                )}

                {applicationData.partA.passType === "ASP" && (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                      <InputField
                        type="text"
                        label={`${t(
                          "NEWAPPLICATION.typeIssueSelected.colorOfCard.label"
                        )}`}
                        name="colorCard"
                        defaultValue={
                          applicationData.partA.colorOfPass
                            ? applicationData.partA.colorOfPass
                            : ""
                        }
                        disable={true}
                      />
                    </div>
                    {/* {applicationData.partA.passType === "ASP" && (
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                        <AuthorizationArea
                          checkboxes={applicationData.partA}
                          isDisable
                        />
                      </div>
                    )} */}
                  </>
                )}

                {(applicationData.partA.passType === "ASP" ||
                  applicationData.partA.passType === "NAP") && (
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <AuthorizationArea
                      checkboxes={applicationData.partA}
                      isDisable
                    />
                  </div>
                )}

                {applicationData.partA.issueType ===
                  "Re-issue (Lost /Stolen)" && (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                      <InputField
                        type="text"
                        label={`${t(
                          "NEWAPPLICATION.typeIssueSelected.gdNumber.label"
                        )}`}
                        name="gdNumber"
                        defaultValue={
                          applicationData.partA.gdNumber
                            ? applicationData.partA.gdNumber
                            : ""
                        }
                        disable={true}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                      <InputField
                        type="text"
                        label={`${t(
                          "NEWAPPLICATION.typeIssueSelected.dateOfEntry.label"
                        )}`}
                        name="gdEntry"
                        defaultValue={
                          applicationData.partA.gdEntry
                            ? applicationData.partA.gdEntry
                            : ""
                        }
                        disable={true}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                      <InputField
                        type="text"
                        label={`${t(
                          "NEWAPPLICATION.typeIssueSelected.policeStationName.label"
                        )}`}
                        name="psName"
                        defaultValue={
                          applicationData.partA.psName
                            ? applicationData.partA.psName
                            : ""
                        }
                        disable={true}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          {/* ================= part B ================= */}
          <div className="margin-primary-header">
            <div className="applicant-primary-header">
              {t("NEWAPPLICATION.confirmApplication.header.partB")}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.name.label"
                )}`}
                name="name"
                defaultValue={
                  applicationData.partB.name ? applicationData.partB.name : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.displayName.label"
                )}`}
                name="displayName"
                defaultValue={
                  applicationData.partB.displayName
                    ? applicationData.partB.displayName
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.fatherName.label"
                )}`}
                name="fatherName"
                defaultValue={
                  applicationData.partB.fathersName
                    ? applicationData.partB.fathersName
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.motherName.label"
                )}`}
                name="motherName"
                defaultValue={
                  applicationData.partB.mothersName
                    ? applicationData.partB.mothersName
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                isMandatory={false}
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.spouseName.label"
                )}`}
                name="spouseName"
                defaultValue={
                  applicationData.partB.spouseName
                    ? applicationData.partB.spouseName
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.nationality.label"
                )}`}
                name="nationality"
                defaultValue={
                  applicationData.partB.nationality
                    ? applicationData.partB.nationality
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.nationalIDNumber.label"
                )}`}
                name="nationalIdNumber"
                defaultValue={
                  applicationData.partB.nid ? applicationData.partB.nid : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.dateOfBirth.label"
                )}`}
                name="dob"
                defaultValue={
                  applicationData.partB.dob ? applicationData.partB.dob : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.mobileNumber.label"
                )}`}
                name="mobileNumber"
                defaultValue={
                  applicationData.partB.mobile
                    ? applicationData.partB.mobile
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.gender.label"
                )}`}
                name="gender"
                defaultValue={
                  applicationData.partB.gender
                    ? applicationData.partB.gender
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.bloodGroup.label"
                )}`}
                name="bloodGroup"
                defaultValue={
                  applicationData.partB.bloodGroup
                    ? applicationData.partB.bloodGroup
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.emailAddress.label"
                )}`}
                name="emailAddress"
                defaultValue={
                  applicationData.partB.email ? applicationData.partB.email : ""
                }
                disable={true}
              />
            </div>
            {/* <div className="col-12 mt-2">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-3">
                  <div className="picture-field">
                    <label className="form-label upload-picture-field-label_2">
                      {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                    </label>
                    <img
                      src={
                        applicationData.partB.profilePic
                          ? applicationData.partB.profilePic
                          : profileImage
                      }
                      className="image-styling img-fluid img-thumbnail rounded"
                      alt="user-pic"
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-8 col-12 mt-3">
                  <div className="picture-field">
                    <label className="form-label upload-picture-field-label_2">
                      {`${t(
                        "NEWAPPLICATION.confirmApplication.policeVerificationFile"
                      )}`}
                    </label>
                    <li className="file-upload-view-item">
                      <span className="file-upload-view__content ms-3">
                        <FontAwesomeIcon
                          className="file-upload-view__icon"
                          icon={faFileArchive}
                        />
                        <p className="file-upload-view__text ms-2">
                          {applicationData.partB.policeVerificationFile
                            ? "Police Verification"
                            : "============ NOT UPLOADED ============"}
                        </p>
                      </span>
                      {applicationData.partB.policeVerificationFile && (
                        <div className="file-upload-view-buttons">
                          <FontAwesomeIcon
                            className="icon-blue me-4"
                            icon={faCloudDownloadAlt}
                            onClick={() =>
                              downloadFile(
                                applicationData.partB.policeVerificationFile
                              )
                            }
                          />
                        </div>
                      )}
                    </li>
                  </div>
                  <div className="mt-2">
                    <InputField
                      type="text"
                      label={`${t(
                        "NEWAPPLICATION.applicantsInformation.validTill.label"
                      )}`}
                      name="policeVerificationValidTill"
                      defaultValue={
                        applicationData.partB.policeVerificationValidTill
                          ? applicationData.partB.policeVerificationValidTill
                          : ""
                      }
                      disable={true}
                    />
                  </div>
                  <div className="picture-field mt-5">
                    <label className="form-label upload-picture-field-label_2">
                      {`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
                    </label>
                    <li className="file-upload-view-item">
                      <span className="file-upload-view__content ms-3">
                        <FontAwesomeIcon
                          className="file-upload-view__icon"
                          icon={faFileArchive}
                        />
                        <p className="file-upload-view__text ms-2">
                          {applicationData.partB.gsatFile
                            ? "GSAT Certificate"
                            : "============ NOT UPLOADED ============"}
                        </p>
                      </span>
                      {applicationData.partB.gsatFile && (
                        <div className="file-upload-view-buttons">
                          <FontAwesomeIcon
                            className="icon-blue me-4"
                            icon={faCloudDownloadAlt}
                            onClick={() =>
                              downloadFile(applicationData.partB.gsatFile)
                            }
                          />
                        </div>
                      )}
                    </li>
                  </div>
                  <div className="mt-2">
                    <InputField
                      type="text"
                      label={`${t(
                        "NEWAPPLICATION.applicantsInformation.validTill.label"
                      )}`}
                      name="gsatVerificationValidTill"
                      defaultValue={
                        applicationData.partB.gsatValidTill
                          ? applicationData.partB.gsatValidTill
                          : ""
                      }
                      disable={true}
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="applicant-secondary-header">
            {t("NEWAPPLICATION.addressInformation.permanentAddressHeader")}
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.houseNumber.label"
                )}`}
                name="houseNumber"
                defaultValue={
                  applicationData.partB.address.permanentAddress.house
                    ? applicationData.partB.address.permanentAddress.house
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t("NEWAPPLICATION.addressInformation.street.label")}`}
                name="road_no"
                defaultValue={
                  applicationData.partB.address.permanentAddress.street
                    ? applicationData.partB.address.permanentAddress.street
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t("NEWAPPLICATION.addressInformation.city.label")}`}
                name="city"
                defaultValue={
                  applicationData.partB.address.permanentAddress.city
                    ? applicationData.partB.address.permanentAddress.city
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.policeStation.label"
                )}`}
                name="police_station"
                defaultValue={
                  applicationData.partB.address.permanentAddress.ps
                    ? applicationData.partB.address.permanentAddress.ps
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.district.label"
                )}`}
                name="district"
                defaultValue={
                  applicationData.partB.address.permanentAddress.dist
                    ? applicationData.partB.address.permanentAddress.dist
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.postCode.label"
                )}`}
                name="post_code"
                defaultValue={
                  applicationData.partB.address.permanentAddress.zip
                    ? applicationData.partB.address.permanentAddress.zip
                    : ""
                }
                disable={true}
              />
            </div>
          </div>
          <div className="applicant-secondary-header">
            {t("NEWAPPLICATION.addressInformation.presentAddressHeader")}
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.houseNumber.label"
                )}`}
                name="houseNumber"
                defaultValue={
                  applicationData.partB.address.presentAddress.house
                    ? applicationData.partB.address.presentAddress.house
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t("NEWAPPLICATION.addressInformation.street.label")}`}
                name="road_no"
                defaultValue={
                  applicationData.partB.address.presentAddress.street
                    ? applicationData.partB.address.presentAddress.street
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t("NEWAPPLICATION.addressInformation.city.label")}`}
                name="city"
                defaultValue={
                  applicationData.partB.address.presentAddress.city
                    ? applicationData.partB.address.presentAddress.city
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.policeStation.label"
                )}`}
                name="police_station"
                defaultValue={
                  applicationData.partB.address.presentAddress.ps
                    ? applicationData.partB.address.presentAddress.ps
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.district.label"
                )}`}
                name="district"
                defaultValue={
                  applicationData.partB.address.presentAddress.dist
                    ? applicationData.partB.address.presentAddress.dist
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.addressInformation.postCode.label"
                )}`}
                name="post_code"
                defaultValue={
                  applicationData.partB.address.presentAddress.zip
                    ? applicationData.partB.address.presentAddress.zip
                    : ""
                }
                disable={true}
              />
            </div>
          </div>

          {/* ================= part C ================= */}
          <div className="margin-primary-header">
            <div className="applicant-primary-header">
              {`${t("NEWAPPLICATION.confirmApplication.header.partC")}`}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.organizationName.label"
                )}`}
                name="organizationName"
                defaultValue={
                  applicationData.partC.organizationName
                    ? applicationData.partC.organizationName
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="col-12 mt-3">
              <Selector
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
                )}`}
                listItem={natureOrganization}
                defaultValue={
                  applicationData.partC.natureOfOrganization
                    ? applicationData.partC.natureOfOrganization
                    : ""
                }
                disable
              />
              {/* <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
                )}`}
                name="natureOfOrganization"
                defaultValue={
                  applicationData.partC.natureOfOrganization
                    ? applicationData.partC.natureOfOrganization
                    : ""
                }
                disable={true}
              /> */}
            </div>
            {applicationData.partA.passType === "NIAC" && <PartC_NIAC />}
            {applicationData.partA.passType === "NAP" && <PartC_NAP />}
            {applicationData.partA.passType === "ASP" && <PartC_ASP />}
            {applicationData.partA.passType === "CMAP" && <PartC_CMAP />}
            {applicationData.partA.passType === "AAIAP" && <PartC_AAIAP />}
          </div>

          {/* ================= part D ================= */}
          {(applicationData.partA.passType === "ASP" ||
            applicationData.partA.passType === "CMAP") && (
            <>
              <div className="margin-primary-header">
                <div className="applicant-primary-header">
                  {t("NEWAPPLICATION.confirmApplication.header.partD")}
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.foreignNational.nationality.label"
                    )}`}
                    name="nationality"
                    defaultValue={
                      applicationData.partE.foreignNationality
                        ? applicationData.partE.foreignNationality
                        : "N/A"
                    }
                    disable={true}
                  />
                </div>
                {applicationData.partA.passType === "ASP" && (
                  <div className="col-12 mt-3">
                    <InputField
                      type="text"
                      label={`${t(
                        "NEWAPPLICATION.foreignNational.natureAssignment.label"
                      )}`}
                      name="natureOfAssignmentBD"
                      defaultValue={
                        applicationData.partE.natureOfAssignmentBD
                          ? applicationData.partE.natureOfAssignmentBD
                          : "N/A"
                      }
                      disable={true}
                    />
                  </div>
                )}
              </div>
              <div className="confirm-header-tertiary-text mt-4">
                {`${t("NEWAPPLICATION.foreignNational.travelDocumentHeader")}`}
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.foreignNational.passportNumber.label"
                    )}`}
                    name="passportDocumentNumber"
                    defaultValue={
                      applicationData.partE.passportDocumentNumber
                        ? applicationData.partE.passportDocumentNumber
                        : "N/A"
                    }
                    disable={true}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.foreignNational.placeIssue.label"
                    )}`}
                    name="placeOfIssue"
                    defaultValue={
                      applicationData.partE.placeOfIssue
                        ? applicationData.partE.placeOfIssue
                        : "N/A"
                    }
                    disable={true}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.foreignNational.expiryDate.label"
                    )}`}
                    name="passportDocumentExpiryDate"
                    defaultValue={
                      applicationData.partE.passportDocumentExpiryDate
                        ? applicationData.partE.passportDocumentExpiryDate
                        : "N/A"
                    }
                    disable={true}
                  />
                </div>
              </div>
              <div className="confirm-header-tertiary-text mt-4">
                {`${t("NEWAPPLICATION.foreignNational.visaHeader")}`}
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.foreignNational.typeVisa.label"
                    )}`}
                    name="typeOfVisa"
                    defaultValue={
                      applicationData.partE.typeOfVisa
                        ? applicationData.partE.typeOfVisa
                        : "N/A"
                    }
                    disable={true}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.foreignNational.validityVisa.label"
                    )}`}
                    name="visaExpiryDate"
                    defaultValue={
                      applicationData.partE.visaExpiryDate
                        ? applicationData.partE.visaExpiryDate
                        : "N/A"
                    }
                    disable={true}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.foreignNational.visaNumber.label"
                    )}`}
                    name="visaNumber"
                    defaultValue={
                      applicationData.partE.visaNumber
                        ? applicationData.partE.visaNumber
                        : "N/A"
                    }
                    disable={true}
                  />
                </div>
              </div>
              <div className="confirm-header-tertiary-text mt-4">
                {`${t("NEWAPPLICATION.foreignNational.workPermitHeader")}`}
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.foreignNational.workPermitNumber.label"
                    )}`}
                    name="workPermitNumber"
                    defaultValue={
                      applicationData.partE.workPermitNumber
                        ? applicationData.partE.workPermitNumber
                        : "N/A"
                    }
                    disable={true}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                  <InputField
                    type="text"
                    label={`${t(
                      "NEWAPPLICATION.foreignNational.validityWorkPermit.label"
                    )}`}
                    name="workPermitExpiryDate"
                    defaultValue={
                      applicationData.partE.workPermitExpiryDate
                        ? applicationData.partE.workPermitExpiryDate
                        : "N/A"
                    }
                    disable={true}
                  />
                </div>
              </div>
            </>
          )}

          <div className="margin-primary-header">
            <div className="applicant-primary-header">
              {t("NEWAPPLICATION.confirmApplication.header.partF.part")}{" "}
              {applicationData.partA.passType === "ASP" ||
              applicationData.partA.passType === "CMAP" ? (
                <>{t("NEWAPPLICATION.confirmApplication.header.partF.F")}</>
              ) : (
                <>{t("NEWAPPLICATION.confirmApplication.header.partF.D")}</>
              )}
              : {t("NEWAPPLICATION.confirmApplication.header.partF.text")}
            </div>
          </div>

          <div className="mt-3">
            <Attachments mode="read" />
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mb-2">
                <h5 className="group-list-text-header mt-4">
                  {t("NEWAPPLICATION.applicantsDeclaration.headerText.text")}{" "}
                  {applicationData.partA.passType}:
                </h5>
                <ul className="p-0 ps-3 mt-4">
                  <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                    {t("NEWAPPLICATION.applicantsDeclaration.textList.item1")}
                  </li>
                  <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                    {t(
                      "NEWAPPLICATION.applicantsDeclaration.textList.item2.text"
                    )}{" "}
                  </li>
                  <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                    {t("NEWAPPLICATION.applicantsDeclaration.textList.item3")}
                  </li>
                  <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                    {t("NEWAPPLICATION.applicantsDeclaration.textList.item4")}
                  </li>
                  <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                    {t("NEWAPPLICATION.applicantsDeclaration.textList.item5")}
                  </li>
                  <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                    {t("NEWAPPLICATION.applicantsDeclaration.textList.item6")}
                  </li>
                  <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                    {t("NEWAPPLICATION.applicantsDeclaration.textList.item7")}
                  </li>
                </ul>
              </div>
            </div>

            {/* ======== Self Declaration ======== */}
            <div className={`col-12 mt-5 p-4 self__declaration_green`}>
              <div>
                <h5 className="group-list-text-header">
                  Self Declaration<span className="text-danger">*</span>
                </h5>
                <ul className="p-0 ps-3 mt-4">
                  <li className="mb-3">
                    Have you ever been involved in any unlawful activities in
                    any airport in Bangladesh?
                    <div className="row mt-1">
                      <div className="col-4">
                        <RadioLabel
                          label={`Yes`}
                          id="unlawful"
                          name="unlawful"
                          value="yes"
                          disable
                        />
                      </div>
                      <div className="col-4">
                        <RadioLabel
                          label={`No`}
                          id="unlawful"
                          name="unlawful"
                          value="no"
                          defaultValue="no"
                          disable
                        />
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    Have you ever violated any airport, immigration and customs
                    rules in any airport in Bangladesh?
                    <div className="row mt-1">
                      <div className="col-4">
                        <RadioLabel
                          label={`Yes`}
                          id="violated"
                          name="violated"
                          value="yes"
                          disable
                        />
                      </div>
                      <div className="col-4">
                        <RadioLabel
                          label={`No`}
                          id="violated"
                          name="violated"
                          value="no"
                          defaultValue="no"
                          disable
                        />
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    Have you ever been blacklisted by Civil Aviation Authority
                    of Bangladesh for any misconduct in any airport in
                    Bangladesh?
                    <div className="row mt-1">
                      <div className="col-4">
                        <RadioLabel
                          label={`Yes`}
                          id="blacklisted"
                          name="blacklisted"
                          value="yes"
                          disable
                        />
                      </div>
                      <div className="col-4">
                        <RadioLabel
                          label={`No`}
                          id="blacklisted"
                          name="blacklisted"
                          value="no"
                          defaultValue="no"
                          disable
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {applicationData.partD.signature && (
              <div className="col-12 my-4">
                <div className="signature-image-styling">
                  <img
                    src={applicationData.partD.signature}
                    alt="signature-user"
                    className="signature-image-display"
                  />
                </div>
                <p className="p-0 m-0 authorized-signatory-text mt-3">
                  {t("NEWAPPLICATION.ApplicantDetail.date")}:{" "}
                  <span className="authorized-signatory-text-bold">
                    {applicationData.partA.createdAt}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicantDetail;
