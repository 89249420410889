import React, { useContext, useState, useEffect } from "react";
import useHttp from "../Http/useHttp";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { settingsData } from "../redux/actions";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthContextProvider({ children }) {
  //   const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const api = useHttp();
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("Auth context");
    const token = localStorage.getItem("token");
    token ? setCurrentUser(true) : setCurrentUser(false);
    const savedUser = JSON.parse(localStorage.getItem("user"));
    if (savedUser) dispatch(settingsData(savedUser));
    // console.log(savedUser);
  }, [dispatch]);

  //   const registration = () => {};

  const login = async (email, password) => {
    try {
      const resp = await api.post("login", {
        email,
        password,
      });
      // console.log(resp.data);

      const settingResp = await api.get("user", {
        headers: { Authorization: `Bearer ${resp.data.token}` },
      });
      // console.log(settingResp.data);

      localStorage.setItem("user", JSON.stringify(settingResp.data.data));
      localStorage.setItem(
        "token",
        Buffer.from(resp.data.token).toString("base64")
      );
      dispatch(settingsData(settingResp.data.data));
      setCurrentUser(true);
      return resp.status;
    } catch (error) {
      // localStorage.clear();
      if (error.response) return error.response.status;
    }
  };

  const logout = () => {
    localStorage.clear();
    setCurrentUser(false);
    // navigate("/");
  };

  const value = {
    currentUser,
    login,
    logout,
    // registration,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      {/* {!loading && children} */}
    </AuthContext.Provider>
  );
}
