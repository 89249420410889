import React, { useState, useEffect, useCallback, useMemo } from "react";
import Logo from "../../../../assets/images/defaultUser.jpg";
import Table from "react-bootstrap/Table";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetApplicationState,
  resetApplicationData,
  applicationDataPartA,
  applicationDataPartB,
  applicationDataPartC,
  applicationDataPartD,
  applicationDataPartE,
  applicationDataPartF,
  applicationDataAttachment,
  changeNextState,
  setDraftData,
  setDraftCount,
  setUnreadNotificationCount,
} from "../../../../redux/actions";
import { toast } from "react-toastify";
import useHttp from "../../../../Http/useHttp";
import { useAuth } from "../../../../Authentication/AuthContext";

//progress bar
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faArrowCircleRight,
  faTrash,
  faEye,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

// custom style
import "./home.css";

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const api = useHttp("authorized");
  const { logout } = useAuth();

  const [percentage, setPercentage] = useState(0);
  const [gsatPercentage, setGsatPercentage] = useState(0);
  const [allApplicationList, setAllApplicationList] = useState([]);
  const [myDraft, setMyDraft] = useState({});

  const myApplication = useSelector((state) => state.settingsData);
  const myApplicationData = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const profileCompletion = useMemo(
    () => [
      myApplication.name,
      myApplication.designation,
      myApplication.fathersName,
      myApplication.mothersName,
      myApplication.spouseName,
      myApplication.nationality,
      myApplication.dob,
      myApplication.gender,
      myApplication.nid,
      myApplication.mobile,
      myApplication.bloodGroup,
      myApplication.email,
      myApplication.profilePic,
      myApplication.policeVerificationValidTill,
      myApplication.gsatValidTill,
      myApplication.address.presentAddress.house,
      myApplication.address.presentAddress.street,
      myApplication.address.presentAddress.city,
      myApplication.address.presentAddress.ps,
      myApplication.address.presentAddress.dist,
      myApplication.address.presentAddress.zip,
      myApplication.address.permanentAddress.house,
      myApplication.address.permanentAddress.street,
      myApplication.address.permanentAddress.city,
      myApplication.address.permanentAddress.ps,
      myApplication.address.permanentAddress.dist,
      myApplication.address.permanentAddress.zip,
      myApplication.signature,
    ],
    [myApplication]
  );

  const profileCompletionCalculator = useCallback(() => {
    let counter = 0;
    profileCompletion.forEach((val) => {
      if (val) counter++;
    });
    return counter;
  }, [profileCompletion]);

  const getRemainingDays = (d1, d2) => {
    d2 = d2 || new Date();
    // console.log(d1);
    // console.log(d2);
    var diff = d2.getTime() - d1.getTime();
    // console.log(diff);
    return diff > 0
      ? 0
      : Math.floor(Math.abs(diff) / (1000 * 60 * 60 * 24)) + 1;
  };

  const getDraftAndApplicationList = useCallback(async () => {
    let temDraftList = [];
    let temApplicationList = [];
    try {
      //==================== Fetching Draft List ====================
      const resp = await api.get("draft");
      // console.log(resp.data.draft);

      if (resp.data.draft.length > 0) {
        setMyDraft({
          ...myApplicationData,
          partA: { ...myApplicationData.partA, ...resp.data.draft[0].part_a },
          partB: { ...myApplicationData.partB, ...resp.data.draft[0].part_b },
          partC: { ...myApplicationData.partC, ...resp.data.draft[0].part_c },
          partD: { ...myApplicationData.partD, ...resp.data.draft[0].part_d },
          partE: { ...myApplicationData.partE, ...resp.data.draft[0].part_e },
          partF: { ...myApplicationData.partF, ...resp.data.draft[0].part_f },
          attachments: {
            ...myApplicationData.attachments,
            ...resp.data.draft[0].attachments,
          },
        });

        temDraftList = [
          {
            cardType: resp.data.draft[0].part_a.passType,
            applicationNo: "N/A",
            applicationDate: resp.data.draft[0].created_at,
            status: "Draft",
            validTill: "N/A",
            applicationId: resp.data.draft[0].id,
          },
        ];
        dispatch(setDraftCount(1));
        // console.log(temDraftList);
      } else dispatch(setDraftCount(0));

      //==================== Fetching Application List ====================
      const applicationResp = await api.get("application");
      // console.log(applicationResp.data.applications);

      if (applicationResp.data.applications.length > 0) {
        temApplicationList = applicationResp.data.applications.map((app) => ({
          cardType: app.card.name,
          applicationNo: app.application_number
            ? app.application_number
            : "N/A",
          applicationDate: app.created_at,
          applicationId: app.id,
          status: app.status,
          validTill: app.expiry_date ? app.expiry_date : "N/A",
        }));
        // console.log(temApplicationList);
      }

      // console.log([...temDraftList, ...temApplicationList]);
      setAllApplicationList([...temDraftList, ...temApplicationList]);
    } catch (error) {
      // console.log(error);
      if (error.response) {
        if (error.response.data.status === 401) logout();
        else {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Unknown Error", {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [api, logout]);

  const getUnreadNotificationCount = useCallback(async () => {
    try {
      const resp = await api.get("user/notification/unread-count");
      // console.log(resp.data);
      dispatch(setUnreadNotificationCount(resp.data.response));
    } catch (error) {
      // console.log(error);
      if (error.response) {
        if (error.response.data.status === 401) logout();
        else {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Unknown Error", {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [api, logout]);

  useEffect(() => {
    const counter = profileCompletionCalculator();
    if (myApplication.gsatValidTill)
      setGsatPercentage(
        // getRemainingDays(new Date("2022-01-07"))
        getRemainingDays(new Date(myApplication.gsatValidTill))
      );
    // console.log(getRemainingDays(new Date(myApplication.gsatValidTill)));
    const timeout = setTimeout(
      () => setPercentage(Math.round((counter / 28) * 100)),
      500
    );
    getDraftAndApplicationList();
    getUnreadNotificationCount();
    return () => {
      clearTimeout(timeout);
      getDraftAndApplicationList();
      setGsatPercentage();
    };
  }, [profileCompletionCalculator, myApplication]);

  const sortingIcon = (
    <div className="table-header-icon-container">
      <FontAwesomeIcon icon={faChevronUp} className="table-header-icon" />
      <FontAwesomeIcon icon={faChevronDown} className="table-header-icon" />
    </div>
  );

  const pathColor = (data) => {
    switch (data) {
      case "profile":
        if (percentage < 33) return "#F01E1F";
        if (percentage < 66) return "#FFA200";
        if (percentage <= 100) return "#3DC31C";
        break;
      default:
        if (gsatPercentage < 122) return "#F01E1F";
        if (gsatPercentage < 244) return "#FFA200";
        if (gsatPercentage <= 365) return "#3DC31C";
    }
  };

  const deleteDraft = (id) => {
    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        const resp = await api.get(`draft/delete/${id}`);
        // console.log(resp.data);
        getDraftAndApplicationList();
        resolve();
      } catch (error) {
        // console.log(error.response);
        if (error.response.data.status === 401) {
          reject("Not Authorized Please Login");
          logout();
        } else reject(error.response.data.message);
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Draft Deleted",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const getAllSites = async () => {
    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        const resp = await api.get(`organization/${myDraft.partA.orgCode}`);

        dispatch(
          applicationDataPartA({
            availableSites: resp.data.sites,
          })
        );
        resolve();
      } catch (error) {
        // console.log(error.response);
        if (error.response) {
          if (error.response.data.status === 401) {
            reject("Not Authorized Please Login");
            logout();
          } else reject("Unable To Open Draft!");
        } else reject("Unknown Error!");
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Your Draft Is Ready",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const openDraft = async () => {
    await getAllSites();
    // console.log(myDraft);
    dispatch(resetApplicationState());
    dispatch(resetApplicationData());
    dispatch(setDraftData(myDraft));

    if (myDraft.partA.completed === "A") dispatch(changeNextState(3));
    if (myDraft.partA.completed === "B") dispatch(changeNextState(5));
    if (myDraft.partA.completed === "C") {
      myDraft.partA.passType === "ASP" || myDraft.partA.passType === "CMAP"
        ? dispatch(changeNextState(6))
        : dispatch(changeNextState(7));
    }

    navigate("/dashboard/newApplication");
    // dispatch(setDraftData(testDraft));
  };

  const resolveCMAPCategories = (category) => {
    switch (category) {
      case "P-SPO":
        return `Pilot (Schedule Passenger Operation)`;
      case "P-H":
        return `Pilot (Helicopter)`;
      case "P-FC":
        return `Pilot (Flight Cargo)`;
      case "CC-SPO":
        return `Cabin Crew (Schedule Passenger Operation)`;
      case "FSI":
        return `Flying School Instructor (FSI)`;
      case "FST":
        return `Flying School Trainee (FST)`;

      default:
        return "";
    }
  };

  const resolveNIACCategories = (category) => {
    switch (category) {
      case "Security_AVSEC":
        return `National Aviation Security (AVSEC) Inspector`;
      case "Safety_ANS":
        return `National Aviation Safety (ANS) Inspector`;
      case "Safety_OPS":
        return `National Aviation Safety (OPS) Inspector`;
      case "Safety_AGA":
        return `National Aviation Safety (AGA) Inspector`;
      case "Safety_OPS_PEL":
        return `National Aviation Safety (OPS/PEL) Inspector`;
      case "Safety_AIR":
        return `National Aviation Safety (AIR) Inspector`;

      default:
        return "";
    }
  };

  const resolveCompanyCategories = (category) => {
    switch (category) {
      case "GOVT":
        return `Government (GOVT)`;
      case "APBE":
        return `Airport Business Entity (APBE)`;
      case "ATBE":
        return `Air Transport Business Entity (ATBE)`;
      case "EHCI":
        return `Embassies and High Commission and International Organization (EHCI)`;
      case "COTN":
        return `Contractor (COTN)`;
      case "PRVT":
        return `Private (PRVT)`;

      default:
        return "";
    }
  };

  const parseURI = async (d) => {
    var reader = new FileReader();
    reader.readAsDataURL(d);
    return new Promise((res, rej) => {
      reader.onload = (e) => {
        // console.log(e.target.result);
        res(e.target.result);
      };
    });
  };

  const getDataBlob = async (url) => {
    // var res =
    await fetch(`https://lit-lake-89687.herokuapp.com/${url}`)
      .then(async (val) => {
        var blob = await val.blob();
        var uri = await parseURI(blob);
        // console.log(uri);
        return uri;
      })
      .catch((err) => {
        return err;
      });

    // var res = await fetch(`https://nocors.jhorotek.com/${url}`);
    // var res = await fetch(`http://165.22.96.93:8080/${url}`);
    // console.log(res);

    // var blob = await res.blob();
    // var uri = await parseURI(blob);
    // // console.log(uri);
    // return uri;
  };

  const downloadApplication = async (id) => {
    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        const resp = await api.get(`application/${id}`);
        // console.log(resp.data.application);

        let applicationData = {
          part_a: {
            completed: "A",
            passType: "",
            passTypeId: "",
            issueType: "",
            existingPassNumber: "",
            colorOfPass: "",
            A: false,
            B: false,
            C: false,
            D: false,
            E: false,
            T: false,
            R: false,
            two: false,
            three: false,
            four: false,
            five: false,
            six: false,
            gdNumber: "",
            gdEntry: "",
            psName: "",
            draftId: null,
            orgCode: "",
            orgId: "",
            availableCards: [],
            availableSites: [],
            applicationId: "",
            createdAt: "",
            siteId: "",
            existingCMAPcategory: "",
          },
          part_b: {
            name: "",
            displayName: "",
            fathersName: "",
            mothersName: "",
            spouseName: "",
            nationality: "",
            dob: "",
            gender: "",
            nid: "",
            mobile: "",
            bloodGroup: "",
            email: "",
            profilePic: "",
            policeVerificationFile: "",
            policeVerificationFileName: "",
            policeVerificationValidTill: "",
            gsatFile: "",
            gsatFileName: "",
            gsatValidTill: "",
            address: {
              presentAddress: {
                house: "",
                street: "",
                city: "",
                ps: "",
                dist: "",
                zip: "",
              },
              permanentAddress: {
                house: "",
                street: "",
                city: "",
                ps: "",
                dist: "",
                zip: "",
              },
            },
          },
          part_c: {
            divisionName: "",
            designation: "",
            currentPosition: "",
            dateOfJoining: "",
            typeOfEmployment: "",
            typeOfEmploymentOther: "",
            typeOfAuthorization: "",
            typeOfAuthorizationSafety: "",
            typeOfAuthorizationSafetyFile: "",
            typeOfAuthorizationSafetyFileName: "",
            natureOfDuty: "",
            organizationName: "",
            natureOfOrganization: "",
            scopeOfOperation: "",
            natureOfDutyDescription: "",
            natureOfDutyDescriptionFile: "",
            natureOfDutyDescriptionFileName: "",
            locationOfDuty: "",
          },
          part_d: {
            signature: "",
          },
          part_e: {
            foreignNationality: "",
            natureOfAssignmentBD: "",
            passportDocumentNumber: "",
            placeOfIssue: "",
            passportDocumentExpiryDate: "",
            typeOfVisa: "",
            visaExpiryDate: "",
            visaNumber: "",
            workPermitNumber: "",
            workPermitExpiryDate: "",
          },
          part_f: {
            requiredColorOfCard: "",
            enhancedSRA: false,
            enhancedSRAspecification: "",
            internationalBaggage: false,
            airCargo: false,
            passengerHolding: false,
            betweenImmigrationPreBoarding: false,
            internationalImmigration: false,
            internationalCustoms: false,
            terminalEntry: false,
            ATCoperation: false,
            authorityToEscort: false,
            authorityToDrive: false,
            authorityToCarry: false,
            policeVerificationExempted: false,
            typeOfPass: "",
            validity: "",
            category: "",
            dasSignature: "",
            dasName: "",
            dasDesignation: "",
            dasVerificationDate: "",
            adminSignature: "",
            replaceAnyone: "",
          },
          attachments: {
            forwardingLetter: "",
            copyOfNID: "",
            employeeId: "",
            employeeLicense: "",
            officeOrder: "",
            drivingLicense: "",
            existingPermit: "",
            backgroundCheck: "",
            validAOC: "",
            copyOfWorkOrder: "",
            copyOfAgreement: "",
            copyOfPassport: "",
            copyOfVisa: "",
            recommendationMOFA: "",
            noteVerbale: "",
            recommendationOfMinistry: "",
            recommendationBIDA: "",
            securityClearanceMOHA: "",
            certificateFSR: "",
            selectedAttachmentGroupASP: "",
          },
        };

        let convertedCmapCategory = "";
        let convertedNiacCategory = "";
        let convertedCompCategory = "";

        applicationData = { ...resp.data.application };

        if (applicationData.part_a.existingCMAPcategory) {
          convertedCmapCategory = resolveCMAPCategories(
            applicationData.part_a.existingCMAPcategory
          );
          // applicationData = {
          //   ...resp.data.application,
          //   part_a: {
          //     ...resp.data.application.part_a,
          //     existingCMAPcategory: convertedCategory,
          //   },
          // };
        }

        if (applicationData.part_c.typeOfAuthorization) {
          convertedNiacCategory = resolveNIACCategories(
            applicationData.part_c.typeOfAuthorization
          );
          // applicationData = {
          //   ...resp.data.application,
          //   part_c: {
          //     ...resp.data.application.part_c,
          //     typeOfAuthorization: convertedCategory,
          //   },
          // };
        }

        if (applicationData.part_c.natureOfOrganization) {
          convertedCompCategory = resolveCompanyCategories(
            applicationData.part_c.natureOfOrganization
          );
          // applicationData = {
          //   ...resp.data.application,
          //   part_c: {
          //     ...resp.data.application.part_c,
          //     natureOfOrganization: convertedCategory,
          //   },
          // };
        }

        applicationData = {
          ...resp.data.application,
          part_a: {
            ...resp.data.application.part_a,
            existingCMAPcategory: convertedCmapCategory,
          },
          part_c: {
            ...resp.data.application.part_c,
            natureOfOrganization: convertedCompCategory,
            typeOfAuthorization: convertedNiacCategory,
          },
        };

        const profilePic = await getDataBlob(
          resp.data.application.part_b.profilePic
        );
        // console.log(profilePic);

        const signature = await getDataBlob(
          resp.data.application.part_d.signature
        );
        // console.log(signature);

        // console.log(applicationData);

        navigate(`application/download/${id}`, {
          state: {
            data: applicationData,
            profilePic,
            signature,
          },
        });
        resolve();
      } catch (error) {
        // console.log(error.response);
        if (error.response.data.status === 401) {
          reject("Not Authorized Please Login");
          logout();
        } else reject(error.response.data.message);
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Your Application",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const openApplication = async (id) => {
    // console.log(id);

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        const resp = await api.get(`application/${id}`);
        // console.log(resp.data);

        dispatch(resetApplicationData());
        dispatch(
          applicationDataPartA({
            ...resp.data.application.part_a,
            applicationId: id,
            createdAt: resp.data.application.created_at,
          })
        );
        dispatch(applicationDataPartB({ ...resp.data.application.part_b }));
        dispatch(applicationDataPartC({ ...resp.data.application.part_c }));
        dispatch(applicationDataPartD({ ...resp.data.application.part_d }));
        dispatch(applicationDataPartE({ ...resp.data.application.part_e }));
        dispatch(applicationDataPartF({ ...resp.data.application.part_f }));
        dispatch(
          applicationDataAttachment({ ...resp.data.application.attachments })
        );

        navigate(`application/${id}`);

        resolve();
      } catch (error) {
        // console.log(error.response);
        if (error.response.data.status === 401) {
          reject("Not Authorized Please Login");
          logout();
        } else reject(error.response.data.message);
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Your Application",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  return (
    <>
      <div className="profile">
        <div className="user-profile">
          <div className="profile-pic">
            <img
              src={myApplication.profilePic ? myApplication.profilePic : Logo}
              alt="Profile Pic"
            />
          </div>
          <div className="profile-description">
            <div className="profile-welcome">{t("HOME.user.header")}</div>
            <div className="profile-name">{myApplication.name}</div>
            {/* <div
              className={`profile-designation ${
                myApplication.designation
                  ? null
                  : "profile-designation__warning"
              }`}
            >
              {myApplication.designation
                ? myApplication.designation
                : "Configure Designation From Setting"}
            </div> */}
          </div>
        </div>
        <div className="user-profile-complete">
          <div className="progress-circle">
            <CircularProgressbar
              value={percentage}
              styles={buildStyles({
                pathColor: pathColor("profile"),
                trailColor: "#E9F4FF",
              })}
            />
            <div className="percentage">
              {percentage}% <span>Completed</span>
            </div>
          </div>
          <div className="profile-name">{t("HOME.profile.header")}</div>
          {percentage < 100 && (
            <div className="profile-designation">
              {t("HOME.profile.subHeader")}
            </div>
          )}
        </div>

        <div className="user-gsat">
          <div className="progress-circle">
            <CircularProgressbar
              maxValue={365}
              value={gsatPercentage}
              styles={buildStyles({
                pathColor: pathColor(),
                trailColor: "#E9F4FF",
              })}
            />
            <div className="percentage">
              {gsatPercentage}
              <div className="days">{t("HOME.duration.day")}</div>{" "}
              <div className="remaining">{t("HOME.duration.remaining")}</div>
            </div>
          </div>

          <div className="profile-gsat">{t("HOME.duration.header")}</div>
          <div className="profile-designation">
            {/* Issue Date: 02-05-2021 <br /> */}
            {t("HOME.duration.expireOn")}:{" "}
            {myApplication.gsatValidTill
              ? myApplication.gsatValidTill
              : "Not Available"}
          </div>
        </div>
      </div>

      <div className="history">
        <div className="table-subheader">
          {t("HOME.historyTable.subHeader")}
        </div>
        <div className="table-header">{t("HOME.historyTable.header")}</div>
        <Table hover className="overview-table">
          <thead>
            <tr className="table-row-header">
              <th>
                <div className="table-header-title">
                  {`Tracking ID`} {sortingIcon}
                </div>
              </th>
              <th>
                <div className="table-header-title">
                  {t("HOME.tableHeader.cardType")} {sortingIcon}
                </div>
              </th>
              <th>
                <div className="table-header-title">
                  {t("HOME.tableHeader.applicationNumber")} {sortingIcon}
                </div>
              </th>
              <th>
                <div className="table-header-title">
                  {t("HOME.tableHeader.applicationDate")} {sortingIcon}
                </div>
              </th>
              <th>
                <div className="table-header-title">
                  {t("HOME.tableHeader.status")} {sortingIcon}
                </div>
              </th>
              <th>
                <div className="table-header-title">
                  {t("HOME.tableHeader.validTill")} {sortingIcon}
                </div>
              </th>
              <th>
                <div className="table-header-title">
                  {t("HOME.tableHeader.action")} {sortingIcon}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="border">
            {allApplicationList.length > 0 &&
              allApplicationList.map((application, index) => (
                <tr key={index}>
                  <td>{application.applicationId}</td>
                  <td>{application.cardType}</td>
                  <td>{application.applicationNo}</td>
                  <td>{application.applicationDate}</td>
                  <td
                    className={application.status === "Draft" ? "draft" : null}
                  >
                    {application.status}
                  </td>
                  {/* draft/pending/accepted/rejected */}
                  <td>{application.validTill}</td>
                  <td>
                    {application.status === "Draft" ? (
                      <>
                        <FontAwesomeIcon
                          icon={faArrowCircleRight}
                          className="table-icon me-2"
                          onClick={openDraft}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="table-icon-delete ms-2"
                          onClick={() => deleteDraft(application.applicationId)}
                        />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faEye}
                          className="table-icon me-2"
                          onClick={() =>
                            openApplication(application.applicationId)
                          }
                        />
                        <FontAwesomeIcon
                          icon={faDownload}
                          className="table-icon me-2"
                          onClick={() =>
                            downloadApplication(application.applicationId)
                          }
                        />
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Home;
