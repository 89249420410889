import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useHttp from "../../../../../../Http/useHttp";
import { useAuth } from "../../../../../../Authentication/AuthContext";
import { setUnreadNotificationCount } from "../../../../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

// custom Style
import "./NotificationCard.css";

const NotificationCard = ({
  activeIconCLass,
  header,
  bodyText,
  time,
  id,
  read,
}) => {
  const api = useHttp("authorized");
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const unreadNotification = useSelector(
    (state) => state.commonVariables.unreadNotificationCount
  );
  const [readStatus, setReadStatus] = useState(false);

  useEffect(() => {
    read ? setReadStatus(true) : setReadStatus(false);
  }, [read]);

  const readNotification = async () => {
    if (readStatus) return;
    try {
      const resp = await api.get(`user/notification/marked-as-read/${id}`);
      dispatch(setUnreadNotificationCount(unreadNotification - 1));
      setReadStatus(true);
      console.log(resp.data);
    } catch (error) {
      console.log(error.response);
      if (error.response.data.status === 401) logout();
      else {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      <div
        className={`card-container ${!readStatus && "notification-unread"}`}
        onClick={readNotification}
      >
        <div className="left-content">
          <div className={`${activeIconCLass}`}>
            <FontAwesomeIcon icon={faFileAlt} className="icon-file" />
          </div>
          <div className="body-container">
            <div className="custom-header-text">{header}</div>
            <div className="custom-body-text ">{bodyText}</div>
          </div>
        </div>
        <div className="time-container">{time}</div>
      </div>
    </>
  );
};

export default NotificationCard;
