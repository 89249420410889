import React, { useState, useEffect } from "react";
import AvSecID from "../assets/images/avsecID.png";

import Button from "react-bootstrap/Button";
import StepWrapper from "./StepProgress/StepWrapper";

import RegistrationForm from "./RegistrationForm/RegistrationForm";
import OTPForm from "./OTPForm/OTPForm";
import { useSelector } from "react-redux";
import Background from "./Background/Background";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader/Loader";
import Popup from "./Popup/Popup";

// import i18n from "i18next";
import { useTranslation } from "react-i18next";

//custom css
import "./registration.css";

function Registration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupErr, setPopupErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [nextStepData, setNextStepData] = useState("");
  // const [currentLng, setCurrentLng] = useState("");

  const myCurrentStates = useSelector((state) => state.changeFormReducer);
  const { pageTitle } = myCurrentStates;
  const { pageSubTitle } = myCurrentStates;
  const { activeForm } = myCurrentStates;

  const handleLoading = (data) => setIsLoading(data);
  const handlePopUp = (data) => setIsPopupActive(data);
  const handlePopUpErr = (data) => setPopupErr(data);
  const handleNextStep = (data) => setNextStepData(data);

  // useEffect(() => {
  //   setCurrentLng(document.cookie);
  // }, []);

  const formBody = (
    <Background>
      {/* <input
        type="checkbox"
        id="registerSwitch"
        className="register-checkbox"
        // defaultChecked={currentLng === "i18next=bn-BD" ? false : true}
        checked={currentLng === "i18next=bn-BD" ? false : true}
        onChange={(ev) => {
          // console.log(ev.target.checked);
          // console.log(currentLng);

          // ev.target.checked
          if (currentLng === "i18next=en-US") {
            i18n.changeLanguage("bn-BD");
            setCurrentLng("i18next=bn-BD");
          }
          if (currentLng === "i18next=en-GB") {
            i18n.changeLanguage("bn-BD");
            setCurrentLng("i18next=bn-BD");
          }
          if (currentLng === "i18next=bn-BD") {
            i18n.changeLanguage("en-US");
            setCurrentLng("i18next=en-US");
          }
        }}
      /> */}
      {/* <label htmlFor="registerSwitch" className="register-toggle">
        <p className="register-textLanguage">বাং EN</p>
      </label> */}

      <div className="card-layout-current">
        {/* <div className="page-title">{pageTitle}</div> */}
        <img src={AvSecID} alt="AvSecID Logo" className="logo-avsecID" />
        <div className="page-subtitle">{pageSubTitle}</div>
        <div className="step-container">
          <StepWrapper activeForm={activeForm} />
        </div>

        {activeForm === "form1" ? (
          <RegistrationForm
            loading={handleLoading}
            popUp={handlePopUp}
            popUpErrorText={handlePopUpErr}
            nextStep={handleNextStep}
          />
        ) : null}
        {activeForm === "form2" ? (
          <OTPForm
            nextStep
            for="registration"
            loading={handleLoading}
            popUp={handlePopUp}
            popUpErrorText={handlePopUpErr}
            userId={nextStepData}
          />
        ) : null}
        {activeForm === "form4" ? (
          <>
            <p className="text-md fw-bold">
              {t("REGISTERSUCCESSPOPUP.successText")}
            </p>
            <Button
              className="col-12 fw-bold mt-3"
              variant="primary"
              type="button"
              onClick={() => navigate("/")}
            >
              {t("REGISTERSUCCESSPOPUP.loginBtn")}
            </Button>
          </>
        ) : null}
      </div>
    </Background>
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : isPopupActive ? (
        <>
          <Popup title={"Oh Snap!"} subTitle={popupErr} variety={"error"}>
            {formBody}
          </Popup>
        </>
      ) : (
        <>{formBody}</>
      )}
    </>
  );
}

export default Registration;
