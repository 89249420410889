import React, { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardNotify from "./CardNotify/CardNotify";
import { toast } from "react-toastify";
import useHttp from "../../../../Http/useHttp";
import { useAuth } from "../../../../Authentication/AuthContext";

import styles from "./notification.module.css";

function Notification() {
  const api = useHttp("authorized");
  const [notifications, setNotifications] = useState([]);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const colorContainer = [
    { accepted: "icon-bg-green", headerAccepted: "Application Accepted" },
    { rejected: "icon-bg-red", headerRejected: "Application Rejected" },
    { pending: "icon-bg-yellow", headerPending: "Application Pending" },
    { drafted: "icon-bg-gray", headerDrafted: "Application Drafted" },
  ];

  const headerDecider = (status) => {
    switch (status) {
      case "Accepted":
        return colorContainer[0].headerAccepted;
      case "Rejected":
        return colorContainer[1].headerRejected;
      case "Submitted":
        return colorContainer[2].headerPending;
      case "Drafted":
        return colorContainer[3].headerDrafted;

      default:
        return;
    }
  };

  const iconClassDecider = (status) => {
    switch (status) {
      case "Accepted":
        return colorContainer[0].accepted;
      case "Rejected":
        return colorContainer[1].rejected;
      case "Submitted":
        return colorContainer[2].pending;
      case "Drafted":
        return colorContainer[3].drafted;

      default:
        return;
    }
  };

  const decorateMessage = (msg) => {
    if (msg.length > 50) {
      const shortMsg = msg.substr(0, 50) + " . . .";
      return shortMsg;
    } else return msg;
  };

  const getNotification = useCallback(async () => {
    try {
      const resp = await api.get("user/notification/latest");
      console.log(resp.data.response);
      setNotifications(resp.data.response);
    } catch (error) {
      console.log(error.response);
      if (error.response.data.status === 401) logout();
      else {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [api, logout]);

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <>
      <div className={styles.notificationContainerInside}>
        {notifications.length > 0 &&
          notifications.map((notification, index) => (
            <CardNotify
              key={index}
              activeIconCLass={iconClassDecider(notification.type)}
              header={headerDecider(notification.type)}
              bodyText={decorateMessage(notification.data.message)}
              time={notification.created_at}
              read={notification.read_at}
              id={notification.id}
            />
            // <CardNotify
            //   key={index}
            //   activeIconCLass={
            //     notification.type === "Submitted" && colorContainer[2].pending
            //   }
            //   header={
            //     notification.type === "Submitted" &&
            //     colorContainer[2].headerPending
            //   }
            //   bodyText={notification.data.message}
            //   time={notification.created_at}
            //   read={notification.read_at}
            //   id={notification.id}
            // />
          ))}

        {/* <CardNotify
          activeIconCLass={colorContainer[0].accepted}
          header={colorContainer[0].headerAccepted}
          bodyText="contrary to popular belief is not simply random text."
          time="1 Day"
        />
        <CardNotify
          activeIconCLass={colorContainer[1].rejected}
          header={colorContainer[1].headerRejected}
          bodyText="contrary to popular belief is not simply random text."
          time="1 Day"
        />
        <CardNotify
          activeIconCLass={colorContainer[2].pending}
          header={colorContainer[2].headerPending}
          bodyText="contrary to popular belief is not simply random text."
          time="1 Day"
        />
        <CardNotify
          activeIconCLass={colorContainer[3].drafted}
          header={colorContainer[3].headerDrafted}
          bodyText="contrary to popular belief is not simply random text."
          time="1 Day"
        /> */}
      </div>

      {notifications.length > 0 && (
        <div
          className={styles.allNotifications}
          onClick={() => {
            navigate("notification");
          }}
        >
          View All
        </div>
      )}
    </>
  );
}

export default Notification;
