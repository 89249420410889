import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveApplicationPart,
  applicationDataPartE,
} from "../../../../../../redux/actions";

import FormTitle from "../../Utilities/FormTitle/FormTitle";
import StepperShift from "../../Utilities/StepperShift/StepperShift";
import Stepper from "../../Utilities/Stepper/Stepper";

import InputField from "../../../../../../Utilities/InputField/InputField";
import DateInput from "../../../../../../Utilities/DateInput/DateInput";
import Selector from "../../../../../../Utilities/Selector/Selector";

import { useTranslation } from "react-i18next";

// custom style
import styles from "./ForeignNational.module.css";

const ForeignNational = () => {
  const [checkedValue, setCheckedValue] = useState(false);
  const [userInput, setUserInput] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scrollDown = useRef(null);

  const myCurrentStates = useSelector(
    (state) => state.newApplicationData.newApplication.partE
  );
  const passType = useSelector(
    (state) => state.newApplicationData.newApplication.partA.passType
  );
  const nationality = useSelector(
    (state) => state.newApplicationData.newApplication.partB.nationality
  );

  const scrollToBottom = () => {
    scrollDown.current?.scrollIntoView({ behavior: "smooth" });
  };

  const aspValidator = useCallback(() => {
    myCurrentStates.foreignNationality &&
    myCurrentStates.natureOfAssignmentBD &&
    myCurrentStates.passportDocumentNumber &&
    myCurrentStates.placeOfIssue &&
    myCurrentStates.passportDocumentExpiryDate &&
    myCurrentStates.typeOfVisa &&
    myCurrentStates.visaExpiryDate &&
    myCurrentStates.visaNumber &&
    myCurrentStates.workPermitNumber &&
    myCurrentStates.workPermitExpiryDate
      ? setCheckedValue(true)
      : setCheckedValue(false);
  }, [myCurrentStates, setCheckedValue]);

  const cicValidator = useCallback(() => {
    myCurrentStates.foreignNationality &&
    myCurrentStates.passportDocumentNumber &&
    myCurrentStates.placeOfIssue &&
    myCurrentStates.passportDocumentExpiryDate &&
    myCurrentStates.typeOfVisa &&
    myCurrentStates.visaExpiryDate &&
    myCurrentStates.visaNumber &&
    myCurrentStates.workPermitNumber &&
    myCurrentStates.workPermitExpiryDate
      ? setCheckedValue(true)
      : setCheckedValue(false);
  }, [myCurrentStates, setCheckedValue]);

  useEffect(() => {
    if (nationality.toLowerCase().substr(0, 3) === "ban") {
      setUserInput(false);
      scrollToBottom();
    } else setUserInput(true);

    passType === "ASP" ? aspValidator() : cicValidator();
    dispatch(changeActiveApplicationPart("Part D"));
  }, [dispatch, passType, aspValidator, cicValidator, nationality]);

  const natureAssignment = [
    {
      label: `${t(
        "NEWAPPLICATION.foreignNational.natureAssignment.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.foreignNational.natureAssignment.option.diplomat"
      )}`,
      value:
        "Diplomat/Non-diplomat of Embassy/ High Commission / Foreign mission in Bangladesh",
    },
    {
      label: `${t(
        "NEWAPPLICATION.foreignNational.natureAssignment.option.partner"
      )}`,
      value: "International development / partner organization",
    },
    {
      label: `${t(
        "NEWAPPLICATION.foreignNational.natureAssignment.option.other"
      )}`,
      value: "Other organization",
    },
  ];

  const foreignNationality = (inputData) => {
    dispatch(
      applicationDataPartE({
        foreignNationality: inputData,
      })
    );
  };

  const natureOfAssignmentBD = (selectedData) => {
    dispatch(
      applicationDataPartE({
        natureOfAssignmentBD: selectedData,
      })
    );
  };

  const passportDocumentNumber = (inputData) => {
    dispatch(
      applicationDataPartE({
        passportDocumentNumber: inputData,
      })
    );
  };

  const placeOfIssue = (inputData) => {
    dispatch(
      applicationDataPartE({
        placeOfIssue: inputData,
      })
    );
  };

  const typeOfVisa = (inputData) => {
    dispatch(
      applicationDataPartE({
        typeOfVisa: inputData,
      })
    );
  };

  const visaNumber = (inputData) => {
    dispatch(
      applicationDataPartE({
        visaNumber: inputData,
      })
    );
  };

  const workPermitNumber = (inputData) => {
    dispatch(
      applicationDataPartE({
        workPermitNumber: inputData,
      })
    );
  };

  const passportDocumentExpiryDate = (selectedData) => {
    dispatch(
      applicationDataPartE({
        passportDocumentExpiryDate: selectedData,
      })
    );
  };

  const visaExpiryDate = (selectedData) => {
    dispatch(
      applicationDataPartE({
        visaExpiryDate: selectedData,
      })
    );
  };

  const workPermitExpiryDate = (selectedData) => {
    dispatch(
      applicationDataPartE({
        workPermitExpiryDate: selectedData,
      })
    );
  };

  return (
    <>
      <FormTitle
        header={`${t("NEWAPPLICATION.foreignNational.title")}`}
        subTitle={`${t("NEWAPPLICATION.foreignNational.subTitle")}`}
      />
      <Stepper />
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            defaultValue={
              myCurrentStates.foreignNationality
                ? myCurrentStates.foreignNationality
                : ""
            }
            execute={foreignNationality}
            type="text"
            label={`${t("NEWAPPLICATION.foreignNational.nationality.label")}`}
            name="nationality"
            placeholder={`${t(
              "NEWAPPLICATION.foreignNational.nationality.placeholder"
            )}`}
            disable={!userInput}
          />
        </div>
        {passType === "ASP" && (
          <div className="col-12 mt-3">
            <Selector
              label={`${t(
                "NEWAPPLICATION.foreignNational.natureAssignment.label"
              )}`}
              placeholder={`${t(
                "NEWAPPLICATION.foreignNational.natureAssignment.placeholder"
              )}`}
              listItem={natureAssignment}
              defaultValue={
                myCurrentStates.natureOfAssignmentBD
                  ? myCurrentStates.natureOfAssignmentBD
                  : ""
              }
              execute={natureOfAssignmentBD}
              disable={!userInput}
            />
          </div>
        )}
        <div className={`col-12 ${styles.marginHeader}`}>
          <h4 className={styles.headerForeign}>
            {`${t("NEWAPPLICATION.foreignNational.travelDocumentHeader")}`}
          </h4>
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={
              myCurrentStates.passportDocumentNumber
                ? myCurrentStates.passportDocumentNumber
                : ""
            }
            execute={passportDocumentNumber}
            type="text"
            label={`${t(
              "NEWAPPLICATION.foreignNational.passportNumber.label"
            )}`}
            name="number"
            placeholder={`${t(
              "NEWAPPLICATION.foreignNational.passportNumber.placeholder"
            )}`}
            disable={!userInput}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={
              myCurrentStates.placeOfIssue ? myCurrentStates.placeOfIssue : ""
            }
            execute={placeOfIssue}
            type="text"
            label={`${t("NEWAPPLICATION.foreignNational.placeIssue.label")}`}
            name="placeOfIssue"
            placeholder={`${t(
              "NEWAPPLICATION.foreignNational.placeIssue.placeholder"
            )}`}
            disable={!userInput}
          />
        </div>
        <div className="col-12 mt-3">
          <DateInput
            execute={passportDocumentExpiryDate}
            defaultValue={
              myCurrentStates.passportDocumentExpiryDate
                ? myCurrentStates.passportDocumentExpiryDate
                : ""
            }
            label={`${t("NEWAPPLICATION.foreignNational.expiryDate.label")}`}
            disable={!userInput}
          />
        </div>
        <div className={`col-12 ${styles.marginHeader}`}>
          <h4 className={styles.headerForeign}>
            {`${t("NEWAPPLICATION.foreignNational.visaHeader")}`}
          </h4>
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={
              myCurrentStates.typeOfVisa ? myCurrentStates.typeOfVisa : ""
            }
            execute={typeOfVisa}
            type="text"
            label={`${t("NEWAPPLICATION.foreignNational.typeVisa.label")}`}
            name="typeOfVisa"
            placeholder={`${t(
              "NEWAPPLICATION.foreignNational.typeVisa.placeholder"
            )}`}
            disable={!userInput}
          />
        </div>
        <div className="col-12 mt-3">
          <DateInput
            execute={visaExpiryDate}
            defaultValue={
              myCurrentStates.visaExpiryDate
                ? myCurrentStates.visaExpiryDate
                : ""
            }
            label={`${t("NEWAPPLICATION.foreignNational.validityVisa.label")}`}
            disable={!userInput}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={
              myCurrentStates.visaNumber ? myCurrentStates.visaNumber : ""
            }
            execute={visaNumber}
            type="text"
            label={`${t("NEWAPPLICATION.foreignNational.visaNumber.label")}`}
            name="visaNumber"
            placeholder={`${t(
              "NEWAPPLICATION.foreignNational.visaNumber.placeholder"
            )}`}
            disable={!userInput}
          />
        </div>
        <div className={`col-12 ${styles.marginHeader}`}>
          <h4 className={styles.headerForeign}>
            {`${t("NEWAPPLICATION.foreignNational.workPermitHeader")}`}
          </h4>
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={
              myCurrentStates.workPermitNumber
                ? myCurrentStates.workPermitNumber
                : ""
            }
            execute={workPermitNumber}
            type="text"
            label={`${t(
              "NEWAPPLICATION.foreignNational.workPermitNumber.label"
            )}`}
            name="number"
            disable={!userInput}
          />
        </div>
        <div className="col-12 mt-3">
          <DateInput
            execute={workPermitExpiryDate}
            defaultValue={
              myCurrentStates.workPermitExpiryDate
                ? myCurrentStates.workPermitExpiryDate
                : ""
            }
            label={`${t(
              "NEWAPPLICATION.foreignNational.validityWorkPermit.label"
            )}`}
            placeholder={`${t(
              "NEWAPPLICATION.foreignNational.validityWorkPermit.placeholder"
            )}`}
            disable={!userInput}
          />
        </div>
      </div>
      <div ref={scrollDown}>
        {checkedValue ? (
          <StepperShift showDraft />
        ) : (
          <StepperShift />
          // <StepperShift nextDisabled />
        )}
      </div>
    </>
  );
};

export default ForeignNational;
