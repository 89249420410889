import React, { useState } from "react";

//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// custom style
import styles from "./PasswordInput.module.css";

const PasswordInput = ({ label, name, placeholder, value, execute }) => {
  const [eyeClose, setEyeClose] = useState(true);

  const eye = <FontAwesomeIcon icon={faEye} style={{ color: "#0979F9" }} />;
  const eyeColored = (
    <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#0979F9" }} />
  );

  const handleChange = (e) => {
    execute(e.target.value);
  };

  return (
    <>
      <div className={styles.area}>
        <label className={`form-label ${styles.label}`}>{label}</label>
        <div className="input-group">
          <input
            type={!eyeClose ? "text" : "password"}
            className={`form-control ${styles.input}`}
            name={name}
            placeholder={placeholder}
            defaultValue={value}
            onChange={handleChange}
          />
          <span
            className="input-group-text"
            onClick={() => setEyeClose(!eyeClose)}
          >
            {eyeClose ? eyeColored : eye}
          </span>
        </div>
      </div>
    </>
  );
};

export default PasswordInput;
