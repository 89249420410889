import React, { useState, useEffect } from "react";
import useHttp from "../../../../../../Http/useHttp";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../../Authentication/AuthContext";
import InputField from "../../../../../../Utilities/InputField/InputField";
import PasswordInput from "../../../../../../Utilities/PasswordInput/PasswordInput";

import { useTranslation } from "react-i18next";

// custom style
import styles from "./PasswordPage.module.css";

const PasswordPage = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formFilled, setFormFilled] = useState(false);

  const { t } = useTranslation();
  const { logout } = useAuth();
  const api = useHttp("authorized");

  useEffect(() => {
    if (currentPassword && newPassword && confirmPassword) setFormFilled(true);
    else setFormFilled(false);
  }, [currentPassword, newPassword, confirmPassword]);

  const handleSubmit = async () => {
    // console.log(currentPassword);
    // console.log(newPassword);
    // console.log(confirmPassword);

    if (newPassword !== confirmPassword) {
      toast.info("Both Password Should Be Same", {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        // const resp =
        await api.post("settings/password-change", {
          current_password: currentPassword,
          new_password: newPassword,
          new_confirm_password: confirmPassword,
        });
        // localStorage.removeItem("token");
        // console.log(resp.data.response);
        // toast.success(resp.data.response, {
        //   autoClose: 1000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        // });

        // navigate("/");
        resolve();
        logout();
      } catch (error) {
        // console.log(error.response);
        if (error.response) {
          if (error.response.data.status === 401) {
            reject("Not Authorized Please Login");
            logout();
          } else {
            const keys = Object.keys(error.response.data);
            // console.log(keys);
            let errorDataLog = [];
            keys.map((key) =>
              error.response.data[key].map(
                (data) => (errorDataLog = [...errorDataLog, data])
              )
            );
            errorDataLog.map((text) => reject(text));
          }
        } else reject("Unknown Error");
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Password Reset Success. Please Log In",
        error: {
          render({ data }) {
            // console.log(data);
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-12 mt-3">
          <InputField
            type="password"
            label={`${t("SETTINGS.passwordForm.currentPassword.label")}`}
            name="currentPassword"
            placeholder={`${t(
              "SETTINGS.passwordForm.currentPassword.placeholder"
            )}`}
            execute={(data) => setCurrentPassword(data)}
          />
        </div>
        <div className="col-12 mt-3">
          <PasswordInput
            label={`${t("SETTINGS.passwordForm.newPassword.label")}`}
            name="newPassword"
            placeholder={`${t(
              "SETTINGS.passwordForm.newPassword.placeholder"
            )}`}
            value=""
            execute={(data) => setNewPassword(data)}
          />
        </div>
        <div className="col-12 mt-3">
          <PasswordInput
            label={`${t("SETTINGS.passwordForm.confirmPassword.label")}`}
            name="confirmPassword"
            placeholder={`${t(
              "SETTINGS.passwordForm.confirmPassword.placeholder"
            )}`}
            value=""
            execute={(data) => setConfirmPassword(data)}
          />
        </div>
        <div className="col-12 mt-5">
          <button
            type="submit"
            className={`btn ${styles.button} float-end`}
            onClick={() => handleSubmit()}
            disabled={!formFilled}
          >
            {t("SETTINGS.updateBtn")}
          </button>
        </div>
      </div>
    </>
  );
};

export default React.memo(PasswordPage);
