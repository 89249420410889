import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationDataPartC,
  changeActiveApplicationPart,
} from "../../../../../../redux/actions";

import FormTitle from "../../Utilities/FormTitle/FormTitle";
import Stepper from "../../Utilities/Stepper/Stepper";
import StepperShift from "../../Utilities/StepperShift/StepperShift";

import Selector from "../../../../../../Utilities/Selector/Selector";
import InputField from "../../../../../../Utilities/InputField/InputField";
import DateInput from "../../../../../../Utilities/DateInput/DateInput";
import TextInputArea from "../../../../../../Utilities/TextInputArea/TextInputArea";
// import PDFUpload from "../../../../../../Utilities/PDFUpload/PDFUpload";
// import FileUploader from "../../Utilities/FileUploader/FileUploader";

import { useTranslation } from "react-i18next";

// custom Style
// import styles from "./DetailsEmployment.module.css";

export default function DetailsEmployment() {
  const [checkedValue, setCheckedValue] = useState(false);
  const [isGovt, setIsGovt] = useState(false);
  const [isAirside, setIsAirside] = useState(false);
  const [isNatureOfDutyModified, setIsNatureOfDutyModified] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const myCurrentStates = useSelector(
    (state) => state.newApplicationData.newApplication.partC
  );

  const draftId = useSelector(
    (state) => state.newApplicationData.newApplication.partA.draftId
  );

  const passType = useSelector(
    (state) => state.newApplicationData.newApplication.partA.passType
  );

  useEffect(() => {
    // console.log(myCurrentStates);
    switch (passType) {
      case "NIAC":
        myCurrentStates.divisionName &&
        myCurrentStates.designation &&
        myCurrentStates.currentPosition &&
        myCurrentStates.dateOfJoining &&
        myCurrentStates.typeOfEmployment &&
        myCurrentStates.typeOfAuthorization
          ? setCheckedValue(true)
          : setCheckedValue(false);
        break;

      case "NAP":
        myCurrentStates.divisionName &&
        myCurrentStates.designation &&
        myCurrentStates.currentPosition &&
        myCurrentStates.dateOfJoining &&
        myCurrentStates.typeOfEmployment
          ? // myCurrentStates.natureOfDutyDescription
            setCheckedValue(true)
          : setCheckedValue(false);
        break;

      case "ASP":
        myCurrentStates.designation &&
        myCurrentStates.currentPosition &&
        myCurrentStates.typeOfEmployment &&
        myCurrentStates.organizationName &&
        myCurrentStates.natureOfOrganization &&
        myCurrentStates.scopeOfOperation &&
        myCurrentStates.locationOfDuty &&
        myCurrentStates.natureOfDuty
          ? setCheckedValue(true)
          : setCheckedValue(false);

        if (
          myCurrentStates.natureOfDuty === "Government / Diplomatic Protocol"
        ) {
          setIsGovt(true);
        }
        break;

      case "CMAP":
        myCurrentStates.designation &&
        myCurrentStates.currentPosition &&
        myCurrentStates.typeOfEmployment &&
        myCurrentStates.organizationName &&
        myCurrentStates.natureOfOrganization &&
        myCurrentStates.scopeOfOperation
          ? setCheckedValue(true)
          : setCheckedValue(false);
        break;

      case "AAIAP":
        myCurrentStates.divisionName &&
        myCurrentStates.designation &&
        myCurrentStates.currentPosition &&
        myCurrentStates.dateOfJoining &&
        myCurrentStates.investigatorCategory &&
        myCurrentStates.typeOfAssignment &&
        myCurrentStates.investigatorID &&
        myCurrentStates.typeOfEmployment
          ? // myCurrentStates.natureOfDutyDescription
            setCheckedValue(true)
          : setCheckedValue(false);
        break;

      default:
        setCheckedValue(false);
        break;
    }

    if (
      myCurrentStates.typeOfEmployment === "Other (Specify)" &&
      !myCurrentStates.typeOfEmploymentOther
    )
      setCheckedValue(false);

    if (myCurrentStates.natureOfDuty === "Government / Diplomatic Protocol")
      setIsGovt(true);

    if (
      myCurrentStates.locationOfDuty !==
      "Terminal (Airport / Air Transport Business)"
    )
      setIsAirside(true);

    // if (
    //   myCurrentStates.typeOfAuthorization ===
    //     "National Aviation Safety Inspector" &&
    //   !myCurrentStates.typeOfAuthorizationSafety
    // )
    //   setCheckedValue(false);

    // console.log(checkedValue);
    dispatch(changeActiveApplicationPart("Part C"));
  }, [myCurrentStates, checkedValue, passType, dispatch]);

  const natureOrganization = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.govt"
      )}`,
      value: "GOVT",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.apbe"
      )}`,
      value: "APBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.atbe"
      )}`,
      value: "ATBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.ehci"
      )}`,
      value: "EHCI",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.cotn"
      )}`,
      value: "COTN",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.prvt"
      )}`,
      value: "PRVT",
    },
  ];

  const employmentTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.deputation"
      )}`,
      value: "Deputation",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.permanent"
      )}`,
      value: "Permanent",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.contractual"
      )}`,
      value: "Contractual",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeEmployment.option.other"
      )}`,
      value: "Other (Specify)",
    },
  ];

  const authorizationTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeAuthorization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `National Aviation Security (AVSEC) Inspector`,
      value: "Security_AVSEC",
    },
    {
      label: `National Aviation Safety (ANS) Inspector`,
      value: "Safety_ANS",
    },
    {
      label: `National Aviation Safety (OPS) Inspector`,
      value: "Safety_OPS",
    },
    {
      label: `National Aviation Safety (AGA) Inspector`,
      value: "Safety_AGA",
    },
    {
      label: `National Aviation Safety (OPS/PEL) Inspector`,
      value: "Safety_OPS_PEL",
    },
    {
      label: `National Aviation Safety (AIR) Inspector`,
      value: "Safety_AIR",
    },
  ];

  const scopeOperation = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
      )}`,
      value: "",
    },
    // {
    //   label: `${t(
    //     "NEWAPPLICATION.detailsEmployment.scopeOperation.option.international"
    //   )}`,
    //   value: "International",
    // },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.scopeOperation.option.domestic"
      )}`,
      value: "Domestic",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.scopeOperation.option.both"
      )}`,
      value: "International and Domestic",
    },
  ];

  const natureDuty = [
    {
      label: `${t("NEWAPPLICATION.detailsEmployment.natureDuty.placeholder")}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureDuty.option.operational"
      )}`,
      value: "Operational",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureDuty.option.nonOperational"
      )}`,
      value: "Non-operational",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureDuty.option.diplomatic"
      )}`,
      value: "Government / Diplomatic Protocol",
    },
  ];

  const locationPrimary = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.option.airside"
      )}`,
      value: "Airside",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.option.terminalAirport"
      )}`,
      value: "Terminal (Airport / Air Transport Business)",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.option.terminalGovt"
      )}`,
      value: "Terminal (Govt / Diplomatic Protocol)",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.option.generalAviation"
      )}`,
      value: "General Aviation",
    },
  ];

  const typeOfAssignmentOptions = [
    {
      label: `Select Your Type of Assignment`,
      value: "",
    },
    {
      label: `Full Time`,
      value: "Full Time",
    },
    {
      label: `Short Term`,
      value: "Short Term",
    },
  ];

  // const textUploadApproval = `${t(
  //   "NEWAPPLICATION.detailsEmployment.approvedOfficeOrder.rightText"
  // )}`;

  // const textUploadChairmanAuthorization = `${t(
  //   "NEWAPPLICATION.detailsEmployment.copyChairmanAuthorization.rightText"
  // )}`;

  const divisionName = (inputData) => {
    dispatch(
      applicationDataPartC({
        divisionName: inputData,
      })
    );
  };

  const organizationName = (inputData) => {
    dispatch(
      applicationDataPartC({
        organizationName: inputData,
      })
    );
  };

  const natureOfDutyDescription = (inputData) => {
    dispatch(
      applicationDataPartC({
        natureOfDutyDescription: inputData,
      })
    );
  };

  const typeOfEmploymentOther = (inputData) => {
    dispatch(
      applicationDataPartC({
        typeOfEmploymentOther: inputData,
      })
    );
  };

  const typeOfAuthorizationSafety = (inputData) => {
    dispatch(
      applicationDataPartC({
        typeOfAuthorizationSafety: inputData,
      })
    );
  };

  const designation = (inputData) => {
    dispatch(
      applicationDataPartC({
        designation: inputData,
      })
    );
  };

  const currentPosition = (inputData) => {
    dispatch(
      applicationDataPartC({
        currentPosition: inputData,
      })
    );
  };

  const dateOfJoining = (inputData) => {
    dispatch(
      applicationDataPartC({
        dateOfJoining: inputData,
      })
    );
  };

  const typeOfEmployment = (selectedData) => {
    dispatch(
      applicationDataPartC({
        typeOfEmployment: selectedData,
      })
    );
  };

  const typeOfAuthorization = (selectedData) => {
    dispatch(
      applicationDataPartC({
        typeOfAuthorization: selectedData,
      })
    );
  };

  const natureOfOrganization = (selectedData) => {
    dispatch(
      applicationDataPartC({
        natureOfOrganization: selectedData,
      })
    );
  };

  const natureOfDuty = (selectedData) => {
    if (selectedData === "Government / Diplomatic Protocol") {
      setIsNatureOfDutyModified(true);
      setIsGovt(true);
      setIsAirside(true);
      dispatch(
        applicationDataPartC({
          locationOfDuty: "Terminal (Govt / Diplomatic Protocol)",
          scopeOfOperation: "International and Domestic",
        })
      );
    } else {
      setIsNatureOfDutyModified(true);
      setIsGovt(false);
      setIsAirside(false);
      dispatch(
        applicationDataPartC({
          locationOfDuty: "",
          scopeOfOperation: "",
        })
      );
    }

    dispatch(
      applicationDataPartC({
        natureOfDuty: selectedData,
      })
    );
  };

  const locationOfDuty = (selectedData) => {
    setIsNatureOfDutyModified(false);
    if (selectedData === "Terminal (Airport / Air Transport Business)") {
      setIsAirside(false);
      dispatch(
        applicationDataPartC({
          scopeOfOperation: "",
        })
      );
    } else {
      setIsAirside(true);
      dispatch(
        applicationDataPartC({
          scopeOfOperation: "International and Domestic",
        })
      );
    }

    dispatch(
      applicationDataPartC({
        locationOfDuty: selectedData,
      })
    );
  };

  const scopeOfOperation = (selectedData) => {
    setIsNatureOfDutyModified(false);
    dispatch(
      applicationDataPartC({
        scopeOfOperation: selectedData,
      })
    );
  };

  const typeOfAssignment = (selectedData) => {
    dispatch(
      applicationDataPartC({
        typeOfAssignment: selectedData,
      })
    );
  };

  const investigatorCategory = (selectedData) => {
    dispatch(
      applicationDataPartC({
        investigatorCategory: selectedData,
      })
    );
  };

  const investigatorID = (selectedData) => {
    dispatch(
      applicationDataPartC({
        investigatorID: selectedData,
      })
    );
  };

  // const natureOfDutyDescriptionFile = (url) => {
  //   // console.log(url);
  //   if (url) {
  //     dispatch(
  //       applicationDataPartC({
  //         natureOfDutyDescriptionFile: url,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       applicationDataPartC({
  //         natureOfDutyDescriptionFile: "",
  //       })
  //     );
  //   }
  // };

  // const typeOfAuthorizationSafetyFile = (url) => {
  //   // console.log(url);
  //   if (url) {
  //     dispatch(
  //       applicationDataPartC({
  //         typeOfAuthorizationSafetyFile: url,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       applicationDataPartC({
  //         typeOfAuthorizationSafetyFile: "",
  //       })
  //     );
  //   }
  // };

  const inputFieldSpecifyEmployment = (
    <div className="col-12 mt-3">
      <TextInputArea
        label={`${t(
          "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
        )}`}
        name="specifyEmployment"
        defaultValue={
          myCurrentStates.typeOfEmploymentOther
            ? myCurrentStates.typeOfEmploymentOther
            : ""
        }
        execute={typeOfEmploymentOther}
      />
    </div>
  );

  const inputFieldSafetyInspector = (
    <>
      <div className="col-12 mt-3">
        <TextInputArea
          label="Specify Authorization"
          name="specifySafetyInspector"
          defaultValue={
            myCurrentStates.typeOfAuthorizationSafety
              ? myCurrentStates.typeOfAuthorizationSafety
              : ""
          }
          execute={typeOfAuthorizationSafety}
        />
      </div>
      {/* <div className="col-12 mt-3">
        <div className={styles.imageArea}>
          <label className={`form-label ${styles.imageLabel}`}>
            {`${t(
              "NEWAPPLICATION.detailsEmployment.copyChairmanAuthorization.label"
            )}`}
          </label>
          <FileUploader
            textAdvise={textUploadChairmanAuthorization}
            fileType="NIAC"
          />

          <PDFUpload
            instruction={textUploadChairmanAuthorization}
            execute={typeOfAuthorizationSafetyFile}
            endpoint="draft/update-authorization-safety"
            formData={{ draftId: draftId, authorizationSafety: "" }}
            dataKey="authorizationSafety"
            fileName="Approved Office Order"
            defaultValue={myCurrentStates.typeOfAuthorizationSafetyFile}
          />
        </div>
      </div> */}
    </>
  );

  const NIAC = (
    <>
      <div className="col-12 mt-3">
        <InputField
          disable={myCurrentStates.organizationName ? true : false}
          defaultValue={
            myCurrentStates.organizationName
              ? myCurrentStates.organizationName
              : ""
          }
          execute={organizationName}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.label"
          )}`}
          name="organizationName"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
          )}`}
          listItem={natureOrganization}
          disable={myCurrentStates.natureOfOrganization ? true : false}
          defaultValue={
            myCurrentStates.natureOfOrganization
              ? myCurrentStates.natureOfOrganization
              : ""
          }
          execute={natureOfOrganization}
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.divisionName ? myCurrentStates.divisionName : ""
          }
          execute={divisionName}
          type="text"
          label={`${t("NEWAPPLICATION.detailsEmployment.divisionName.label")}`}
          name="division_name"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.divisionName.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          // defaultValue={
          //   myCurrentStates.natureOfOrganization === "COTN"
          //     ? ""
          //     : myCurrentStates.designation
          //     ? myCurrentStates.designation
          //     : ""
          // }
          defaultValue={
            myCurrentStates.designation ? myCurrentStates.designation : ""
          }
          execute={designation}
          type="text"
          label={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
          }
          name="designation"
          placeholder={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t(
                  "NEWAPPLICATION.detailsEmployment.designation.placeholder"
                )}`
          }
          maxLen="20"
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.currentPosition
              ? myCurrentStates.currentPosition
              : ""
          }
          execute={currentPosition}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.label"
          )}`}
          name="current_position"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <DateInput
          label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
          execute={dateOfJoining}
          defaultValue={
            myCurrentStates.dateOfJoining ? myCurrentStates.dateOfJoining : ""
          }
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
          )}`}
          listItem={employmentTypes}
          defaultValue={
            myCurrentStates.typeOfEmployment
              ? myCurrentStates.typeOfEmployment
              : ""
          }
          execute={typeOfEmployment}
        />
      </div>

      {myCurrentStates.typeOfEmployment === "Other (Specify)" &&
        inputFieldSpecifyEmployment}

      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.typeAuthorization.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.typeAuthorization.placeholder"
          )}`}
          listItem={authorizationTypes}
          defaultValue={
            myCurrentStates.typeOfAuthorization
              ? myCurrentStates.typeOfAuthorization
              : ""
          }
          execute={typeOfAuthorization}
        />
      </div>

      {myCurrentStates.typeOfAuthorization !== "Security_AVSEC" &&
        inputFieldSafetyInspector}
    </>
  );

  const NAP = (
    <>
      <div className="col-12 mt-3">
        <InputField
          disable={myCurrentStates.organizationName ? true : false}
          defaultValue={
            myCurrentStates.organizationName
              ? myCurrentStates.organizationName
              : ""
          }
          execute={organizationName}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.label"
          )}`}
          name="organizationName"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
          )}`}
          listItem={natureOrganization}
          disable={myCurrentStates.natureOfOrganization ? true : false}
          defaultValue={
            myCurrentStates.natureOfOrganization
              ? myCurrentStates.natureOfOrganization
              : ""
          }
          execute={natureOfOrganization}
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.divisionName ? myCurrentStates.divisionName : ""
          }
          execute={divisionName}
          type="text"
          label={`${t("NEWAPPLICATION.detailsEmployment.divisionName.label")}`}
          name="division_name"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.divisionName.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          // defaultValue={
          //   myCurrentStates.natureOfOrganization === "COTN"
          //     ? ""
          //     : myCurrentStates.designation
          //     ? myCurrentStates.designation
          //     : ""
          // }
          defaultValue={
            myCurrentStates.designation ? myCurrentStates.designation : ""
          }
          execute={designation}
          type="text"
          label={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
          }
          name="designation"
          placeholder={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t(
                  "NEWAPPLICATION.detailsEmployment.designation.placeholder"
                )}`
          }
          maxLen="20"
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.currentPosition
              ? myCurrentStates.currentPosition
              : ""
          }
          execute={currentPosition}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.label"
          )}`}
          name="current_position"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <DateInput
          label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
          execute={dateOfJoining}
          defaultValue={
            myCurrentStates.dateOfJoining ? myCurrentStates.dateOfJoining : ""
          }
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
          )}`}
          listItem={employmentTypes}
          defaultValue={
            myCurrentStates.typeOfEmployment
              ? myCurrentStates.typeOfEmployment
              : ""
          }
          execute={typeOfEmployment}
        />
      </div>

      {myCurrentStates.typeOfEmployment === "Other (Specify)" &&
        inputFieldSpecifyEmployment}

      <div className="col-12 mt-3">
        <TextInputArea
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.describeNatureDuty.label"
          )}`}
          name="describeNatureDuty"
          defaultValue={
            myCurrentStates.natureOfDutyDescription
              ? myCurrentStates.natureOfDutyDescription
              : ""
          }
          execute={natureOfDutyDescription}
        />
      </div>
      {/* <div className="col-12 mt-3">
        <div className={styles.imageArea}>
          <label className={`form-label ${styles.imageLabel}`}>
            {`${t(
              "NEWAPPLICATION.detailsEmployment.approvedOfficeOrder.label"
            )}`}
          </label>
          <FileUploader textAdvise={textUploadApproval} fileType="NAP" />
          <PDFUpload
            instruction={textUploadApproval}
            execute={natureOfDutyDescriptionFile}
            endpoint="draft/update-duty-description"
            formData={{ draftId: draftId, dutyDescription: "" }}
            dataKey="dutyDescription"
            fileName="Approved Office Order"
            defaultValue={myCurrentStates.natureOfDutyDescriptionFile}
          />
        </div>
      </div> */}
    </>
  );

  const ASP = (
    <>
      <div className="col-12 mt-3">
        <InputField
          disable={myCurrentStates.organizationName ? true : false}
          defaultValue={
            myCurrentStates.organizationName
              ? myCurrentStates.organizationName
              : ""
          }
          execute={organizationName}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.label"
          )}`}
          name="organizationName"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
          )}`}
          listItem={natureOrganization}
          disable={myCurrentStates.natureOfOrganization ? true : false}
          defaultValue={
            myCurrentStates.natureOfOrganization
              ? myCurrentStates.natureOfOrganization
              : ""
          }
          execute={natureOfOrganization}
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          // defaultValue={
          //   myCurrentStates.natureOfOrganization === "COTN"
          //     ? ""
          //     : myCurrentStates.designation
          //     ? myCurrentStates.designation
          //     : ""
          // }
          defaultValue={
            myCurrentStates.designation ? myCurrentStates.designation : ""
          }
          execute={designation}
          type="text"
          label={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
          }
          name="designation"
          placeholder={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t(
                  "NEWAPPLICATION.detailsEmployment.designation.placeholder"
                )}`
          }
          maxLen="20"
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.currentPosition
              ? myCurrentStates.currentPosition
              : ""
          }
          execute={currentPosition}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.label"
          )}`}
          name="current_position"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
          )}`}
          listItem={employmentTypes}
          defaultValue={
            myCurrentStates.typeOfEmployment
              ? myCurrentStates.typeOfEmployment
              : ""
          }
          execute={typeOfEmployment}
        />
      </div>

      {myCurrentStates.typeOfEmployment === "Other (Specify)" &&
        inputFieldSpecifyEmployment}

      <div className="col-12 mt-3">
        <Selector
          defaultValue={
            myCurrentStates.natureOfDuty ? myCurrentStates.natureOfDuty : ""
          }
          execute={natureOfDuty}
          label={`${t("NEWAPPLICATION.detailsEmployment.natureDuty.label")}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.natureDuty.placeholder"
          )}`}
          listItem={natureDuty}
        />
      </div>

      {isNatureOfDutyModified && (
        <>
          {isGovt && (
            <div className="col-12 mt-3">
              <Selector
                defaultValue={
                  myCurrentStates.locationOfDuty
                    ? myCurrentStates.locationOfDuty
                    : ""
                }
                execute={locationOfDuty}
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
                )}`}
                listItem={locationPrimary}
                disable={isGovt}
              />
            </div>
          )}

          {!isGovt && (
            <div className="col-12 mt-3">
              <Selector
                defaultValue={
                  myCurrentStates.locationOfDuty
                    ? myCurrentStates.locationOfDuty
                    : ""
                }
                execute={locationOfDuty}
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
                )}`}
                listItem={locationPrimary}
                // disable={isGovt}
              />
            </div>
          )}

          {isAirside && (
            <div className="col-12 mt-3">
              <Selector
                defaultValue={
                  myCurrentStates.scopeOfOperation
                    ? myCurrentStates.scopeOfOperation
                    : ""
                }
                execute={scopeOfOperation}
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
                )}`}
                listItem={scopeOperation}
                disable={isAirside}
              />
            </div>
          )}

          {!isAirside && (
            <div className="col-12 mt-3">
              <Selector
                defaultValue={
                  myCurrentStates.scopeOfOperation
                    ? myCurrentStates.scopeOfOperation
                    : ""
                }
                execute={scopeOfOperation}
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
                )}`}
                listItem={scopeOperation}
                // disable={isAirside}
              />
            </div>
          )}
        </>
      )}

      {!isNatureOfDutyModified && (
        <>
          {isGovt && (
            <div className="col-12 mt-3">
              <Selector
                defaultValue={
                  myCurrentStates.locationOfDuty
                    ? myCurrentStates.locationOfDuty
                    : ""
                }
                execute={locationOfDuty}
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
                )}`}
                listItem={locationPrimary}
                disable={isGovt}
              />
            </div>
          )}

          {!isGovt && (
            <div className="col-12 mt-3">
              <Selector
                defaultValue={
                  myCurrentStates.locationOfDuty
                    ? myCurrentStates.locationOfDuty
                    : ""
                }
                execute={locationOfDuty}
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.placeholder"
                )}`}
                listItem={locationPrimary}
                // disable={isGovt}
              />
            </div>
          )}

          {isAirside && (
            <div className="col-12 mt-3">
              <Selector
                defaultValue={
                  myCurrentStates.scopeOfOperation
                    ? myCurrentStates.scopeOfOperation
                    : ""
                }
                execute={scopeOfOperation}
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
                )}`}
                listItem={scopeOperation}
                disable={isAirside}
              />
            </div>
          )}

          {!isAirside && (
            <div className="col-12 mt-3">
              <Selector
                defaultValue={
                  myCurrentStates.scopeOfOperation
                    ? myCurrentStates.scopeOfOperation
                    : ""
                }
                execute={scopeOfOperation}
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
                )}`}
                listItem={scopeOperation}
                // disable={isAirside}
              />
            </div>
          )}
        </>
      )}
    </>
  );

  const CMAP = (
    <>
      <div className="col-12 mt-3">
        <InputField
          disable={myCurrentStates.organizationName ? true : false}
          defaultValue={
            myCurrentStates.organizationName
              ? myCurrentStates.organizationName
              : ""
          }
          execute={organizationName}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.label"
          )}`}
          name="organizationName"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
          )}`}
          listItem={natureOrganization}
          disable={myCurrentStates.natureOfOrganization ? true : false}
          defaultValue={
            myCurrentStates.natureOfOrganization
              ? myCurrentStates.natureOfOrganization
              : ""
          }
          execute={natureOfOrganization}
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          // defaultValue={
          //   myCurrentStates.natureOfOrganization === "COTN"
          //     ? ""
          //     : myCurrentStates.designation
          //     ? myCurrentStates.designation
          //     : ""
          // }
          defaultValue={
            myCurrentStates.designation ? myCurrentStates.designation : ""
          }
          execute={designation}
          type="text"
          label={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
          }
          name="designation"
          placeholder={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t(
                  "NEWAPPLICATION.detailsEmployment.designation.placeholder"
                )}`
          }
          maxLen="20"
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.currentPosition
              ? myCurrentStates.currentPosition
              : ""
          }
          execute={currentPosition}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.label"
          )}`}
          name="current_position"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
          )}`}
          listItem={employmentTypes}
          defaultValue={
            myCurrentStates.typeOfEmployment
              ? myCurrentStates.typeOfEmployment
              : ""
          }
          execute={typeOfEmployment}
        />
      </div>

      {myCurrentStates.typeOfEmployment === "Other (Specify)" &&
        inputFieldSpecifyEmployment}

      <div className="col-12 mt-3">
        <Selector
          defaultValue={
            myCurrentStates.scopeOfOperation
              ? myCurrentStates.scopeOfOperation
              : ""
          }
          execute={scopeOfOperation}
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.scopeOperation.placeholder"
          )}`}
          listItem={scopeOperation}
        />
      </div>
    </>
  );

  const AAIAP = (
    <>
      <div className="col-12 mt-3">
        <InputField
          disable={myCurrentStates.organizationName ? true : false}
          defaultValue={
            myCurrentStates.organizationName
              ? myCurrentStates.organizationName
              : ""
          }
          execute={organizationName}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.label"
          )}`}
          name="organizationName"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.organizationName.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
          )}`}
          listItem={natureOrganization}
          disable={myCurrentStates.natureOfOrganization ? true : false}
          defaultValue={
            myCurrentStates.natureOfOrganization
              ? myCurrentStates.natureOfOrganization
              : ""
          }
          execute={natureOfOrganization}
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.divisionName ? myCurrentStates.divisionName : ""
          }
          execute={divisionName}
          type="text"
          label={`${t("NEWAPPLICATION.detailsEmployment.divisionName.label")}`}
          name="division_name"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.divisionName.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          // defaultValue={
          //   myCurrentStates.natureOfOrganization === "COTN"
          //     ? ""
          //     : myCurrentStates.designation
          //     ? myCurrentStates.designation
          //     : ""
          // }
          defaultValue={
            myCurrentStates.designation ? myCurrentStates.designation : ""
          }
          execute={designation}
          type="text"
          label={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
          }
          name="designation"
          placeholder={
            myCurrentStates.natureOfOrganization === "COTN"
              ? "Company Name"
              : `${t(
                  "NEWAPPLICATION.detailsEmployment.designation.placeholder"
                )}`
          }
          maxLen="20"
        />
      </div>
      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.currentPosition
              ? myCurrentStates.currentPosition
              : ""
          }
          execute={currentPosition}
          type="text"
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.label"
          )}`}
          name="current_position"
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.currentPosition.placeholder"
          )}`}
        />
      </div>
      <div className="col-12 mt-3">
        <DateInput
          label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
          execute={dateOfJoining}
          defaultValue={
            myCurrentStates.dateOfJoining ? myCurrentStates.dateOfJoining : ""
          }
        />
      </div>
      <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.typeEmployment.placeholder"
          )}`}
          listItem={[
            ...employmentTypes,
            ...[
              {
                label: `${t(
                  "NEWAPPLICATION.detailsEmployment.typeEmployment.option.shortTerm"
                )}`,
                value: "Short Term Expert",
              },
            ],
          ]}
          defaultValue={
            myCurrentStates.typeOfEmployment
              ? myCurrentStates.typeOfEmployment
              : ""
          }
          execute={typeOfEmployment}
        />
      </div>

      {myCurrentStates.typeOfEmployment === "Other (Specify)" &&
        inputFieldSpecifyEmployment}

      <div className="col-12 mt-3">
        <Selector
          label={`Type of Assignment`}
          placeholder={`Select one`}
          listItem={typeOfAssignmentOptions}
          defaultValue={
            myCurrentStates.typeOfAssignment
              ? myCurrentStates.typeOfAssignment
              : ""
          }
          execute={typeOfAssignment}
        />
      </div>

      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.investigatorCategory
              ? myCurrentStates.investigatorCategory
              : ""
          }
          execute={investigatorCategory}
          type="text"
          label={`Category of Investigator`}
          name="investigator_cat"
          placeholder={`Your Category`}
          maxLen={100}
        />
      </div>

      <div className="col-12 mt-3">
        <InputField
          defaultValue={
            myCurrentStates.investigatorID ? myCurrentStates.investigatorID : ""
          }
          execute={investigatorID}
          type="text"
          label={`Investigator ID Number`}
          name="investigator_ID"
          placeholder={`Your Identification Number`}
          maxLen={20}
        />
      </div>

      {/* <div className="col-12 mt-3">
        <Selector
          label={`${t(
            "NEWAPPLICATION.detailsEmployment.typeAuthorization.label"
          )}`}
          placeholder={`${t(
            "NEWAPPLICATION.detailsEmployment.typeAuthorization.placeholder"
          )}`}
          listItem={authorizationTypes}
          defaultValue={
            myCurrentStates.typeOfAuthorization
              ? myCurrentStates.typeOfAuthorization
              : ""
          }
          execute={typeOfAuthorization}
        />
      </div>

      {myCurrentStates.typeOfAuthorization !== "Security_AVSEC" &&
        inputFieldSafetyInspector} */}
    </>
  );

  const nextStepDecider = () => {
    switch (passType) {
      case "ASP":
        return <StepperShift showDraft lastCompleted="C" />;

      case "CMAP":
        return <StepperShift showDraft lastCompleted="C" />;

      default:
        return <StepperShift increaseBy={2} showDraft lastCompleted="C" />;
    }
  };
  //   issueType === "Initial" ? (
  //     <StepperShift increaseBy={3} />
  //   ) : (
  //     <StepperShift />
  //   );

  return (
    <>
      <FormTitle
        header={`${t("NEWAPPLICATION.detailsEmployment.title")}`}
        subTitle={`${t("NEWAPPLICATION.detailsEmployment.subTitle")}`}
      />
      <Stepper />
      <div className="row">
        {passType === "NIAC" && NIAC}
        {passType === "NAP" && NAP}
        {passType === "ASP" && ASP}
        {passType === "CMAP" && CMAP}
        {passType === "AAIAP" && AAIAP}
      </div>
      {checkedValue ? nextStepDecider() : <StepperShift nextDisabled />}
    </>
  );
}
