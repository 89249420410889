import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./header.css";
import Logo from "../../../assets/images/caabLogo.png";
import UserImage from "../../../assets/images/defaultUser.jpg";
import Notification from "./Notification/Notification";
import { useNavigate } from "react-router-dom";
// import { settingsData } from "../../../redux/actions";
// import { useDispatch } from "react-redux";
import { useAuth } from "../../../Authentication/AuthContext";
import { useSelector } from "react-redux";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [notificationVisible, setNotificationVisible] = useState(false);
  // const [myApplication, setMyApplication] = useState({});
  const [currentLng, setCurrentLng] = useState("");
  const { logout } = useAuth();
  const myApplication = useSelector((state) => state.settingsData);
  const unreadNotification = useSelector(
    (state) => state.commonVariables.unreadNotificationCount
  );

  // useEffect(() => {
  //   let myApplication = JSON.parse(localStorage.getItem("user"));
  //   setMyApplication(myApplication);
  //   dispatch(settingsData(myApplication));
  // }, [dispatch]);
  useEffect(() => {
    setCurrentLng(document.cookie);
  }, []);

  const user = (
    <div className="profile-pic-container">
      <div className="profile-pic">
        <img
          src={myApplication.profilePic ? myApplication.profilePic : UserImage}
          alt="Profile Pic"
        />
      </div>
      {myApplication.name}
      <FontAwesomeIcon icon={faChevronDown} className="icon-chevron-down" />
    </div>
  );

  const profile = (
    <div className="drop-down-menu" onClick={() => navigate("settings")}>
      <FontAwesomeIcon icon={faUserCircle} className="icon-dropdown" />
      {t("HEADER.navProfile")}
    </div>
  );

  const logOut = (
    <div
      className="drop-down-menu"
      onClick={() => {
        // localStorage.clear();
        // navigate("/");
        logout();
      }}
    >
      <FontAwesomeIcon icon={faSignOutAlt} className="icon-dropdown" />
      {t("HEADER.navLogout")}
    </div>
  );

  const bell = (
    <div
      className="notification"
      onClick={() => setNotificationVisible((prevState) => !prevState)}
    >
      <FontAwesomeIcon icon={faBell} className="icon-bell" />
      {unreadNotification > 0 && (
        <div className="notification-count">{unreadNotification}</div>
      )}
      {notificationVisible && (
        <div className="notification-container">
          <Notification />
        </div>
      )}
    </div>
  );

  return (
    <Navbar className="navbar-bg" expand="md" collapseOnSelect>
      <Navbar.Brand href="#">
        <img src={Logo} alt="Main Logo" className="logo" />
        {t("HEADER.title")}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <input
            type="checkbox"
            id="switchNavHeader"
            className="checkbox-nav"
            checked={currentLng === "i18next=bn-BD" ? false : true}
            onClick={(ev) => {
              if (currentLng === "i18next=en-US") {
                i18n.changeLanguage("bn-BD");
                setCurrentLng("i18next=bn-BD");
              }
              if (currentLng === "i18next=en-GB") {
                i18n.changeLanguage("bn-BD");
                setCurrentLng("i18next=bn-BD");
              }
              if (currentLng === "i18next=bn-BD") {
                i18n.changeLanguage("en-US");
                setCurrentLng("i18next=en-US");
              }
            }}
            readOnly
            // checked={true}
            // defaultChecked={false}
          />
          <label htmlFor="switchNavHeader" className="toggle-nav">
            <p className="textLanguage-nav">বাং EN</p>
            {/* BN */}
          </label>

          {bell}

          <NavDropdown title={user} id="basic-nav-dropdown">
            <NavDropdown.Item href="#">{profile}</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#">{logOut}</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
