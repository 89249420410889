import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./fonts/Poppins-SemiBold.ttf";
import "./fonts/Poppins-Regular.ttf";
import "./fonts/Poppins-Medium.ttf";
import "./fonts/kalpurush.ttf";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";

//toastify
import "react-toastify/dist/ReactToastify.css";

//datepicker
import "react-datepicker/dist/react-datepicker.css";

//date-range picker
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

//Languages
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    detection: {
      // order and from where user language should be detected
      order: [
        "cookie",
        "localStorage",
        "sessionStorage",
        "querystring",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],

      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["cookie", "localStorage"],

      // excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    // react: { useSuspense: false },
  });

const loadingMarkUp = (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{ height: "100vh" }}
  >
    <h4>Loading...</h4>
  </div>
);

ReactDOM.render(
  <Suspense fallback={loadingMarkUp}>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
