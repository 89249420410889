import React from "react";

// components
import Step from "./Step/Step";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

// Custom Style
import styles from "./Stepper.module.css";
const Stepper = () => {
  const { t } = useTranslation();
  const selectedCard = useSelector(
    (state) => state.newApplicationData.newApplication.partA.passType
  );

  const NIAC_NAP = [
    `${t("NEWAPPLICATION.partA")}`,
    `${t("NEWAPPLICATION.partB")}`,
    `${t("NEWAPPLICATION.partC")}`,
    `${t("NEWAPPLICATION.partD")}`,
  ];
  const ASP_CMAP = [
    ...NIAC_NAP,
    `${t("NEWAPPLICATION.partE")}`,
    // `${t("NEWAPPLICATION.partF")}`
  ];

  const stepperArrayDecider = () => {
    switch (selectedCard) {
      case "ASP":
        return ASP_CMAP.map((area, index) => (
          <Step location={area} key={index} />
        ));

      case "CMAP":
        return ASP_CMAP.map((area, index) => (
          <Step location={area} key={index} />
        ));

      default:
        return NIAC_NAP.map((area, index) => (
          <Step location={area} key={index} />
        ));
    }
  };

  return (
    <>
      <div className={styles.stepperArea}>
        <ul className={styles.stepper}>
          {/* {selectedCard === "NIAC" &&
            NIAC_NAP.map((area, index) => <Step location={area} key={index} />)}
          {selectedCard === "NAP" &&
            NIAC_NAP.map((area, index) => <Step location={area} key={index} />)}
          {selectedCard === "CMAP" &&
            ASP_CMAP.map((area, index) => <Step location={area} key={index} />)}
          {selectedCard === "ASP" &&
            ASP_CMAP.map((area, index) => <Step location={area} key={index} />)} */}
          {stepperArrayDecider()}
        </ul>
      </div>
    </>
  );
};

export default Stepper;
