const initialState = {
  newApplication: {
    passType: "",
    issueType: "",
    companyName: "",
    companyAddress: "",
    typeOfOrganization: "",
    requiredPeriodDate: "",
    natureOfActivity: "",
    areaOfActivity: "",
  },
};

const vehicleNewApplicationData = (state = initialState, action) => {
  let formData = action.payload;

  switch (action.type) {
    case "RESET_APPLICATION_DATA_VEHICLE":
      return {
        ...state,
        newApplication: initialState.newApplication,
      };

    case "APPLICATION_DATA_VEHICLE":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          ...formData,
        },
      };

    default:
      return state;
  }
};

export default vehicleNewApplicationData;
