const initialState = {
  draftCount: 0,
  unreadNotificationCount: 0,
};

const commonVariables = (state = initialState, action) => {
  let currentState = { ...state };
  let data = action.payload;

  switch (action.type) {
    case "SET_DRAFT_COUNT":
      currentState.draftCount = data;
      return currentState;
    case "UNREAD_NOTIFICATION_COUNT":
      currentState.unreadNotificationCount = data;
      return currentState;

    default:
      return state;
  }
};

export default commonVariables;
