import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { popupLoaderReset } from "../../redux/actions";

//custom css
import "./popup.css";

function Popup({ children, navigateTo, title, subTitle, variety }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);

  const handleNavigation = useCallback(() => {
    if (navigateTo === "dashboard") navigate("/dashboard");
    if (variety === "success") {
      dispatch(popupLoaderReset());
      navigate("/dashboard");
      // navigate(`/${navigateTo}`);
    }
    // else if (variety === "error") {
    //   dispatch(popupLoaderReset());
    // } else dispatch(popupLoaderReset());
  }, [navigateTo, variety, dispatch, navigate]);

  useEffect(() => {
    if (isActive) setTimeout(() => handleNavigation(), 1000);
    return () => setIsActive(false);
  }, [isActive, setIsActive, handleNavigation]);

  return (
    <>
      {" "}
      {isActive && (
        <>
          <div className="msg-container">
            {/* <div className="msg-container" onClick={handleNavigation}> */}
            <FontAwesomeIcon
              icon={variety === "error" ? faExclamationTriangle : faCheck}
              className={variety === "error" ? "icon-check-red" : "icon-check"}
            />
            <div className="font-md">{title}</div>
            {subTitle === "Successful" ? (
              <div className=" font-md blue">{subTitle}</div>
            ) : (
              <div className=" font-reg">{subTitle}</div>
            )}
          </div>
          <div className="popup-container">{children}</div>
        </>
      )}
    </>
  );
}

export default Popup;
