import React, { useState } from "react";

// custom style
import styles from "./TextInputArea.module.css";

const TextInputArea = ({
  label,
  name,
  defaultValue = "",
  execute,
  disabled = false,
  isMandatory = true,
}) => {
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
    execute(e.target.value);
  };

  return (
    <>
      <div className={styles.textInputArea}>
        {label && (
          <>
            <label className={`form-label ${styles.textInputAreaLabel}`}>
              {label}
            </label>
            {isMandatory && <span className="text-danger">*</span>}
          </>
        )}
        <textarea
          name={name}
          className={`form-control  ${styles.textInputAreaInput}`}
          rows="4"
          // defaultValue={value}
          disabled={disabled}
          onChange={handleChange}
          value={value ? value : defaultValue === null ? "" : defaultValue}
        ></textarea>
      </div>
    </>
  );
};

export default TextInputArea;
