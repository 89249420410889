import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import fileMedical from "../../assets/images/utility_icons/file-medical.png";
import { useAuth } from "../../Authentication/AuthContext";
import useHttp from "../../Http/useHttp";
import { toast } from "react-toastify";

// Fontawesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faArrowUp,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

// custom style
import styles from "./ImageUpload.module.css";

function ImageUpload({
  //   defaultValue  = "https://caabusersite.jhorotek.com/Drafts/user_15/62bae6cc2909f_1_1656415948.jpg",
  defaultValue,
  uploadButtonAvailable = true,
  deleteButtonAvailable = true,
  // settingSignature = false,
  instruction,
  endpoint,
  execute,
  formData,
  dataKey,
}) {
  const [fileLink, setFileLink] = useState("");

  const { t } = useTranslation();
  const api = useHttp("authorized");
  const { logout } = useAuth();

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      // const localFileUrl = window.URL.createObjectURL(acceptedFiles[0]);

      formData[dataKey] = acceptedFiles[0];
      const form_data = new FormData();
      for (const key in formData) {
        form_data.append(key, formData[key]);
      }

      //   console.log(form_data);

      const resolveAfterResponse = new Promise(async (resolve, reject) => {
        try {
          const resp = await api.post(endpoint, form_data);
          // console.log(resp.data);
          setFileLink(resp.data.url);
          execute(resp.data.url);
          resolve();
        } catch (error) {
          // console.log(error);
          if (error.response.data.status === 401) {
            reject("Not Authorized Please Login");
            logout();
          } else reject(error.response.data.message);
        }
      });

      toast.promise(
        resolveAfterResponse,
        {
          pending: "Please Wait...",
          success: "Successfully Uploaded",
          error: {
            render({ data }) {
              return data;
            },
          },
        },
        {
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 1,
    accept: "image/jpeg, image/png",
    multiple: false,
    maxSize: 5242880,
    onDrop,
  });

  const deleteFile = async () => {
    // const formData = new FormData();
    // formData.append("draftId", 15);
    // formData.append("dutyDescription", "");

    formData[dataKey] = "";
    const form_data = new FormData();
    for (const key in formData) {
      form_data.append(key, formData[key]);
    }

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        const resp = await api.post(endpoint, form_data);
        // console.log(resp.data);
        setFileLink(resp.data.url);
        execute(resp.data.url);
        resolve();
      } catch (error) {
        // console.log(error);
        if (error.response.data.status === 401) {
          reject("Not Authorized Please Login");
          logout();
        } else reject(error.response.data.message);
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Successfully Deleted",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  useEffect(() => {
    defaultValue && setFileLink(defaultValue);
  }, [defaultValue]);

  return (
    <div className="row">
      <div className={`col-lg-6 col-md-12 col-sm-12 col-12 ${styles.leftDiv}`}>
        <div {...getRootProps({ className: `${styles.dropzone}` })}>
          <input {...getInputProps()} />
          <div
            className={`${defaultValue ? styles.removePicture : ""} ${
              styles.container
            }`}
          >
            <img
              src={fileMedical}
              alt="File Medical"
              className={styles.image}
            />
            <p className={styles.textDropzone}>
              {t("NEWAPPLICATION.placeholderDropzone.colorBefore")}
            </p>
            <p className={styles.textDropzone}>
              <span className={styles.textColor}>
                {t("NEWAPPLICATION.placeholderDropzone.color")}
              </span>{" "}
              {t("NEWAPPLICATION.placeholderDropzone.colorAfter")}
            </p>
          </div>
          <img
            src={fileLink}
            // src={defaultValue}
            alt="Upload Image"
            className={`${
              defaultValue ? styles.showPicture : styles.removePicture
            } ${styles.uploadedPicture}`}
          />
        </div>
      </div>

      <div className={`col-md-6 col-sm-6 col-12 ${styles.rightDiv}`}>
        <div className={styles.uploadRight}>
          <div className={styles.uploadContent}>
            <FontAwesomeIcon icon={faInfoCircle} className={styles.iconSize} />
            <div className={styles.text}>{instruction}</div>
          </div>
          <div className={`${styles.btnArea} mt-3`}>
            {uploadButtonAvailable && (
              <button
                type="button"
                className={`btn btn-primary ${styles.button}`}
                onClick={() => open()}
              >
                <FontAwesomeIcon icon={faArrowUp} className="me-2" />{" "}
                {t("NEWAPPLICATION.uploadBtn")}
              </button>
            )}
            {deleteButtonAvailable && (
              <button
                type="button"
                className={`btn btn-danger ${styles.button} ${
                  uploadButtonAvailable && styles.leftSpace
                }`}
                onClick={() => deleteFile()}
              >
                <FontAwesomeIcon icon={faWindowClose} className="me-2" />{" "}
                {t("NEWAPPLICATION.discardBtn")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
