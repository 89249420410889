import React, { useState } from "react";

import FormTitle from "../../Utilities/FormTitle/FormTitle";
import Stepper from "../../Utilities/Stepper/Stepper";
import StepperShift from "../../Utilities/StepperShift/StepperShift";
import AddressContents from "../AddressContents/AddressContents";

import { useTranslation } from "react-i18next";

// Custom Style
import styles from "./AddressInformation.module.css";

const AddressInformation = () => {
  const { t } = useTranslation();
  const [checkedValue, setCheckedValue] = useState(false);
  const isFormValid = (value) => setCheckedValue(value);

  return (
    <>
      <FormTitle
        header={`${t("NEWAPPLICATION.addressInformation.title")}`}
        subTitle={`${t("NEWAPPLICATION.addressInformation.subTitle")}`}
      />
      <Stepper />
      <AddressContents
        validation={isFormValid}
        checkState={false}
        checkText={null}
        header={`${t(
          "NEWAPPLICATION.addressInformation.permanentAddressHeader"
        )}`}
      />
      <div className={styles.marginBefore}>
        <AddressContents
          validation={isFormValid}
          checkState={true}
          checkText={`${t(
            "NEWAPPLICATION.addressInformation.similarCheckedText"
          )}`}
          header={`${t(
            "NEWAPPLICATION.addressInformation.presentAddressHeader"
          )}`}
        />
      </div>
      {checkedValue ? (
        <StepperShift showDraft lastCompleted="B" />
      ) : (
        <StepperShift nextDisabled />
      )}
    </>
  );
};

// export default AddressInformation;
export default React.memo(AddressInformation);
