/** =========================== Common actions ============================== */

export const setDraftCount = (count) => {
  return {
    type: "SET_DRAFT_COUNT",
    payload: count,
  };
};
export const setUnreadNotificationCount = (count) => {
  return {
    type: "UNREAD_NOTIFICATION_COUNT",
    payload: count,
  };
};

/** =========================== Loader and Popup actions ============================== */

export const popupLoaderReset = () => {
  return {
    type: "RESET_POPUP_LOADER_STATES",
  };
};

export const loaderActive = () => {
  return {
    type: "LOADER_ACTIVE",
  };
};

export const loaderInActive = () => {
  return {
    type: "LOADER_INACTIVE",
  };
};

export const popupActive = () => {
  return {
    type: "POPUP_ACTIVE",
  };
};

export const popupInActive = () => {
  return {
    type: "POPUP_INACTIVE",
  };
};

export const popupVariety = (loaderVariety) => {
  return {
    type: "CHANGE_POPUP_VARIETY",
    payload: loaderVariety,
  };
};

/** =========================== Registration Form actions ============================== */

export const formFillUp = (activeFormData) => {
  return {
    type: "FORM_FILL_UP",
    payload: activeFormData,
  };
};

// export const formFillUpReset = () => {
//   return {
//     type: "RESET",
//   };
// };

/** =========================== Forgot Password Form actions ============================== */

export const resetFormFillUp = (activeFormData) => {
  return {
    type: "RESET_FORM_FILL_UP",
    payload: activeFormData,
  };
}; //RESET_FORGOT_PASSWORD_STATES

export const formFillUpReset = () => {
  return {
    type: "RESET_FORGOT_PASSWORD_STATES",
  };
};

/** =========================== Stepper Shift actions ============================== */

export const changeNextState = (increaseBy) => {
  return {
    type: "CHANGE_NEXT_STATE",
    payload: increaseBy,
  };
};

export const changePreviousState = (decreaseBy) => {
  return {
    type: "CHANGE_PREVIOUS_STATE",
    payload: decreaseBy,
  };
};

export const resetApplicationState = () => {
  return {
    type: "RESET_APPLICATION_STATE",
  };
};

export const changeActiveApplicationPart = (activePart) => {
  return {
    type: "CHANGE_ACTIVE_APPLICATION_PART",
    payload: activePart,
  };
};

/** =========================== Vehicle New Application actions ============================== */

export const resetApplicationDataVehicle = () => {
  return {
    type: "RESET_APPLICATION_DATA_VEHICLE",
  };
};

export const applicationDataVehicle = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_VEHICLE",
    payload: activeFormData,
  };
};

/** =========================== New Application actions ============================== */

export const resetApplicationData = () => {
  return {
    type: "RESET_APPLICATION_DATA",
  };
};

export const setDraftData = (activeFormData) => {
  return {
    type: "SET_APPLICATION_DATA",
    payload: activeFormData,
  };
};

export const applicationDataPartA = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_A",
    payload: activeFormData,
  };
};

export const resetApplicationDataCardChanged = () => {
  return {
    type: "RESET_APPLICATION_DATA_CARD_CHANGED",
  };
};

export const applicationDataPartB = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_B",
    payload: activeFormData,
  };
};

export const applicationDataPartBPresentAddress = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_B_PRESENT_ADDRESS",
    payload: activeFormData,
  };
};

export const applicationDataPartBPermanentAddress = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_B_PERMANENT_ADDRESS",
    payload: activeFormData,
  };
};

export const copyPermanentToPresent = () => {
  return {
    type: "COPY_PERMANENT_TO_PRESENT",
  };
};

export const resetPresentAddress = () => {
  return {
    type: "RESET_PRESENT_ADDRESS",
  };
};

export const applicationDataPartC = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_C",
    payload: activeFormData,
  };
};

export const applicationDataPartD = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_D",
    payload: activeFormData,
  };
};

export const applicationDataPartE = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_E",
    payload: activeFormData,
  };
};

export const applicationDataPartF = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_F",
    payload: activeFormData,
  };
};

export const applicationDataAttachment = (activeFormData) => {
  return {
    type: "APPLICATION_DATA_ATTACHMENT",
    payload: activeFormData,
  };
};

export const applicationDataAttachmentReset = () => {
  return {
    type: "APPLICATION_DATA_ATTACHMENT_RESET",
  };
};

/** =========================== Settings actions ============================== */

export const resetSettingsData = () => {
  return {
    type: "RESET_SETTINGS_DATA",
  };
};

export const settingsData = (activeFormData) => {
  return {
    type: "SETTINGS_DATA",
    payload: activeFormData,
  };
};

export const settingsPresentAddress = (activeFormData) => {
  return {
    type: "SETTINGS_PRESENT_ADDRESS",
    payload: activeFormData,
  };
};

export const settingsPermanentAddress = (activeFormData) => {
  return {
    type: "SETTINGS_PERMANENT_ADDRESS",
    payload: activeFormData,
  };
};

export const settingsCopyPermanentToPresent = (activeFormData) => {
  return {
    type: "SETTINGS_COPY_PERMANENT_TO_PRESENT",
    payload: activeFormData,
  };
};

export const resetSettingsPresentAddress = (activeFormData) => {
  return {
    type: "RESET_SETTINGS_PRESENT_ADDRESS",
    payload: activeFormData,
  };
};
