import React from "react";
import { useDispatch } from "react-redux";
import { applicationDataPartA } from "../../redux/actions";
import CheckBox from "../CheckBox/CheckBox";
import { useTranslation } from "react-i18next";

// custom style
import styles from "./AuthorizationArea.module.css";

const AuthorizationArea = ({ checkboxes, isDisable = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const A = (selectedData) => {
    dispatch(
      applicationDataPartA({
        A: selectedData,
      })
    );
  };

  const B = (selectedData) => {
    dispatch(
      applicationDataPartA({
        B: selectedData,
      })
    );
  };

  const C = (selectedData) => {
    dispatch(
      applicationDataPartA({
        C: selectedData,
      })
    );
  };

  const D = (selectedData) => {
    dispatch(
      applicationDataPartA({
        D: selectedData,
      })
    );
  };

  const E = (selectedData) => {
    dispatch(
      applicationDataPartA({
        E: selectedData,
      })
    );
  };

  const R = (selectedData) => {
    dispatch(
      applicationDataPartA({
        R: selectedData,
      })
    );
  };

  const T = (selectedData) => {
    dispatch(
      applicationDataPartA({
        T: selectedData,
      })
    );
  };

  const two = (selectedData) => {
    dispatch(
      applicationDataPartA({
        two: selectedData,
      })
    );
  };

  const three = (selectedData) => {
    dispatch(
      applicationDataPartA({
        three: selectedData,
      })
    );
  };

  const four = (selectedData) => {
    dispatch(
      applicationDataPartA({
        four: selectedData,
      })
    );
  };

  const five = (selectedData) => {
    dispatch(
      applicationDataPartA({
        five: selectedData,
      })
    );
  };

  const six = (selectedData) => {
    dispatch(
      applicationDataPartA({
        six: selectedData,
      })
    );
  };

  return (
    <div className={styles.authorizationArea}>
      <label className={styles.authorizationAreaLabel}>
        {`${t("NEWAPPLICATION.typeIssueSelected.authorization.label")}`}
      </label>
      <div className={styles.checkboxArea}>
        <div className={styles.checkboxAreaRow}>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.A}
              disable={isDisable}
              name="A"
              label={`${t("NEWAPPLICATION.typeIssueSelected.A")}`}
              execute={A}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.B}
              disable={isDisable}
              name="B"
              label={`${t("NEWAPPLICATION.typeIssueSelected.B")}`}
              execute={B}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.C}
              disable={isDisable}
              name="C"
              label={`${t("NEWAPPLICATION.typeIssueSelected.C")}`}
              execute={C}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.D}
              disable={isDisable}
              name="D"
              label={`${t("NEWAPPLICATION.typeIssueSelected.D")}`}
              execute={D}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.E}
              disable={isDisable}
              name="E"
              label={`${t("NEWAPPLICATION.typeIssueSelected.E")}`}
              execute={E}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.T}
              disable={isDisable}
              name="T"
              label={`${t("NEWAPPLICATION.typeIssueSelected.T")}`}
              execute={T}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.R}
              disable={isDisable}
              name="R"
              label={`${t("NEWAPPLICATION.typeIssueSelected.R")}`}
              execute={R}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.two}
              disable={isDisable}
              name="two"
              label={`${t("NEWAPPLICATION.typeIssueSelected.2")}`}
              execute={two}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.three}
              disable={isDisable}
              name="three"
              label={`${t("NEWAPPLICATION.typeIssueSelected.3")}`}
              execute={three}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.four}
              disable={isDisable}
              name="four"
              label={`${t("NEWAPPLICATION.typeIssueSelected.4")}`}
              execute={four}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.five}
              disable={isDisable}
              name="five"
              label={`${t("NEWAPPLICATION.typeIssueSelected.5")}`}
              execute={five}
            />
          </div>
          <div className="me-3">
            <CheckBox
              defaultValue={checkboxes.six}
              disable={isDisable}
              name="six"
              label={`${t("NEWAPPLICATION.typeIssueSelected.6")}`}
              execute={six}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorizationArea;
