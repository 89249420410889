import React from "react";

// custom style
import styles from "./RadioButtonImage.module.css";

const RadioButtonImage = ({
  value,
  name,
  header,
  subTitle,
  extraClass,
  activeClass,
  onChecked,
  id,
  limit,
  used,
}) => {
  return (
    <>
      <div
        className={`${styles.btnContainer} ${styles[extraClass]} ${
          activeClass ? styles.btnAction : null
        }`}
        onClick={() =>
          onChecked({
            selected: value,
            selectedId: id,
            limit: limit,
            used: used,
          })
        }
      >
        <label className={`form-check-label ${styles.labelArea} ms-4`}>
          <div className="me-3">
            <input
              className={styles.checkbox}
              type="radio"
              name={name}
              value={value}
            />
            <div className={styles.radio}></div>
          </div>
          {/* {console.log(activeClass)} */}
          <div className={styles.textArea}>
            <span className={styles.headerText}>{header}</span>
            <span className={styles.subTitleText}>{subTitle}</span>
          </div>
        </label>
      </div>
    </>
  );
};

export default RadioButtonImage;
