import React, { useState, useEffect } from "react";
// components
import RadioButtonImage from "../../Utilities/RadioButtonImage/RadioButtonImage";
import FormTitle from "../../Utilities/FormTitle/FormTitle";
import StepperShift from "../../Utilities/StepperShift/StepperShift";
import Stepper from "../../Utilities/Stepper/Stepper";
import {
  applicationDataPartA,
  applicationDataPartB,
  applicationDataPartC,
  applicationDataPartD,
  resetApplicationDataCardChanged,
  changeActiveApplicationPart,
} from "../../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

// custom style
// import "./CardType.css";

const CardType = () => {
  const { t } = useTranslation();
  const [checkedValue, setCheckedValue] = useState("");
  const dispatch = useDispatch();

  const myCurrentStates = useSelector(
    (state) => state.newApplicationData.newApplication.partA
  );
  const settingsData = useSelector((state) => state.settingsData);
  const newApplicationData = useSelector(
    (state) => state.newApplicationData.newApplication.partB
  );

  const availableCards = useSelector(
    (state) => state.newApplicationData.newApplication.partA.availableCards
  );

  const passType = myCurrentStates.passType;

  useEffect(() => {
    setCheckedValue(passType);
    dispatch(changeActiveApplicationPart("Part A"));
  }, [passType, dispatch]);

  const handleCheckedValue = (value) => {
    // console.log(value);

    if (value.used >= value.limit) {
      // console.log("Show Warning!");
      toast.warning(
        `Your Organization's ${value.selected} limit is exceeded!`,
        {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
    }

    setCheckedValue(value.selected);
    dispatch(resetApplicationDataCardChanged());
    dispatch(
      applicationDataPartA({
        passType: value.selected,
        passTypeId: value.selectedId,
      })
    );
    const copiedData = { ...newApplicationData, ...settingsData };
    // console.log(copiedData);

    dispatch(applicationDataPartB(copiedData));
    dispatch(
      applicationDataPartC({
        organizationName: settingsData.organizationName,
        natureOfOrganization: settingsData.natureOfOrganization,
        designation: settingsData.designation,
      })
    );
    dispatch(
      applicationDataPartD({
        signature: copiedData.signature,
      })
    );
  };

  const PersonnelCard = ({ cardName, cardId, used, limit }) => (
    <>
      {cardName === "NIAC" && (
        <div className="col-md-6 col-sm-6 col-12 mt-3 mb-1">
          <RadioButtonImage
            extraClass={
              checkedValue === "NIAC" ? "containerHover" : "imageBlue"
            }
            header={`${t("NEWAPPLICATION.typeCard.NIAC.title")}`}
            subTitle={`${t("NEWAPPLICATION.typeCard.NIAC.subTitle")}`}
            value="NIAC"
            name="type_of_card"
            id={cardId}
            onChecked={handleCheckedValue}
            activeClass={checkedValue === "NIAC" ? true : false}
            used={used}
            limit={limit}
          />
        </div>
      )}
      {cardName === "NAP" && (
        <div className="col-md-6 col-sm-6 col-12 mt-3">
          <RadioButtonImage
            extraClass={checkedValue === "NAP" ? "containerHover" : "imageGray"}
            header={`${t("NEWAPPLICATION.typeCard.NAP.title")}`}
            subTitle={`${t("NEWAPPLICATION.typeCard.NAP.subTitle")}`}
            value="NAP"
            name="type_of_card"
            id={cardId}
            onChecked={handleCheckedValue}
            activeClass={checkedValue === "NAP" ? true : false}
            used={used}
            limit={limit}
          />
        </div>
      )}
      {/* 2nd row */}
      {cardName === "ASP" && (
        <div className="col-md-6 col-sm-6 col-12 mt-3 mb-1">
          <RadioButtonImage
            extraClass={checkedValue === "ASP" ? "containerHover" : "imageRed"}
            header={`${t("NEWAPPLICATION.typeCard.ASP.title")}`}
            subTitle={`${t("NEWAPPLICATION.typeCard.ASP.subTitle")}`}
            value="ASP"
            name="type_of_card"
            id={cardId}
            onChecked={handleCheckedValue}
            activeClass={checkedValue === "ASP" ? true : false}
            used={used}
            limit={limit}
          />
        </div>
      )}
      {cardName === "CMAP" && (
        <div className="col-md-6 col-sm-6 col-12 mt-3">
          <RadioButtonImage
            extraClass={
              checkedValue === "CMAP" ? "containerHover" : "imageGreen"
            }
            header={`${t("NEWAPPLICATION.typeCard.CMAP.title")}`}
            subTitle={`${t("NEWAPPLICATION.typeCard.CMAP.subTitle")}`}
            value="CMAP"
            name="type_of_card"
            id={cardId}
            onChecked={handleCheckedValue}
            activeClass={checkedValue === "CMAP" ? true : false}
            used={used}
            limit={limit}
          />
        </div>
      )}
      {cardName === "AAIAP" && (
        <div className="col-md-6 col-sm-6 col-12 mt-3 mb-1">
          <RadioButtonImage
            extraClass={checkedValue === "AAIAP" ? "containerHover" : "imageYellow"}
            header={`${t("NEWAPPLICATION.typeCard.AAIAP.title")}`}
            subTitle={`${t("NEWAPPLICATION.typeCard.AAIAP.subTitle")}`}
            value="AAIAP"
            name="type_of_card"
            id={cardId}
            onChecked={handleCheckedValue}
            activeClass={checkedValue === "AAIAP" ? true : false}
            used={used}
            limit={limit}
          />
        </div>
      )}
    </>
  );

  // const vehicleCard = (
  //   <>
  //     <div className="col-md-6 col-sm-6 col-12 mt-3 mb-1">
  //       <RadioButtonImage
  //         extraClass={
  //           checkedValue === "AVP"
  //             ? "containerHover"
  //             : "imagePink"
  //         }
  //         header="AVP"
  //         subTitle="Air-side Vehicle Pass"
  //         value="AVP"
  //         name="type_of_card"
  //         onChecked={handleCheckedValue}
  //         activeClass={checkedValue === "AVP" ? true : false}
  //       />
  //     </div>
  //   </>
  // );

  return (
    <>
      <FormTitle
        header={`${t("NEWAPPLICATION.typeCard.title")}`}
        subTitle={`${t("NEWAPPLICATION.typeCard.subTitle")}`}
      />
      {checkedValue ? (
        <Stepper />
      ) : (
        <div className="underline-after-title"></div>
      )}
      <div className="row">
        {/* {personnelCard} */}
        {availableCards.length > 0 &&
          availableCards.map((card, index) => (
            <PersonnelCard
              cardName={card.name}
              cardId={card.id}
              key={index}
              used={card.used}
              limit={card.limit}
            />
          ))}

        {/* 3rd row 
        <div className="col-md-6 col-sm-6 col-12 mt-3 mb-1">
          <RadioButtonImage
            extraClass={
              checkedValue === "OTPP"
                ? "containerHover"
                : "imagePink"
            }
            header="OTPP"
            subTitle="One Time Protocol Pass"
            value="OTPP"
            name="type_of_card"
            onChecked={handleCheckedValue}
            activeClass={checkedValue === "OTPP" ? true : false}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-12 mt-3">
          <RadioButtonImage
            extraClass={
              checkedValue === "OTVP"
                ? "containerHover"
                : "imageYellow"
            }
            header="OTVP"
            subTitle="One Time Visitor Pass"
            value="OTVP"
            name="type_of_card"
            onChecked={handleCheckedValue}
            activeClass={checkedValue === "OTVP" ? true : false}
          />
        </div>*/}
        {/* 4th row 
        <div className="col-md-6 col-sm-6 col-12 mt-3">
          <RadioButtonImage
            extraClass={
              checkedValue === "OTMP"
                ? "containerHover"
                : "imageViolet"
            }
            header="OTMP"
            subTitle="One Time Maintenance Pass"
            value="OTMP"
            name="type_of_card"
            onChecked={handleCheckedValue}
            activeClass={checkedValue === "OTMP" ? true : false}
          />
        </div>*/}
      </div>
      {checkedValue ? (
        <StepperShift />
      ) : (
        // <StepperShift backDisabled />
        <StepperShift nextDisabled />
        // <StepperShift backDisabled nextDisabled />
      )}
    </>
  );
};

export default CardType;
