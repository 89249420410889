import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changePreviousState,
  changeActiveApplicationPart,
  popupActive,
  popupInActive,
  loaderActive,
  loaderInActive,
  popupVariety,
  resetApplicationState,
  resetApplicationData,
} from "../../../../../../redux/actions";
import useHttp from "../../../../../../Http/useHttp";
import { useAuth } from "../../../../../../Authentication/AuthContext";
import userImage from "../../../../../../assets/images/defaultUser.jpg";
import RadioLabel from "../../../../../../Utilities/RadioLabel/RadioLabel";
import FormTitle from "../../Utilities/FormTitle/FormTitle";
import Stepper from "../../Utilities/Stepper/Stepper";

// import DisabledInput from "../../Utilities/DisabledInput/DisabledInput";
// import DisableAuthorizationArea from "../../Utilities/DisableAuthorizationArea/DisableAuthorizationArea";
// import TextInputAreaDisable from "../../Utilities/TextInputAreaDisable/TextInputAreaDisable";

import InputField from "../../../../../../Utilities/InputField/InputField";
import AuthorizationArea from "../../../../../../Utilities/AuthorizationArea/AuthorizationArea";
import TextInputArea from "../../../../../../Utilities/TextInputArea/TextInputArea";
import Selector from "../../../../../../Utilities/Selector/Selector";
// import PDFAttachment from "../../../../../../Utilities/PDFAttachment/PDFAttachment";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArchive,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

// custom Style
import "./ConfirmApplication.css";

const ConfirmApplication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [unlawful, setUnlawful] = useState("");
  const [violated, setViolated] = useState("");
  const [blacklisted, setBlacklisted] = useState("");
  const [isOk, setIsOk] = useState(false);

  const api = useHttp("authorized");
  const { logout } = useAuth();

  const passType = useSelector(
    (state) => state.newApplicationData.newApplication.partA.passType
  );

  const draftId = useSelector(
    (state) => state.newApplicationData.newApplication.partA.draftId
  );

  const myApplication = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  const availableSites = useSelector(
    (state) => state.newApplicationData.newApplication.partA.availableSites
  );
  let allSites = [
    {
      label: `Select The Required Airport`,
      value: "",
    },
  ];
  const formattedSiteValue = availableSites.map((site) => ({
    label: `${site.name} (${site.acronym})`,
    value: site.id,
  }));
  allSites = [...allSites, ...formattedSiteValue];

  const CmapCategories = [
    {
      label: `Select Required CMAP Category`,
      value: "",
    },
    {
      label: `Pilot (Schedule Passenger Operation)`,
      value: "P-SPO",
    },
    {
      label: `Pilot (Helicopter)`,
      value: "P-H",
    },
    {
      label: `Pilot (Flight Cargo)`,
      value: "P-FC",
    },
    {
      label: `Cabin Crew (Schedule Passenger Operation)`,
      value: "CC-SPO",
    },
    {
      label: `Flying School Instructor (FSI)`,
      value: "FSI",
    },
    {
      label: `Flying School Trainee (FST)`,
      value: "FST",
    },
  ];

  const authorizationTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.typeAuthorization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `National Aviation Security (AVSEC) Inspector`,
      value: "Security_AVSEC",
    },
    {
      label: `National Aviation Safety (ANS) Inspector`,
      value: "Safety_ANS",
    },
    {
      label: `National Aviation Safety (OPS) Inspector`,
      value: "Safety_OPS",
    },
    {
      label: `National Aviation Safety (AGA) Inspector`,
      value: "Safety_AGA",
    },
    {
      label: `National Aviation Safety (OPS/PEL) Inspector`,
      value: "Safety_OPS_PEL",
    },
    {
      label: `National Aviation Safety (AIR) Inspector`,
      value: "Safety_AIR",
    },
  ];

  const natureOrganization = [
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.placeholder"
      )}`,
      value: "",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.govt"
      )}`,
      value: "GOVT",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.apbe"
      )}`,
      value: "APBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.atbe"
      )}`,
      value: "ATBE",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.ehci"
      )}`,
      value: "EHCI",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.cotn"
      )}`,
      value: "COTN",
    },
    {
      label: `${t(
        "NEWAPPLICATION.detailsEmployment.natureOrganization.options.prvt"
      )}`,
      value: "PRVT",
    },
  ];

  useEffect(() => {
    switch (passType) {
      case "ASP":
        dispatch(changeActiveApplicationPart("Part F"));
        break;
      case "CMAP":
        dispatch(changeActiveApplicationPart("Part F"));
        break;

      default:
        dispatch(changeActiveApplicationPart("Part D"));
        break;
    }
  }, [passType, dispatch]);

  useEffect(() => {
    if (unlawful === "no" && violated === "no" && blacklisted === "no")
      setIsOk(true);
    else setIsOk(false);
  }, [unlawful, violated, blacklisted]);

  const issueTypeNotInitial = (
    <>
      <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
        <InputField
          type="text"
          label={`Existing AvSecID`}
          name="existingPassNumber"
          disable
          defaultValue={myApplication.partA.existingPassNumber}
        />
      </div>

      {myApplication.partA.passType === "CMAP" && (
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <Selector
            label="Existing CMAP Type"
            placeholder="Select One"
            listItem={CmapCategories}
            disable
            defaultValue={
              myApplication.partA.existingCMAPcategory
                ? myApplication.partA.existingCMAPcategory
                : ""
            }
          />
        </div>
      )}

      {myApplication.partA.passType === "ASP" && (
        <>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.typeIssueSelected.colorOfCard.label"
              )}`}
              name="colorOfPass"
              disable
              defaultValue={myApplication.partA.colorOfPass}
            />
          </div>
        </>
      )}

      {(myApplication.partA.passType === "ASP" ||
        myApplication.partA.passType === "NAP") && (
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
          <AuthorizationArea isDisable checkboxes={myApplication.partA} />
        </div>
      )}

      {myApplication.partA.issueType === "Re-issue (Lost /Stolen)" && (
        <>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.typeIssueSelected.gdNumber.label")}`}
              name="gdNumber"
              // placeholder="64166116165"
              disable
              defaultValue={myApplication.partA.gdNumber}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.typeIssueSelected.dateOfEntry.label"
              )}`}
              name="gdEntry"
              // placeholder="2021-12-26"
              disable
              defaultValue={myApplication.partA.gdEntry}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.typeIssueSelected.policeStationName.label"
              )}`}
              name="psName"
              // placeholder="Badda"
              disable
              defaultValue={myApplication.partA.psName}
            />
          </div>
        </>
      )}
    </>
  );

  const PartC_NIAC = (
    <>
      <div className="confirm-header-title-text mt-5">
        {`${t("NEWAPPLICATION.confirmApplication.header.partC")}`}
      </div>
      <hr />
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.organizationName.label"
            )}`}
            name="organizationName"
            // placeholder="Government"
            disable
            defaultValue={myApplication.partC.organizationName}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            name="natureOfOrganization"
            placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.natureOfOrganization}
          /> */}
          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            listItem={natureOrganization}
            defaultValue={
              myApplication.partC.natureOfOrganization
                ? myApplication.partC.natureOfOrganization
                : ""
            }
            disable
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.divisionName.label"
            )}`}
            name="division_name"
            // placeholder="Enter Your Division Name"
            disable
            defaultValue={myApplication.partC.divisionName}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              myApplication.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            // placeholder="Enter Your Designation"
            disable
            defaultValue={myApplication.partC.designation}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="current_position"
            // placeholder="Enter Your Position"
            disable
            defaultValue={myApplication.partC.currentPosition}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
            name="date_Of_joining_in_CAAB"
            // placeholder="DD/MM/YY"
            disable
            defaultValue={myApplication.partC.dateOfJoining}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            // placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.typeOfEmployment}
          />
        </div>
        {myApplication.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              disabled
              defaultValue={myApplication.partC.typeOfEmploymentOther}
            />
          </div>
        )}
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeAuthorization.label"
            )}`}
            name="typeOfAuthorization"
            // placeholder="National Aviation Safety Inspector"
            disable
            defaultValue={myApplication.partC.typeOfAuthorization}
          /> */}

          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeAuthorization.label"
            )}`}
            listItem={authorizationTypes}
            disable
            defaultValue={myApplication.partC.typeOfAuthorization}
          />
        </div>

        {myApplication.partC.typeOfAuthorization !== "Security_AVSEC" && (
          <>
            <div className="col-12 mt-3">
              <TextInputArea
                label={`${t(
                  "NEWAPPLICATION.detailsEmployment.typeAuthorization.specify"
                )}`}
                name="typeOfAuthorizationSafety"
                disabled
                defaultValue={myApplication.partC.typeOfAuthorizationSafety}
              />
            </div>
          </>
        )}
      </div>
    </>
  );

  const PartC_NAP = (
    <>
      <div className="confirm-header-title-text mt-5">
        {`${t("NEWAPPLICATION.confirmApplication.header.partC")}`}
      </div>
      <hr />
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.organizationName.label"
            )}`}
            name="organizationName"
            // placeholder="Government"
            disable
            defaultValue={myApplication.partC.organizationName}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            name="natureOfOrganization"
            placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.natureOfOrganization}
          /> */}
          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            listItem={natureOrganization}
            defaultValue={
              myApplication.partC.natureOfOrganization
                ? myApplication.partC.natureOfOrganization
                : ""
            }
            disable
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.divisionName.label"
            )}`}
            name="division_name"
            // placeholder="Enter Your Division Name"
            disable
            defaultValue={myApplication.partC.divisionName}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              myApplication.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            // placeholder="Enter Your Designation"
            disable
            defaultValue={myApplication.partC.designation}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="current_position"
            // placeholder="Enter Your Position"
            disable
            defaultValue={myApplication.partC.currentPosition}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
            name="date_Of_joining_in_CAAB"
            // placeholder="DD/MM/YY"
            disable
            defaultValue={myApplication.partC.dateOfJoining}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            // placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.typeOfEmployment}
          />
        </div>
        {myApplication.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              disabled
              defaultValue={myApplication.partC.typeOfEmploymentOther}
            />
          </div>
        )}
        <div className="col-12 mt-3">
          <TextInputArea
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.describeNatureDuty.label"
            )}`}
            name="natureOfDutyDescription"
            disabled
            defaultValue={myApplication.partC.natureOfDutyDescription}
          />
        </div>
        {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {t("NEWAPPLICATION.confirmApplication.approvedChairmanLabel")}
            </label>
            <li className="file-upload-view-item">
              <span className="file-upload-view__content ms-3">
                <FontAwesomeIcon
                  className="file-upload-view__icon"
                  icon={faFileArchive}
                />
                <p className="file-upload-view__text ms-2">
                  {myApplication.partC.natureOfDutyDescriptionFile
                    ? myApplication.partC.natureOfDutyDescriptionFile
                    : "============== NOT UPLOADED =============="}
                </p>
              </span>
            </li>
          </div>
        </div> */}
        {/* <PDFAttachment
          fileLink={
            myApplication.partC.natureOfDutyDescriptionFile
              ? myApplication.partC.natureOfDutyDescriptionFile
              : ""
          }
          filePlaceholder="Approved Office Order"
        /> */}
      </div>
    </>
  );

  const PartC_ASP = (
    <>
      <div className="confirm-header-title-text mt-5">
        {`${t("NEWAPPLICATION.confirmApplication.header.partC")}`}
      </div>
      <hr />
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.organizationName.label"
            )}`}
            name="organizationName"
            // placeholder="Government"
            disable
            defaultValue={myApplication.partC.organizationName}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            name="natureOfOrganization"
            placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.natureOfOrganization}
          /> */}

          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            listItem={natureOrganization}
            defaultValue={
              myApplication.partC.natureOfOrganization
                ? myApplication.partC.natureOfOrganization
                : ""
            }
            disable
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              myApplication.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            // placeholder="Enter Your Designation"
            disable
            defaultValue={myApplication.partC.designation}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="current_position"
            // placeholder="Enter Your Position"
            disable
            defaultValue={myApplication.partC.currentPosition}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            // placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.typeOfEmployment}
          />
        </div>
        {myApplication.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              disabled
              defaultValue={myApplication.partC.typeOfEmploymentOther}
            />
          </div>
        )}
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.natureDuty.label")}`}
            name="natureOfDuty"
            // placeholder="Operational"
            disable
            defaultValue={myApplication.partC.natureOfDuty}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.locationPrimaryDuty.label"
            )}`}
            name="locationOfDuty"
            // placeholder="Airside"
            disable
            defaultValue={myApplication.partC.locationOfDuty}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
            )}`}
            name="scopeOfOperation"
            // placeholder="International and Domestic"
            disable
            defaultValue={myApplication.partC.scopeOfOperation}
          />
        </div>
      </div>
    </>
  );

  const PartC_CMAP = (
    <>
      <div className="confirm-header-title-text mt-5">
        {`${t("NEWAPPLICATION.confirmApplication.header.partC")}`}
      </div>
      <hr />
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.organizationName.label"
            )}`}
            name="organizationName"
            // placeholder="Government"
            disable
            defaultValue={myApplication.partC.organizationName}
          />
        </div>
        <div className="col-12 mt-3">
          {/* <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            name="natureOfOrganization"
            placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.natureOfOrganization}
          /> */}

          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            listItem={natureOrganization}
            defaultValue={
              myApplication.partC.natureOfOrganization
                ? myApplication.partC.natureOfOrganization
                : ""
            }
            disable
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              myApplication.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            // placeholder="Enter Your Designation"
            disable
            defaultValue={myApplication.partC.designation}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="current_position"
            // placeholder="Enter Your Position"
            disable
            defaultValue={myApplication.partC.currentPosition}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            // placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.typeOfEmployment}
          />
        </div>
        {myApplication.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              disabled
              defaultValue={myApplication.partC.typeOfEmploymentOther}
            />
          </div>
        )}
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.scopeOperation.label"
            )}`}
            name="scopeOfOperation"
            // placeholder="International and Domestic"
            disable
            defaultValue={myApplication.partC.scopeOfOperation}
          />
        </div>
      </div>
    </>
  );

  const PartC_AAIAP = (
    <>
      <div className="confirm-header-title-text mt-5">
        {`${t("NEWAPPLICATION.confirmApplication.header.partC")}`}
      </div>
      <hr />
      <div className="row">
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.organizationName.label"
            )}`}
            name="organizationName"
            // placeholder="Government"
            disable
            defaultValue={myApplication.partC.organizationName}
          />
        </div>
        <div className="col-12 mt-3">
          <Selector
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.natureOrganization.label"
            )}`}
            listItem={natureOrganization}
            defaultValue={
              myApplication.partC.natureOfOrganization
                ? myApplication.partC.natureOfOrganization
                : ""
            }
            disable
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.divisionName.label"
            )}`}
            name="division_name"
            // placeholder="Enter Your Division Name"
            disable
            defaultValue={myApplication.partC.divisionName}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={
              myApplication.partC.natureOfOrganization === "COTN"
                ? "Company Name"
                : `${t("NEWAPPLICATION.detailsEmployment.designation.label")}`
            }
            name="designation"
            // placeholder="Enter Your Designation"
            disable
            defaultValue={myApplication.partC.designation}
          />
        </div>
        <div className="col-6 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.currentPosition.label"
            )}`}
            name="current_position"
            // placeholder="Enter Your Position"
            disable
            defaultValue={myApplication.partC.currentPosition}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.detailsEmployment.dateJoining.label")}`}
            name="date_Of_joining_in_CAAB"
            // placeholder="DD/MM/YY"
            disable
            defaultValue={myApplication.partC.dateOfJoining}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t(
              "NEWAPPLICATION.detailsEmployment.typeEmployment.label"
            )}`}
            name="typeOfEmployment"
            // placeholder="Contractual"
            disable
            defaultValue={myApplication.partC.typeOfEmployment}
          />
        </div>
        {myApplication.partC.typeOfEmployment === "Other (Specify)" && (
          <div className="col-12 mt-3">
            <TextInputArea
              label={`${t(
                "NEWAPPLICATION.detailsEmployment.typeEmployment.specify"
              )}`}
              name="typeOfEmploymentOther"
              disabled
              defaultValue={myApplication.partC.typeOfEmploymentOther}
            />
          </div>
        )}
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`Type of Assignment`}
            name="typeOfAssignment"
            disable
            defaultValue={myApplication.partC.typeOfAssignment}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`Category of Investigator`}
            name="investigatorCategory"
            disable
            defaultValue={myApplication.partC.investigatorCategory}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`Investigator ID Number`}
            name="investigatorID"
            disable
            defaultValue={myApplication.partC.investigatorID}
          />
        </div>
      </div>
    </>
  );

  const submitApplication = async () => {
    // console.log(`Unlawful: ${unlawful}`);
    // console.log(`Violated: ${violated}`);
    // console.log(`Blacklisted: ${blacklisted}`);

    if (isOk) {
      // console.log("Perform req");
      dispatch(loaderActive());
      try {
        const resp = await api.get(`draft/submit/${draftId}`);
        // console.log(resp.data);
        dispatch(
          popupVariety({
            popupVariety: "success",
            popupTitle: "Your Application has been Submitted Successfully",
            popupSubtitle: "Please Check Your Dashboard for Continuous Updates",
          })
        );
        dispatch(loaderInActive());
        dispatch(popupActive());
        dispatch(resetApplicationState());
        dispatch(resetApplicationData());
      } catch (error) {
        console.log(error.response);
        dispatch(
          popupVariety({
            popupVariety: "error",
            popupTitle: "Application Error",
            popupSubtitle: error.response.data.message,
          })
        );
        dispatch(loaderInActive());
        dispatch(popupActive());
        setTimeout(() => {
          dispatch(popupInActive());
        }, 2000);
        if (error.response.data.status === 401) logout();
      }
    }

    // =============== Not Used ===============
    // dispatch(loaderActive());
    // dispatch(
    //   popupVariety({
    //     popupVariety: "success",
    //     popupTitle: "Your Application has been Submitted Successfully",
    //     popupSubtitle: "Please Check Your Dashboard for Continuous Updates",
    //   })
    // );
    // setTimeout(() => {
    //   dispatch(loaderInActive());
    //   dispatch(popupActive());
    //   // dispatch(resetApplicationState());
    //   // dispatch(resetApplicationData());
    // }, 1000);
  };

  const createDate = () => {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  const AssetContainer = ({ fieldLabel, assetUrl, assetName }) => {
    return (
      <>
        <label className="form-label upload-picture-field-label_2">
          {fieldLabel}
        </label>

        <div
          className="file-upload-asset"
          onClick={() => (assetUrl ? window.open(assetUrl) : null)}
        >
          <FontAwesomeIcon
            className={`mx-3 iconArchive`}
            icon={faFileArchive}
          />
          <div className="assetFileName">
            {assetUrl ? assetName : "==== NOT UPLOADED ===="}
          </div>
          {assetUrl && (
            <FontAwesomeIcon
              className={`mx-3 iconDownload`}
              icon={faCloudDownloadAlt}
            />
          )}
        </div>
      </>
    );
  };

  const AssetNIAC = () => {
    return (
      <div className="assetContainer">
        <div className="leftPortion col-6  pb-1">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className="image-styling img-fluid img-thumbnail rounded"
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        <div className="rightPortion col-6  pb-1">
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter From Concerned Division"
              assetName="Forwarding Letter"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of NID Card"
              assetName="NID Card"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Letter of Authorization as CAAB Inspector form CAAB Chairman"
              assetName="Letter of Authorization"
              assetUrl={myApplication.partC.typeOfAuthorizationSafetyFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetNAP = () => {
    return (
      <div className="assetContainer">
        <div className="leftPortion col-6  pb-1">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className="image-styling img-fluid img-thumbnail rounded"
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        <div className="rightPortion col-6  pb-1">
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter From Concerned Division"
              assetName="Forwarding Letter"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of NID Card"
              assetName="NID Card"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Letter of Job Assignment form CAAB Chairman"
              assetName="Letter of Job Assignment"
              assetUrl={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetAAIAP = () => {
    return (
      <div className="assetContainer">
        <div className="leftPortion col-6  pb-1">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className="image-styling img-fluid img-thumbnail rounded"
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>
        </div>

        <div className="rightPortion col-6  pb-1">
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter From Concerned Division"
              assetName="Forwarding Letter"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of NID Card"
              assetName="NID Card"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Letter of Job Assignment form CAAB Chairman / Ministry"
              assetName="REF Chairman / Ministry"
              assetUrl={myApplication.partC.natureOfDutyDescriptionFile}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetCMAP = () => {
    return (
      <div className="assetContainer">
        <div className="leftPortion col-6  pb-1">
          <div className="picture-field">
            <label className="form-label upload-picture-field-label_2">
              {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
            </label>
            <img
              src={
                myApplication.partB.profilePic
                  ? myApplication.partB.profilePic
                  : userImage
              }
              className="image-styling img-fluid img-thumbnail rounded"
              alt="user-pic"
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel={`${t("NEWAPPLICATION.confirmApplication.GSATFile")}`}
              assetName="GSAT Certificate"
              assetUrl={myApplication.partB.gsatFile}
            />
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="gsatValidTill"
              disable
              defaultValue={myApplication.partB.gsatValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Driving License"
              assetName="Copy of Driving License"
              assetUrl={myApplication.attachments.drivingLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Existing Pass Card in Case of Renewal"
              assetName="Copy of Existing Pass Card"
              assetUrl={myApplication.attachments.existingPermit}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Certificate from FSR Division"
              assetName="Certificate from FSR Division"
              assetUrl={myApplication.attachments.certificateFSR}
            />
          </div>
        </div>

        <div className="rightPortion col-6  pb-1">
          <AssetContainer
            fieldLabel={`${t(
              "NEWAPPLICATION.confirmApplication.policeVerificationFile"
            )}`}
            assetName="Police Verification"
            assetUrl={myApplication.partB.policeVerificationFile}
          />

          <div className="mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.validTill.label"
              )}`}
              name="policeVerificationValidTill"
              disable
              defaultValue={myApplication.partB.policeVerificationValidTill}
              isMandatory={false}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Forwarding Letter from Employer"
              assetName="Forwarding Letter from Employer"
              assetUrl={myApplication.attachments.forwardingLetter}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of National Id"
              assetName="Copy of National Id"
              assetUrl={myApplication.attachments.copyOfNID}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Crew Id"
              assetName="Copy of Crew Id"
              assetUrl={myApplication.attachments.employeeId}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Crew License"
              assetName="Copy of Crew License"
              assetUrl={myApplication.attachments.employeeLicense}
            />
          </div>

          <div className="mt-3">
            <AssetContainer
              fieldLabel="Copy of Agreement in Case of Contractual Employment"
              assetName="Copy of Agreement"
              assetUrl={myApplication.attachments.copyOfAgreement}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssetASP = () => {
    return (
      <>
        {/* =================== For Govt Employee =================== */}
        {myApplication.attachments.selectedAttachmentGroupASP === "GovtEmp" && (
          <div className="assetContainer">
            <div className="leftPortion col-6  pb-1">
              <div className="picture-field">
                <label className="form-label upload-picture-field-label_2">
                  {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                </label>
                <img
                  src={
                    myApplication.partB.profilePic
                      ? myApplication.partB.profilePic
                      : userImage
                  }
                  className="image-styling img-fluid img-thumbnail rounded"
                  alt="user-pic"
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel={`${t(
                    "NEWAPPLICATION.confirmApplication.GSATFile"
                  )}`}
                  assetName="GSAT Certificate"
                  assetUrl={myApplication.partB.gsatFile}
                />
              </div>

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="gsatValidTill"
                  disable
                  defaultValue={myApplication.partB.gsatValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Driving License"
                  assetName="Copy of Driving License"
                  assetUrl={myApplication.attachments.drivingLicense}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Existing Pass Card in Case of Renewal"
                  assetName="Copy of Existing Pass Card"
                  assetUrl={myApplication.attachments.existingPermit}
                />
              </div>
            </div>

            <div className="rightPortion col-6  pb-1">
              <AssetContainer
                fieldLabel="Forwarding letter from the employer authorized official with
                confirmation statement that the initial background checks and the
                police verification checks has been conducted on the person"
                assetName="Forwarding letter from employer"
                assetUrl={myApplication.attachments.forwardingLetter}
              />

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of NID"
                  assetName="Copy of NID"
                  assetUrl={myApplication.attachments.copyOfNID}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Employee Id"
                  assetName="Copy of Employee Id"
                  assetUrl={myApplication.attachments.employeeId}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Posting order/ office order for the job assignment at airport"
                  assetName="Posting order/ office order"
                  assetUrl={myApplication.attachments.officeOrder}
                />
              </div>
            </div>
          </div>
        )}

        {/* =================== For Non Govt Employee =================== */}
        {myApplication.attachments.selectedAttachmentGroupASP ===
          "NonGovtEmp" && (
          <div className="assetContainer">
            <div className="leftPortion col-6  pb-1">
              <div className="picture-field">
                <label className="form-label upload-picture-field-label_2">
                  {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                </label>
                <img
                  src={
                    myApplication.partB.profilePic
                      ? myApplication.partB.profilePic
                      : userImage
                  }
                  className="image-styling img-fluid img-thumbnail rounded"
                  alt="user-pic"
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel={`${t(
                    "NEWAPPLICATION.confirmApplication.GSATFile"
                  )}`}
                  assetName="GSAT Certificate"
                  assetUrl={myApplication.partB.gsatFile}
                />
              </div>

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="gsatValidTill"
                  disable
                  defaultValue={myApplication.partB.gsatValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Driving License"
                  assetName="Copy of Driving License"
                  assetUrl={myApplication.attachments.drivingLicense}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Existing Pass Card in Case of Renewal"
                  assetName="Copy of Existing Pass Card"
                  assetUrl={myApplication.attachments.existingPermit}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Valid AOC/ Permission and/ or Flight Schedule that is approved by CAAB"
                  assetName="Valid AOC/ Permission and/ or Flight Schedule"
                  assetUrl={myApplication.attachments.validAOC}
                />
              </div>
            </div>

            <div className="rightPortion col-6  pb-1">
              <AssetContainer
                fieldLabel={`${t(
                  "NEWAPPLICATION.confirmApplication.policeVerificationFile"
                )}`}
                assetName="Police Verification"
                assetUrl={myApplication.partB.policeVerificationFile}
              />

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="policeVerificationValidTill"
                  disable
                  defaultValue={myApplication.partB.policeVerificationValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Forwarding letter from the employer"
                  assetName="Forwarding letter from employer"
                  assetUrl={myApplication.attachments.forwardingLetter}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of NID"
                  assetName="Copy of NID"
                  assetUrl={myApplication.attachments.copyOfNID}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Employee Id"
                  assetName="Copy of Employee Id"
                  assetUrl={myApplication.attachments.employeeId}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Complete Background Check By Employer"
                  assetName="Background Check"
                  assetUrl={myApplication.attachments.backgroundCheck}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Agreement with CAAB or other Organization "
                  assetName="Agreement with CAAB or other Organization"
                  assetUrl={myApplication.attachments.copyOfAgreement}
                />
              </div>
              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Work Order"
                  assetName="Copy of Work Order"
                  assetUrl={myApplication.attachments.copyOfWorkOrder}
                />
              </div>
            </div>
          </div>
        )}

        {/* =================== For Foreigners =================== */}
        {myApplication.attachments.selectedAttachmentGroupASP ===
          "Foreigners" && (
          <div className="assetContainer">
            <div className="leftPortion col-6  pb-1">
              <div className="picture-field">
                <label className="form-label upload-picture-field-label_2">
                  {`${t("NEWAPPLICATION.confirmApplication.pictureLabel")}`}
                </label>
                <img
                  src={
                    myApplication.partB.profilePic
                      ? myApplication.partB.profilePic
                      : userImage
                  }
                  className="image-styling img-fluid img-thumbnail rounded"
                  alt="user-pic"
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel={`${t(
                    "NEWAPPLICATION.confirmApplication.GSATFile"
                  )}`}
                  assetName="GSAT Certificate"
                  assetUrl={myApplication.partB.gsatFile}
                />
              </div>

              <div className="mt-3">
                <InputField
                  type="text"
                  label={`${t(
                    "NEWAPPLICATION.applicantsInformation.validTill.label"
                  )}`}
                  name="gsatValidTill"
                  disable
                  defaultValue={myApplication.partB.gsatValidTill}
                  isMandatory={false}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Existing Pass Card in Case of Renewal"
                  assetName="Copy of Existing Pass Card"
                  assetUrl={myApplication.attachments.existingPermit}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Recommendation from Bangladesh Investment Development Agency
                (BIDA)"
                  assetName="Recommendation from BIDA"
                  assetUrl={myApplication.attachments.recommendationBIDA}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of Visa"
                  assetName="Copy of Visa"
                  assetUrl={myApplication.attachments.copyOfVisa}
                />
              </div>
            </div>

            <div className="rightPortion col-6  pb-1">
              <AssetContainer
                fieldLabel="Copy of Passport"
                assetName="Copy of Passport"
                assetUrl={myApplication.attachments.copyOfPassport}
              />

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Copy of note verbale issued by the mission"
                  assetName="Copy of note verbale"
                  assetUrl={myApplication.attachments.noteVerbale}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Recommendation of concerned ministry or authority"
                  assetName="Recommendation of ministry"
                  assetUrl={myApplication.attachments.recommendationOfMinistry}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Security Clearance form Ministry of Home Affairs"
                  assetName="Security Clearance MOHA"
                  assetUrl={myApplication.attachments.securityClearanceMOHA}
                />
              </div>

              <div className="mt-3">
                <AssetContainer
                  fieldLabel="Recommendation of Ministry of Foreign affairs by endorsing the
              application form"
                  assetName="Recommendation of Ministry of Foreign affairs"
                  assetUrl={myApplication.attachments.recommendationMOFA}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const PartA = () => {
    return (
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.confirmApplication.organizationCode")}`}
            name="organization_code"
            disable
            defaultValue={myApplication.partA.orgCode}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.confirmApplication.typeCard")}`}
            name="type_card"
            disable
            defaultValue={myApplication.partA.passType}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <InputField
            type="text"
            label={`${t("NEWAPPLICATION.confirmApplication.typeIssue")}`}
            name="type_issue"
            disable
            defaultValue={myApplication.partA.issueType}
          />
        </div>
        {passType === "ASP" && (
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <Selector
              label="Selected Airport"
              listItem={allSites}
              disable
              defaultValue={
                myApplication.partA.siteId ? myApplication.partA.siteId : ""
              }
            />
          </div>
        )}
        {myApplication.partA.issueType !== "Initial" && issueTypeNotInitial}
      </div>
    );
  };

  const PartB = () => {
    return (
      <>
        <div className="row">
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.applicantsInformation.name.label")}`}
              name="name"
              // placeholder="Abdul Karim"
              disable
              defaultValue={myApplication.partB.name}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.displayName.label"
              )}`}
              name="displayName"
              disable
              defaultValue={myApplication.partB.displayName}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.fatherName.label"
              )}`}
              name="fatherName"
              // placeholder="Hasnat Karim"
              disable
              defaultValue={myApplication.partB.fathersName}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.motherName.label"
              )}`}
              name="motherName"
              // placeholder="Rabeya Begum"
              disable
              defaultValue={myApplication.partB.mothersName}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.spouseName.label"
              )}`}
              name="spouseName"
              disable
              defaultValue={
                myApplication.partB.spouseName
                  ? myApplication.partB.spouseName
                  : ""
              }
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.nationality.label"
              )}`}
              name="nationality"
              // placeholder="Bangldeshi"
              disable
              defaultValue={myApplication.partB.nationality}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.nationalIDNumber.label"
              )}`}
              name="national_id_number"
              // placeholder="21684168454621"
              disable
              defaultValue={myApplication.partB.nid}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.dateOfBirth.label"
              )}`}
              name="dob"
              // placeholder="01/02/1989"
              disable
              defaultValue={myApplication.partB.dob}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.mobileNumber.label"
              )}`}
              name="mobile_number"
              // placeholder="01973321654"
              disable
              defaultValue={myApplication.partB.mobile}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.gender.label"
              )}`}
              name="gender"
              // placeholder="Male"
              disable
              defaultValue={myApplication.partB.gender}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.bloodGroup.label"
              )}`}
              name="blood_group"
              // placeholder="A+"
              disable
              defaultValue={myApplication.partB.bloodGroup}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.applicantsInformation.emailAddress.label"
              )}`}
              name="email_address"
              // placeholder="abdulkarim1989@gmail.com"
              disable
              defaultValue={myApplication.partB.email}
            />
          </div>
        </div>
        <div className="confirm-header-secondary-text mt-4">
          {t("NEWAPPLICATION.addressInformation.permanentAddressHeader")}
        </div>
        <div className="row">
          <div className="col-12 mt-2">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.addressInformation.houseNumber.label"
              )}`}
              name="house_number"
              // placeholder="12/13 CE"
              disable
              defaultValue={myApplication.partB.address.permanentAddress.house}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.addressInformation.street.label")}`}
              name="road_no"
              // placeholder="32"
              disable
              defaultValue={myApplication.partB.address.permanentAddress.street}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.addressInformation.city.label")}`}
              name="city"
              // placeholder="Dhaka"
              disable
              defaultValue={myApplication.partB.address.permanentAddress.city}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.addressInformation.policeStation.label"
              )}`}
              name="police_station"
              // placeholder="Dhaka"
              disable
              defaultValue={myApplication.partB.address.permanentAddress.ps}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.addressInformation.district.label")}`}
              name="district"
              // placeholder="Dhaka"
              disable
              defaultValue={myApplication.partB.address.permanentAddress.dist}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.addressInformation.postCode.label")}`}
              name="post_code"
              // placeholder="1200"
              disable
              defaultValue={myApplication.partB.address.permanentAddress.zip}
            />
          </div>
        </div>
        <div className="confirm-header-secondary-text mt-4">
          {t("NEWAPPLICATION.addressInformation.presentAddressHeader")}
        </div>
        <div className="row">
          <div className="col-12 mt-2">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.addressInformation.houseNumber.label"
              )}`}
              name="house_number"
              // placeholder="12/13 CE"
              disable
              defaultValue={myApplication.partB.address.presentAddress.house}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.addressInformation.street.label")}`}
              name="road_no"
              // placeholder="32"
              disable
              defaultValue={myApplication.partB.address.presentAddress.street}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.addressInformation.city.label")}`}
              name="city"
              // placeholder="Dhaka"
              disable
              defaultValue={myApplication.partB.address.presentAddress.city}
            />
          </div>
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.addressInformation.policeStation.label"
              )}`}
              name="police_station"
              // placeholder="Dhaka"
              disable
              defaultValue={myApplication.partB.address.presentAddress.ps}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.addressInformation.district.label")}`}
              name="district"
              // placeholder="Dhaka"
              disable
              defaultValue={myApplication.partB.address.presentAddress.dist}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.addressInformation.postCode.label")}`}
              name="post_code"
              // placeholder="1200"
              disable
              defaultValue={myApplication.partB.address.presentAddress.zip}
            />
          </div>
        </div>
      </>
    );
  };

  const ForeignNationals = () => {
    return (
      <>
        <div className="row">
          <div className="col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.foreignNational.nationality.label")}`}
              name="nationality"
              // placeholder="British"
              disable
              defaultValue={
                myApplication.partE.foreignNationality
                  ? myApplication.partE.foreignNationality
                  : "N/A"
              }
            />
          </div>
          {passType === "ASP" && (
            <div className="col-12 mt-3">
              <InputField
                type="text"
                label={`${t(
                  "NEWAPPLICATION.foreignNational.natureAssignment.label"
                )}`}
                name="natureOfAssignmentBD"
                disable
                defaultValue={
                  myApplication.partE.natureOfAssignmentBD
                    ? myApplication.partE.natureOfAssignmentBD
                    : "N/A"
                }
              />
            </div>
          )}
        </div>
        <div className="confirm-header-tertiary-text mt-4">
          {`${t("NEWAPPLICATION.foreignNational.travelDocumentHeader")}`}
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.foreignNational.passportNumber.label"
              )}`}
              name="number"
              // placeholder="Z0236304"
              disable
              defaultValue={
                myApplication.partE.passportDocumentNumber
                  ? myApplication.partE.passportDocumentNumber
                  : "N/A"
              }
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.foreignNational.placeIssue.label")}`}
              name="placeOfIssue"
              // placeholder="Dhaka"
              disable
              defaultValue={
                myApplication.partE.placeOfIssue
                  ? myApplication.partE.placeOfIssue
                  : "N/A"
              }
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.foreignNational.expiryDate.label")}`}
              name="passportDocumentExpiryDate"
              // placeholder="2021-12-29"
              disable
              defaultValue={
                myApplication.partE.passportDocumentExpiryDate
                  ? myApplication.partE.passportDocumentExpiryDate
                  : "N/A"
              }
            />
          </div>
        </div>
        <div className="confirm-header-tertiary-text mt-4">
          {`${t("NEWAPPLICATION.foreignNational.visaHeader")}`}
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.foreignNational.typeVisa.label")}`}
              name="typeOfVisa"
              // placeholder="Diplomatic"
              disable
              defaultValue={
                myApplication.partE.typeOfVisa
                  ? myApplication.partE.typeOfVisa
                  : "N/A"
              }
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.foreignNational.validityVisa.label"
              )}`}
              name="visaExpiryDate"
              // placeholder="2022-01-02"
              disable
              defaultValue={
                myApplication.partE.visaExpiryDate
                  ? myApplication.partE.visaExpiryDate
                  : "N/A"
              }
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t("NEWAPPLICATION.foreignNational.visaNumber.label")}`}
              name="visaNumber"
              // placeholder="Z0236304"
              disable
              defaultValue={
                myApplication.partE.visaNumber
                  ? myApplication.partE.visaNumber
                  : "N/A"
              }
            />
          </div>
        </div>
        <div className="confirm-header-tertiary-text mt-4">
          {`${t("NEWAPPLICATION.foreignNational.workPermitHeader")}`}
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.foreignNational.workPermitNumber.label"
              )}`}
              name="number"
              // placeholder="Z0236304"
              disable
              defaultValue={
                myApplication.partE.workPermitNumber
                  ? myApplication.partE.workPermitNumber
                  : "N/A"
              }
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
            <InputField
              type="text"
              label={`${t(
                "NEWAPPLICATION.foreignNational.validityWorkPermit.label"
              )}`}
              name="workPermitExpiryDate"
              // placeholder="2021-12-27"
              disable
              defaultValue={
                myApplication.partE.workPermitExpiryDate
                  ? myApplication.partE.workPermitExpiryDate
                  : "N/A"
              }
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <FormTitle
        header={`${t("NEWAPPLICATION.confirmApplication.title")}`}
        subTitle={`${t("NEWAPPLICATION.confirmApplication.subTitle")}`}
      />
      <Stepper />

      {/*============================================= Part A =============================================*/}
      <>
        <div className="confirm-header-title-text mt-2">
          {t("NEWAPPLICATION.confirmApplication.header.partA")}
        </div>
        <hr />
        <PartA />
      </>

      {/*============================================= Part B =============================================*/}
      <>
        <div className="confirm-header-title-text mt-5">
          {t("NEWAPPLICATION.confirmApplication.header.partB")}
        </div>
        <hr />
        <PartB />
      </>

      {/*============================================= Part C =============================================*/}
      <>
        {passType === "NIAC" && <>{PartC_NIAC}</>}
        {passType === "NAP" && <>{PartC_NAP}</>}
        {passType === "ASP" && <>{PartC_ASP}</>}
        {passType === "CMAP" && <>{PartC_CMAP}</>}
        {passType === "AAIAP" && <>{PartC_AAIAP}</>}
      </>

      {/*============================================= Part E Foreigner Details =============================================*/}
      {(passType === "CMAP" || passType === "ASP") && (
        <>
          <div className="confirm-header-title-text mt-5">
            {t("NEWAPPLICATION.confirmApplication.header.partD")}
          </div>
          <hr />
          <ForeignNationals />
        </>
      )}

      {/*============================================= Part Attachment and Declaration =============================================*/}
      <>
        <div className="confirm-header-title-text mt-5">
          {t("NEWAPPLICATION.confirmApplication.header.partF.part")}{" "}
          {passType === "CMAP" || passType === "ASP"
            ? `${t("NEWAPPLICATION.confirmApplication.header.partF.F")}`
            : `${t("NEWAPPLICATION.confirmApplication.header.partF.D")}`}{" "}
          : {t("NEWAPPLICATION.confirmApplication.header.partF.text")} &amp;
          Attachments
        </div>
        <hr />
        <div className="row">
          <div className="col-12 mt-3">
            {passType === "NIAC" && <AssetNIAC />}
            {passType === "NAP" && <AssetNAP />}
            {passType === "ASP" && <AssetASP />}
            {passType === "CMAP" && <AssetCMAP />}
            {passType === "AAIAP" && <AssetAAIAP />}
          </div>

          <div className="col-12 mt-5">
            <div className="mb-2">
              <h5 className="group-list-text-header">
                {t("NEWAPPLICATION.applicantsDeclaration.headerText.text")}{" "}
                {passType}:
              </h5>
              <ul className="p-0 ps-3 mt-4">
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item1")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t(
                    "NEWAPPLICATION.applicantsDeclaration.textList.item2.text"
                  )}{" "}
                  {passType === "NIAC"
                    ? `${t(
                        "NEWAPPLICATION.applicantsDeclaration.textList.item2.textInspector"
                      )}`
                    : `${t(
                        "NEWAPPLICATION.applicantsDeclaration.textList.item2.textPurposes"
                      )}`}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item3")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item4")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item5")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item6")}
                </li>
                <li className="pb-1 p-0 m-0 border-bottom-0 group-list-text">
                  {t("NEWAPPLICATION.applicantsDeclaration.textList.item7")}
                </li>
              </ul>
            </div>
          </div>

          {/* ======== Self Declaration ======== */}
          <div
            className={`col-12 mt-5 p-4 ${
              isOk ? "self__declaration_green" : "self__declaration_red"
            }`}
          >
            <div>
              <h5 className="group-list-text-header">
                Self Declaration<span className="text-danger">*</span>
              </h5>
              <ul className="p-0 ps-3 mt-4">
                <li className="mb-3">
                  Have you ever been involved in any unlawful activities in any
                  airport in Bangladesh?
                  <div className="row mt-1">
                    <div className="col-4">
                      <RadioLabel
                        label={`Yes`}
                        id="unlawful"
                        name="unlawful"
                        value="yes"
                        execute={setUnlawful}
                        defaultValue={unlawful}
                      />
                    </div>
                    <div className="col-4">
                      <RadioLabel
                        label={`No`}
                        id="unlawful"
                        name="unlawful"
                        value="no"
                        execute={setUnlawful}
                        defaultValue={unlawful}
                      />
                    </div>
                  </div>
                </li>
                <li className="mb-3">
                  Have you ever violated any airport, immigration and customs
                  rules in any airport in Bangladesh?
                  <div className="row mt-1">
                    <div className="col-4">
                      <RadioLabel
                        label={`Yes`}
                        id="violated"
                        name="violated"
                        value="yes"
                        execute={setViolated}
                        defaultValue={violated}
                      />
                    </div>
                    <div className="col-4">
                      <RadioLabel
                        label={`No`}
                        id="violated"
                        name="violated"
                        value="no"
                        execute={setViolated}
                        defaultValue={violated}
                      />
                    </div>
                  </div>
                </li>
                <li className="mb-3">
                  Have you ever been blacklisted by Civil Aviation Authority of
                  Bangladesh for any misconduct in any airport in Bangladesh?
                  <div className="row mt-1">
                    <div className="col-4">
                      <RadioLabel
                        label={`Yes`}
                        id="blacklisted"
                        name="blacklisted"
                        value="yes"
                        execute={setBlacklisted}
                        defaultValue={blacklisted}
                      />
                    </div>
                    <div className="col-4">
                      <RadioLabel
                        label={`No`}
                        id="blacklisted"
                        name="blacklisted"
                        value="no"
                        execute={setBlacklisted}
                        defaultValue={blacklisted}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* ======== Sign and Date ======== */}
          <div className="col-12 mt-5">
            <div className="signature-image-styling">
              <img
                src={myApplication.partD.signature}
                alt="signature-user"
                className="signature-image-display"
              />
            </div>
            <p className="p-0 m-0 authorized-signatory-text mt-3">
              {t("NEWAPPLICATION.ApplicantDetail.date")}:{" "}
              <span className="authorized-signatory-text-bold">
                {createDate()}
              </span>
            </p>
          </div>

          {/* ======== Buttons ======== */}
          <div className="col-12 my-5">
            <div className="btn-area-confirm float-end">
              <button
                type="button"
                className="btn btn-confirm-custom btn-custom-orange"
                onClick={() => dispatch(changePreviousState(1))}
              >
                {t("NEWAPPLICATION.confirmApplication.editBtn")}
              </button>
              <button
                type="button"
                disabled={!isOk}
                className="btn btn-confirm-custom btn-custom-blue"
                onClick={submitApplication}
              >
                {t("NEWAPPLICATION.confirmApplication.confirmBtn")}
              </button>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ConfirmApplication;
