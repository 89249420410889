import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { formFillUp, resetFormFillUp } from "../../redux/actions";
import useHttp from "../../Http/useHttp";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

// custom style
import "./OTPForm.css";

function OTPForm(props) {
  const { loading, popUp, popUpErrorText, userId } = props;
  const dispatch = useDispatch();
  const token = useSelector(
    (state) => state.resetPasswordReducer.formData.tempToken
  );
  const submittedForm = useSelector(
    (state) => state.resetPasswordReducer.formData
  );
  const registrationData = useSelector(
    (state) => state.changeFormReducer.formData
  );

  const [counter, setCounter] = useState(90);
  const api = useHttp();
  const { t } = useTranslation();

  const [OTP, setOTP] = useState(new Array(6).fill(""));

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleChange = (e, index) => {
    const element = e.target;
    if (isNaN(element.value)) return false;
    setOTP([...OTP.map((d, idx) => (idx === index ? element.value : d))]);
    //Focus next input
    if (element.value !== "") {
      e.preventDefault();
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleRemove = (e, index) => {
    const element = e.target;
    if (isNaN(element.value)) return false;
    setOTP([...OTP.map((d, idx) => (idx === index ? element.value : d))]);
    //Focus next input
    if (e.keyCode === 8 && e.currentTarget.value === "") {
      if (element.previousSibling) {
        element.previousSibling.focus();
        element.value = "";
      }
    }
  };

  const verifyOtpForRegistration = async (e) => {
    loading(true);
    e.preventDefault();
    const otpNumber = OTP.join("");
    const regFormData = {
      temp_user_id: userId,
      otp: otpNumber,
    };
    // console.log(regFormData);

    try {
      const resp = await api.post("verify-registration", regFormData);
      console.log(resp.data);

      dispatch(
        formFillUp({
          pageTitle: "Success",
          pageSubTitle: "Awesome",
          activeForm: "form4",
          formData: {
            otpNumber,
          },
        })
      );
      loading(false);
    } catch (err) {
      console.log(err.response);
      popUpErrorText(err.response.data.otp);
      loading(false);
      popUp(true);
      setTimeout(() => popUp(false), 1000);
    }
  };

  const verifyOtpForForgetPassword = async (e) => {
    // loading(true);
    e.preventDefault();
    const otpNumber = OTP.join("");

    const formData = {
      otp: otpNumber,
    };

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        const resp = await api.post("verify-forget-password", formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log(resp.data.verifiedToken);

        dispatch(
          resetFormFillUp({
            pageTitle: `${t("RESETPASSWORD.header")}`,
            pageSubTitle: `${t("RESETPASSWORD.secondaryHeader")}`,
            activeForm: "form3",
            formData: resp.data.verifiedToken,
          })
        );
        resolve();
      } catch (error) {
        console.log(error.response);
        reject(error.response.data.message);
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "OTP Verified Successfully",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const resendOTPforRegistration = async () => {
    // console.log("Under Construction");

    let formData = {
      mobile: `${registrationData.selectedCountryCode}${registrationData.mobile}`,
    };

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        setCounter(90);
        const resp = await api.post("resend-registration-otp", {
          temp_user_id: userId,
        });
        resolve();
      } catch (error) {
        console.log(error.response);
        reject(error.response.data.message);
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: `OTP Successfully Sent to ${formData.mobile.substr(
          0,
          5
        )}*****`,
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const resendOTPforForgetPassword = async () => {
    let value;

    let formData = {
      email: submittedForm.email,
      mobile: submittedForm.mobile,
    };

    submittedForm.email
      ? (value = submittedForm.email)
      : (value = submittedForm.mobile);

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        setCounter(90);
        const resp = await api.post("forget-password", formData);

        dispatch(
          resetFormFillUp({
            pageTitle: `${t("OTP.header")}`,
            pageSubTitle: `${t("OTP.secondaryHeader")}`,
            activeForm: "form2",
            formData: { ...formData, tempToken: resp.data.forgetPasswordToken },
          })
        );
        resolve();
      } catch (error) {
        console.log(error.response);
        reject(error.response.data.message);
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: `OTP Successfully Sent to ${value.substr(0, 5)}*****`,
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  return (
    <>
      <Form
        onSubmit={(e) => {
          props.for === "registration"
            ? verifyOtpForRegistration(e)
            : verifyOtpForForgetPassword(e);
        }}
      >
        <Form.Group className="mb-1">
          <Form.Label className="fw-bold">
            {t("OTP.verificationCode.label")}
          </Form.Label>

          <Row>
            <div className="col-12">
              <div className="otp-area">
                {OTP.map((data, index) => {
                  return (
                    <input
                      type="text"
                      className="otp-area-input"
                      maxLength="1"
                      size="1"
                      max="1"
                      pattern="[0-9]{1}"
                      required
                      key={index}
                      value={data}
                      onInput={(e) => handleChange(e, index)}
                      onKeyDown={(e) => handleRemove(e, index)}
                    />
                  );
                })}
              </div>
            </div>
          </Row>
          <p className="text-md">{t("OTP.verificationCode.subText")}</p>
        </Form.Group>

        <Button className="col-12 mt-3 fw-bold" variant="primary" type="submit">
          {t("OTP.nextBtn")}
        </Button>

        <div className="d-flex justify-content-center mt-2 fw-bold">
          <p className="text-md">{t("OTP.getCode")}</p>&nbsp;
          <p
            type="button"
            className="text-md text-md-underline"
            onClick={() => {
              if (counter === 0) {
                props.for === "registration"
                  ? resendOTPforRegistration()
                  : resendOTPforForgetPassword();
              }
            }}
          >
            {t("OTP.resend")} ({counter})
          </p>
        </div>
      </Form>
    </>
  );
}

export default OTPForm;
