const initialState = {
  name: "",
  designation: "",
  fathersName: "",
  mothersName: "",
  spouseName: "",
  nationality: "",
  dob: "",
  gender: "",
  nid: "",
  mobile: "",
  bloodGroup: "",
  email: "",
  profilePic: "",
  policeVerificationFile: "",
  policeVerificationFileName: "",
  policeVerificationValidTill: "",
  gsatFile: "",
  gsatFileName: "",
  gsatValidTill: "",
  address: {
    presentAddress: {
      house: "",
      street: "",
      city: "",
      ps: "",
      dist: "",
      zip: "",
    },
    permanentAddress: {
      house: "",
      street: "",
      city: "",
      ps: "",
      dist: "",
      zip: "",
    },
  },
  signature: "",
  organizationName: "",
  natureOfOrganization: "",
  // userId: "",
};

const settingsData = (state = initialState, action) => {
  let formData = action.payload;

  switch (action.type) {
    case "RESET_SETTINGS_DATA":
      return { ...initialState };

    case "SETTINGS_DATA":
      return {
        ...state,
        ...formData,
      };

    case "SETTINGS_PRESENT_ADDRESS":
      return {
        ...state,
        address: {
          ...state.address,
          presentAddress: {
            ...state.address.presentAddress,
            ...formData,
          },
        },
      };

    case "SETTINGS_PERMANENT_ADDRESS":
      return {
        ...state,
        address: {
          ...state.address,
          permanentAddress: {
            ...state.address.permanentAddress,
            ...formData,
          },
        },
      };

    case "SETTINGS_COPY_PERMANENT_TO_PRESENT":
      return {
        ...state,
        address: {
          ...state.address,
          presentAddress: {
            ...state.address.permanentAddress,
          },
        },
      };

    case "RESET_SETTINGS_PRESENT_ADDRESS":
      return {
        ...state,
        address: {
          ...state.address,
          presentAddress: initialState.address.permanentAddress,
        },
      };

    default:
      return state;
  }
};

export default settingsData;
