import React, { useState } from "react";

// custom style
import styles from "./InputField.module.css";

const InputField = ({
  label,
  type,
  placeholder,
  name,
  execute,
  defaultValue = "",
  disable = false,
  isMandatory = true,
  maxLen,
}) => {
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
    execute(e.target.value);
  };

  return (
    <>
      <div className={styles.inputArea}>
        <label className={`form-label ${styles.label}`}>{label}</label>
        {isMandatory && <span className="text-danger">*</span>}
        <input
          type={type}
          className={`form-control ${styles.input}`}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          value={value ? value : defaultValue ? defaultValue : ""}
          disabled={disable}
          maxLength={maxLen}
        />
      </div>
    </>
  );
};

export default InputField;
