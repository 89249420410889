import React from "react";

import { useTranslation } from "react-i18next";

// Custom Style
import styles from "./FormTitle.module.css";

const FormTitle = ({ header, subTitle }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.titleArea}>
      <div className={styles.starterText}>{t("NEWAPPLICATION.header")}</div>
      <div className={styles.headerText}>{header}</div>
      <div className={styles.subText}>{subTitle}</div>
    </div>
  );
};

export default FormTitle;
