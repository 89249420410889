import React from "react";
import DatePicker from "react-datepicker";

// custom style
import styles from "./DateInput.module.css";

const DateInput = ({
  label,
  execute,
  disable = false,
  defaultValue,
  isMandatory = true,
}) => {
  const handleChange = (e) => {
    const apiDate = new Date(e);

    const month =
      apiDate.getMonth() + 1 > 9
        ? apiDate.getMonth() + 1
        : `0${apiDate.getMonth() + 1}`;

    const date =
      apiDate.getDate() > 9 ? apiDate.getDate() : `0${apiDate.getDate()}`;

    // console.log(`${apiDate.getFullYear()}-${month}-${date}`);

    execute(`${apiDate.getFullYear()}-${month}-${date}`);
  };

  return (
    <>
      <div className={styles.area}>
        <label className={`form-label ${styles.label}`}>{label}</label>
        {isMandatory && <span className="text-danger">*</span>}
        {/* <input
          type="date"
          className={`form-control ${styles.input}`}
          placeholder={placeholder}
          value={value ? value : defaultValue}
          onChange={handleChange}
          disabled={disable}
        /> */}
        <DatePicker
          className={`form-control ${styles.input}`}
          selected={defaultValue ? new Date(defaultValue) : null}
          onChange={handleChange}
          placeholderText="eg: 05/04/1990"
          dateFormat={"dd/MM/yyyy"}
          disabled={disable}
        />
      </div>
    </>
  );
};

export default DateInput;
