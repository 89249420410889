import React, { useEffect, useState } from "react";
import FormTitle from "../../Utilities/FormTitle/FormTitle";
import Stepper from "../../Utilities/Stepper/Stepper";
import StepperShift from "../../Utilities/StepperShift/StepperShift";

import InputField from "../../../../../../Utilities/InputField/InputField";
import DateInput from "../../../../../../Utilities/DateInput/DateInput";
import Selector from "../../../../../../Utilities/Selector/Selector";
import RadioLabel from "../../../../../../Utilities/RadioLabel/RadioLabel";
// import PDFUpload from "../../../../../../Utilities/PDFUpload/PDFUpload";
// import ImageUpload from "../../../../../../Utilities/ImageUpload/ImageUpload";

// import FileUploader from "../../Utilities/FileUploader/FileUploader";
// import ImageUploader from "../../Utilities/ImageUploader/ImageUploader";

import { useDispatch, useSelector } from "react-redux";
import {
  applicationDataPartB,
  changeActiveApplicationPart,
} from "../../../../../../redux/actions";

import { useTranslation } from "react-i18next";

// custom Style
import styles from "./PersonalDetails.module.css";

const PersonalDetails = () => {
  const { t } = useTranslation();
  const myCurrentStates = useSelector(
    (state) => state.newApplicationData.newApplication
  );

  // const settingsData = useSelector((state) => state.settingsData);

  const dispatch = useDispatch();
  const [checkedValue, setCheckedValue] = useState(false);

  // const issueType = myCurrentStates.partA.issueType;
  // const draftId = myCurrentStates.partA.draftId;
  const partB = myCurrentStates.partB;

  useEffect(() => {
    partB.name &&
    partB.displayName &&
    partB.fathersName &&
    partB.mothersName &&
    // partB.spouseName &&
    partB.nationality &&
    partB.dob &&
    partB.gender &&
    partB.nid &&
    partB.mobile &&
    partB.bloodGroup &&
    partB.email
      ? // && partB.profilePic
        // && Object.keys(partB.policeVerification).length === 0
        setCheckedValue(true)
      : setCheckedValue(false);

    dispatch(changeActiveApplicationPart("Part B"));
  }, [partB, checkedValue, dispatch]);

  const bloodTypes = [
    {
      label: `${t(
        "NEWAPPLICATION.applicantsInformation.bloodGroup.placeholder"
      )}`,
      value: "",
    },
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
  ];

  // const textUploadPicture = (
  //   <>
  //     {t("NEWAPPLICATION.applicantsInformation.pictureAdviceText.upload")}{" "}
  //     <span className={styles.textBold}>
  //       {t("NEWAPPLICATION.applicantsInformation.pictureAdviceText.jpg")}
  //     </span>{" "}
  //     {t("NEWAPPLICATION.applicantsInformation.pictureAdviceText.or")}{" "}
  //     <span className={styles.textBold}>
  //       {t("NEWAPPLICATION.applicantsInformation.pictureAdviceText.png")}
  //     </span>{" "}
  //     {t("NEWAPPLICATION.applicantsInformation.pictureAdviceText.file")}{" "}
  //     <span className={styles.textBold}>
  //       {t("NEWAPPLICATION.applicantsInformation.pictureAdviceText.size")}.
  //     </span>
  //   </>
  // );

  // const textUploadPoliceVerification = (
  //   <>
  //     {t("SETTINGS.uploadForm.adviceTextPolice.upload")}{" "}
  //     <strong>{t("SETTINGS.uploadForm.adviceTextPolice.pdf")}</strong>{" "}
  //     {t("SETTINGS.uploadForm.adviceTextPolice.file")}
  //   </>
  // );

  // const textUploadGSAT = (
  //   <>
  //     {t("SETTINGS.uploadForm.adviceTextGSAT.upload")}{" "}
  //     <strong>{t("SETTINGS.uploadForm.adviceTextGSAT.pdf")}</strong>{" "}
  //     {t("SETTINGS.uploadForm.adviceTextGSAT.file")}
  //   </>
  // );

  // const nextStepDecider =
  //   issueType === "Initial" ? (
  //     <StepperShift decreaseBy={2} showDraft lastCompleted="B" backDisabled />
  //   ) : (
  //     <StepperShift showDraft lastCompleted="B" backDisabled />
  //   );

  // const previousStepDecider =
  //   issueType === "Initial" ? (
  //     <StepperShift decreaseBy={2} nextDisabled backDisabled />
  //   ) : (
  //     <StepperShift nextDisabled backDisabled />
  //   );

  const name = (inputData) => {
    dispatch(
      applicationDataPartB({
        name: inputData,
      })
    );
  };

  const displayName = (inputData) => {
    dispatch(
      applicationDataPartB({
        displayName: inputData,
      })
    );
  };

  const fathersName = (inputData) => {
    dispatch(
      applicationDataPartB({
        fathersName: inputData,
      })
    );
  };

  const mothersName = (inputData) => {
    dispatch(
      applicationDataPartB({
        mothersName: inputData,
      })
    );
  };

  const spouseName = (inputData) => {
    dispatch(
      applicationDataPartB({
        spouseName: inputData,
      })
    );
  };

  const nationality = (inputData) => {
    dispatch(
      applicationDataPartB({
        nationality: inputData,
      })
    );
  };

  const nid = (inputData) => {
    dispatch(
      applicationDataPartB({
        nid: inputData,
      })
    );
  };

  const dob = (inputData) => {
    dispatch(
      applicationDataPartB({
        dob: inputData,
      })
    );
  };

  // const gsatValidTill = (inputData) => {
  //   dispatch(
  //     applicationDataPartB({
  //       gsatValidTill: inputData,
  //     })
  //   );
  // };

  // const policeVerificationValidTill = (inputData) => {
  //   dispatch(
  //     applicationDataPartB({
  //       policeVerificationValidTill: inputData,
  //     })
  //   );
  // };

  const mobile = (inputData) => {
    dispatch(
      applicationDataPartB({
        mobile: inputData,
      })
    );
  };

  const email = (inputData) => {
    dispatch(
      applicationDataPartB({
        email: inputData,
      })
    );
  };

  const bloodGroup = (selectedData) => {
    dispatch(
      applicationDataPartB({
        bloodGroup: selectedData,
      })
    );
  };

  const gender = (selectedData) => {
    dispatch(
      applicationDataPartB({
        gender: selectedData,
      })
    );
  };

  // const policeVerification = (url) => {
  //   // console.log(url);
  //   if (url) {
  //     dispatch(
  //       applicationDataPartB({
  //         policeVerificationFile: url,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       applicationDataPartB({
  //         policeVerificationFile: "",
  //         policeVerificationValidTill: "",
  //       })
  //     );
  //   }
  // };

  // const GSAT = (url) => {
  //   // console.log(url);
  //   if (url) {
  //     dispatch(
  //       applicationDataPartB({
  //         gsatFile: url,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       applicationDataPartB({
  //         gsatFile: "",
  //         gsatValidTill: "",
  //       })
  //     );
  //   }
  // };

  // const profilePic = (url) => {
  //   // console.log(url);
  //   if (url) {
  //     dispatch(
  //       applicationDataPartB({
  //         profilePic: url,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       applicationDataPartB({
  //         profilePic: "",
  //       })
  //     );
  //   }
  // };

  return (
    <>
      <FormTitle
        header={`${t("NEWAPPLICATION.applicantsInformation.title")}`}
        subTitle={`${t("NEWAPPLICATION.applicantsInformation.subTitle")}`}
      />
      <Stepper />
      <div className={styles.headerTitle}>
        {t("NEWAPPLICATION.applicantsInformation.pageHeader")}
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <InputField
            defaultValue={partB.name ? partB.name : ""}
            execute={name}
            type="text"
            label={`${t("NEWAPPLICATION.applicantsInformation.name.label")}`}
            name="name"
            placeholder={`${t(
              "NEWAPPLICATION.applicantsInformation.name.placeholder"
            )}`}
            maxLen="30"
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={partB.displayName ? partB.displayName : ""}
            execute={displayName}
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.displayName.label"
            )}`}
            name="displayName"
            placeholder={`${t(
              "NEWAPPLICATION.applicantsInformation.displayName.placeholder"
            )}`}
            maxLen="16"
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={partB.fathersName ? partB.fathersName : ""}
            execute={fathersName}
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.fatherName.label"
            )}`}
            name="father_name"
            placeholder={`${t(
              "NEWAPPLICATION.applicantsInformation.fatherName.placeholder"
            )}`}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={partB.mothersName ? partB.mothersName : ""}
            execute={mothersName}
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.motherName.label"
            )}`}
            name="mother_name"
            placeholder={`${t(
              "NEWAPPLICATION.applicantsInformation.motherName.placeholder"
            )}`}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={partB.spouseName ? partB.spouseName : ""}
            isMandatory={false}
            execute={spouseName}
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.spouseName.label"
            )}`}
            name="spouse_name"
            placeholder={`${t(
              "NEWAPPLICATION.applicantsInformation.spouseName.placeholder"
            )}`}
          />
        </div>
        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                defaultValue={partB.nationality ? partB.nationality : ""}
                execute={nationality}
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.nationality.label"
                )}`}
                name="nationality"
                placeholder={`${t(
                  "NEWAPPLICATION.applicantsInformation.nationality.placeholder"
                )}`}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                defaultValue={partB.nid ? partB.nid : ""}
                execute={nid}
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.nationalIDNumber.label"
                )}`}
                name="national_id_number"
                placeholder={`${t(
                  "NEWAPPLICATION.applicantsInformation.nationalIDNumber.placeholder"
                )}`}
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <DateInput
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.dateOfBirth.label"
                )}`}
                execute={dob}
                defaultValue={partB.dob ? partB.dob : ""}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                defaultValue={partB.mobile ? partB.mobile : ""}
                execute={mobile}
                type="text"
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.mobileNumber.label"
                )}`}
                name="mobile_number"
                placeholder={`${t(
                  "NEWAPPLICATION.applicantsInformation.mobileNumber.placeholder"
                )}`}
                disable
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <div className={styles.genderArea}>
                <label className={`form-label ${styles.genderLabel}`}>
                  {t("NEWAPPLICATION.applicantsInformation.gender.label")}
                </label>
                <span className="text-danger">*</span>
                <div className="row mt-1">
                  <div className="col-md-4 col-12">
                    <RadioLabel
                      label={`${t(
                        "NEWAPPLICATION.applicantsInformation.gender.male"
                      )}`}
                      id="gender"
                      name="gender"
                      value="Male"
                      defaultValue={partB.gender ? partB.gender : ""}
                      execute={gender}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <RadioLabel
                      label={`${t(
                        "NEWAPPLICATION.applicantsInformation.gender.female"
                      )}`}
                      id="gender"
                      name="gender"
                      value="Female"
                      defaultValue={partB.gender ? partB.gender : ""}
                      execute={gender}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <RadioLabel
                      label={`${t(
                        "NEWAPPLICATION.applicantsInformation.gender.other"
                      )}`}
                      id="gender"
                      name="gender"
                      value="Others"
                      defaultValue={partB.gender ? partB.gender : ""}
                      execute={gender}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <Selector
                label={`${t(
                  "NEWAPPLICATION.applicantsInformation.bloodGroup.label"
                )}`}
                placeholder={`${t(
                  "NEWAPPLICATION.applicantsInformation.bloodGroup.placeholder"
                )}`}
                listItem={bloodTypes}
                defaultValue={partB.bloodGroup ? partB.bloodGroup : ""}
                execute={bloodGroup}
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <InputField
            defaultValue={partB.email ? partB.email : ""}
            execute={email}
            type="text"
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.emailAddress.label"
            )}`}
            name="email_address"
            placeholder={`${t(
              "NEWAPPLICATION.applicantsInformation.emailAddress.placeholder"
            )}`}
          />
        </div>

        {/* =============== Should be added to attachment =============== */}
        {/* <div className="col-12 mt-3">
          <div className={styles.uploadArea}>
            <label className={`form-label ${styles.uploadLabel}`}>
              {`${t(
                "NEWAPPLICATION.applicantsInformation.uploadPicture.label"
              )}`}
            </label>
            <ImageUpload
              instruction={textUploadPicture}
              execute={profilePic}
              endpoint="draft/update-image"
              formData={{ draftId: draftId, profilePic: "" }}
              dataKey="profilePic"
              defaultValue={partB.profilePic}
            />
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className={styles.uploadArea}>
            <label className={`form-label ${styles.uploadLabel}`}>
              {`${t(
                "NEWAPPLICATION.applicantsInformation.uploadPoliceVerification.label"
              )}`}
            </label>
            <PDFUpload
              instruction={textUploadPoliceVerification}
              execute={policeVerification}
              endpoint="draft/update-policeVerification"
              formData={{ draftId: draftId, policeVerification: "" }}
              dataKey="policeVerification"
              fileName="Police Verification File"
              defaultValue={partB.policeVerificationFile}
            />
          </div>
        </div>
        <div className="col-12 mt-3">
          <DateInput
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={policeVerificationValidTill}
            defaultValue={
              partB.policeVerificationValidTill
                ? partB.policeVerificationValidTill
                : ""
            }
          />
        </div>
        <div className="col-12 mt-3">
          <div className={styles.uploadArea}>
            <label className={`form-label ${styles.uploadLabel}`}>
              {`${t("NEWAPPLICATION.applicantsInformation.uploadGSAT.label")}`}
            </label>

            <PDFUpload
              // instruction="Upload A Single .pdf File Should Not Be More Than 5MB And Should Contain Applicant's GSAT Certificate."
              instruction={textUploadGSAT}
              execute={GSAT}
              endpoint="draft/update-gsat"
              formData={{ draftId: draftId, gsat: "" }}
              dataKey="gsat"
              fileName="GSAT File"
              defaultValue={partB.gsatFile}
            />
          </div>
        </div>
        <div className="col-12 mt-3">
          <DateInput
            label={`${t(
              "NEWAPPLICATION.applicantsInformation.validTill.label"
            )}`}
            execute={gsatValidTill}
            defaultValue={partB.gsatValidTill ? partB.gsatValidTill : ""}
          />
        </div> */}
      </div>

      {/* <StepperShift /> */}
      {/* {checkedValue ? nextStepDecider : previousStepDecider} */}
      {checkedValue ? (
        <StepperShift showDraft lastCompleted="B" backDisabled />
      ) : (
        <StepperShift nextDisabled backDisabled />
      )}
    </>
  );
};

export default PersonalDetails;
