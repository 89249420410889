import React from "react";
import { Link } from "react-router-dom";
import "./terms&Conditions.css";

function TermsAndConditions() {
  return (
    <div className="tnc_container">
      <div className="text-center mt-2">
        <span className="text-md">
          Terms &amp; Conditions under construction, Please{" "}
          <Link to="/">Click Here</Link> To Go Back
        </span>
      </div>
    </div>
  );
}

export default TermsAndConditions;
