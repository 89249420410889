import React from "react";
import "./loader.css";
import loader from "../../assets/images/loader.gif";

function Loader() {
  return (
    <div className="loader-main">
      <img src={loader} alt="loading..." className="loader-content" />
    </div>
  );
}

export default Loader;
