const initialState = {
  newApplication: {
    partA: {
      completed: "A",
      passType: "",
      passTypeId: "",
      issueType: "",
      existingPassNumber: "",
      colorOfPass: "",
      A: false,
      B: false,
      C: false,
      D: false,
      E: false,
      T: false,
      R: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      gdNumber: "",
      gdEntry: "",
      psName: "",
      draftId: null,
      orgCode: "",
      orgId: "",
      availableCards: [],
      availableSites: [],
      applicationId: "",
      createdAt: "",
      siteId: "",
      existingCMAPcategory: "",
    },
    partB: {
      name: "",
      displayName: "",
      fathersName: "",
      mothersName: "",
      spouseName: "",
      nationality: "",
      dob: "",
      gender: "",
      nid: "",
      mobile: "",
      bloodGroup: "",
      email: "",
      profilePic: "",
      policeVerificationFile: "",
      policeVerificationFileName: "",
      policeVerificationValidTill: "",
      gsatFile: "",
      gsatFileName: "",
      gsatValidTill: "",
      address: {
        presentAddress: {
          house: "",
          street: "",
          city: "",
          ps: "",
          dist: "",
          zip: "",
        },
        permanentAddress: {
          house: "",
          street: "",
          city: "",
          ps: "",
          dist: "",
          zip: "",
        },
      },
    },
    partC: {
      divisionName: "",
      designation: "",
      currentPosition: "",
      dateOfJoining: "",
      typeOfEmployment: "",
      typeOfEmploymentOther: "",
      typeOfAuthorization: "",
      typeOfAuthorizationSafety: "",
      typeOfAuthorizationSafetyFile: "",
      typeOfAuthorizationSafetyFileName: "",
      natureOfDuty: "",
      organizationName: "",
      natureOfOrganization: "",
      scopeOfOperation: "",
      natureOfDutyDescription: "",
      natureOfDutyDescriptionFile: "",
      natureOfDutyDescriptionFileName: "",
      locationOfDuty: "",
      typeOfAssignment: "",
      investigatorCategory: "",
      investigatorID: "",
    },
    partD: {
      signature: "",
    },
    partE: {
      foreignNationality: "",
      natureOfAssignmentBD: "",
      passportDocumentNumber: "",
      placeOfIssue: "",
      passportDocumentExpiryDate: "",
      typeOfVisa: "",
      visaExpiryDate: "",
      visaNumber: "",
      workPermitNumber: "",
      workPermitExpiryDate: "",
    },
    partF: {
      requiredColorOfCard: "",
      enhancedSRA: false,
      enhancedSRAspecification: "",
      internationalBaggage: false,
      airCargo: false,
      passengerHolding: false,
      betweenImmigrationPreBoarding: false,
      internationalImmigration: false,
      internationalCustoms: false,
      terminalEntry: false,
      ATCoperation: false,
      authorityToEscort: false,
      authorityToDrive: false,
      authorityToCarry: false,
      policeVerificationExempted: false,
      typeOfPass: "",
      validity: "",
      category: "",
      dasSignature: "",
      dasName: "",
      dasDesignation: "",
      dasVerificationDate: "",
      adminSignature: "",
      replaceAnyone: "",
    },
    attachments: {
      forwardingLetter: "",
      copyOfNID: "",
      employeeId: "",
      employeeLicense: "",
      officeOrder: "",
      drivingLicense: "",
      existingPermit: "",
      backgroundCheck: "",
      validAOC: "",
      copyOfWorkOrder: "",
      copyOfAgreement: "",
      copyOfPassport: "",
      copyOfVisa: "",
      recommendationMOFA: "",
      noteVerbale: "",
      recommendationOfMinistry: "",
      recommendationBIDA: "",
      securityClearanceMOHA: "",
      certificateFSR: "",
      selectedAttachmentGroupASP: "",
    },
  },
};

const newApplicationData = (state = initialState, action) => {
  let formData = action.payload;

  switch (action.type) {
    case "RESET_APPLICATION_DATA":
      return {
        ...state,
        newApplication: initialState.newApplication,
      };

    case "SET_APPLICATION_DATA":
      return {
        ...state,
        newApplication: {
          ...formData,
        },
      };

    case "APPLICATION_DATA_A":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partA: { ...state.newApplication.partA, ...formData },
        },
      };

    case "RESET_APPLICATION_DATA_CARD_CHANGED":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partA: {
            ...state.newApplication.partA,
            passType: "",
            issueType: "",
            existingPassNumber: "",
            colorOfPass: "",
            gdNumber: "",
            gdEntry: "",
            psName: "",
          },
          partB: initialState.newApplication.partB,
          partC: initialState.newApplication.partC,
          partD: initialState.newApplication.partD,
          partE: initialState.newApplication.partE,
          partF: initialState.newApplication.partF,
        },
      };

    case "APPLICATION_DATA_B":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partB: { ...state.newApplication.partB, ...formData },
        },
      };

    case "APPLICATION_DATA_B_PRESENT_ADDRESS":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partB: {
            ...state.newApplication.partB,
            address: {
              ...state.newApplication.partB.address,
              presentAddress: {
                ...state.newApplication.partB.address.presentAddress,
                ...formData,
              },
            },
          },
        },
      };

    case "APPLICATION_DATA_B_PERMANENT_ADDRESS":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partB: {
            ...state.newApplication.partB,
            address: {
              ...state.newApplication.partB.address,
              permanentAddress: {
                ...state.newApplication.partB.address.permanentAddress,
                ...formData,
              },
            },
          },
        },
      };

    case "COPY_PERMANENT_TO_PRESENT":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partB: {
            ...state.newApplication.partB,
            address: {
              ...state.newApplication.partB.address,
              presentAddress:
                state.newApplication.partB.address.permanentAddress,
            },
          },
        },
      };

    case "RESET_PRESENT_ADDRESS":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partB: {
            ...state.newApplication.partB,
            address: {
              ...state.newApplication.partB.address,
              presentAddress:
                initialState.newApplication.partB.address.presentAddress,
            },
          },
        },
      };

    case "APPLICATION_DATA_C":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partC: { ...state.newApplication.partC, ...formData },
        },
      };

    case "APPLICATION_DATA_D":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partD: { ...state.newApplication.partD, ...formData },
        },
      };

    case "APPLICATION_DATA_E":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partE: { ...state.newApplication.partE, ...formData },
        },
      };

    case "APPLICATION_DATA_F":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          partF: { ...state.newApplication.partF, ...formData },
        },
      };

    case "APPLICATION_DATA_ATTACHMENT":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          attachments: { ...state.newApplication.attachments, ...formData },
        },
      };

    case "APPLICATION_DATA_ATTACHMENT_RESET":
      return {
        ...state,
        newApplication: {
          ...state.newApplication,
          attachments: initialState.newApplication.attachments,
        },
      };

    default:
      return state;
  }
};

export default newApplicationData;
