import React, { useRef } from "react";
import DropzoneUploaded from "../DropzoneUploaded/DropzoneUploaded";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faArrowUp,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

// Custom Style
import styles from "./FileUploader.module.css";

export default function FileUploader({
  textAdvise,
  // statusCheckbox,
  checkText,
  fileType,
}) {
  const { t } = useTranslation();
  const discardFile = useRef();
  const openUploadDialog = useRef();

  // const checkArea = (
  //   <div className={`${styles.checkboxArea} mt-1`}>
  //     <div className={styles.checkboxArea}>
  //       <div className="form-check">
  //         <input
  //           className="form-check-input"
  //           type="checkbox"
  //           value=""
  //           id="flexCheckChecked"
  //         />
  //       </div>
  //     </div>
  //     <div className={styles.checkboxText}>{checkText}</div>
  //   </div>
  // );

  return (
    <div className="row">
      <div className={`col-md-6 col-sm-6 col-12 ${styles.leftDiv}`}>
        <DropzoneUploaded
          removeUpload={discardFile}
          upload={openUploadDialog}
          fileType={fileType}
        />
      </div>
      <div className={`col-md-6 col-sm-6 col-12 ${styles.rightDiv}`}>
        <div className={styles.uploadRight}>
          <div className={styles.content}>
            <FontAwesomeIcon icon={faInfoCircle} className={styles.iconSize} />
            <div className={styles.text}>{textAdvise}</div>
          </div>
          {/* {statusCheckbox ? checkArea : null} */}
          <div className={`${styles.btnArea} mt-3`}>
            <button
              type="button"
              className={`btn btn-primary ${styles.button} mt-2`}
              onClick={() => openUploadDialog.current()}
            >
              <FontAwesomeIcon icon={faArrowUp} className="me-2" />{" "}
              {t("SETTINGS.uploadBtn")}
            </button>
            <button
              type="button"
              className={`btn btn-danger ${styles.button} ${styles.leftSpace} mt-2`}
              onClick={() => discardFile.current()}
            >
              <FontAwesomeIcon icon={faWindowClose} className="me-2" />{" "}
              {t("SETTINGS.discardBtn")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
