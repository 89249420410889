import React from "react";
import { useTranslation } from "react-i18next";

// custom style
import styles from "./SettingTitle.module.css";

const SettingTitle = ({ header, subTitle }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.titleArea}>
      <div className={styles.starterText}>{t("SETTINGS.header")}</div>
      <div className={styles.headerText}>{header}</div>
      <div className={styles.subTitleText}>{subTitle}</div>
    </div>
  );
};

export default SettingTitle;
