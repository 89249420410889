import React, { useState } from "react";

// custom style
import styles from "./CheckBox.module.css";

const CheckBox = ({
  label,
  name,
  disable,
  defaultValue = false,
  execute,
  index = 0,
}) => {
  // isChecked,
  const [selectedValue, setSelectedValue] = useState(false);

  const handleChange = (e) => {
    setSelectedValue(e.target.checked);
    execute(e.target.checked, index);
  };

  return (
    <>
      <div className={`form-check ${styles.checkField}`}>
        <input
          className={`form-check-input ${styles.checkFieldInput}`}
          name={name}
          type="checkbox"
          disabled={disable}
          checked={selectedValue ? selectedValue : defaultValue}
          onChange={handleChange}
        />
        <label className={`form-check-label ${styles.checkFieldLabel}`}>
          {label}
        </label>
      </div>
    </>
  );
};

export default CheckBox;
