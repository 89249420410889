import React from "react";
import useHttp from "../../../../../../Http/useHttp";
import AddressInfo from "../../../../../../Utilities/AddressInfo/AddressInfo";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../../Authentication/AuthContext";

import { useTranslation } from "react-i18next";

// custom style
import styles from "./AddressPage.module.css";

const AddressPage = () => {
  const { t } = useTranslation();
  //   const [checkedValue, setCheckedValue] = useState(false);
  //   const isFormValid = (value) => setCheckedValue(value);
  const api = useHttp("authorized");
  // const navigate = useNavigate();
  const { logout } = useAuth();
  const newAddress = useSelector((state) => state.settingsData.address);

  const addressUpdate = async () => {
    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        // const resp =
        await api.post("settings/address-update", newAddress);
        // console.log("Address update success");
        // console.log(newAddress);
        const savedUser = JSON.parse(localStorage.getItem("user"));
        const updatedUser = {
          ...savedUser,
          address: newAddress,
        };
        // console.log(updatedUser);
        localStorage.setItem("user", JSON.stringify(updatedUser));
        resolve();
      } catch (error) {
        console.log(error);
        if (error.response) {
          if (error.response.data.status === 401) {
            reject("Not Authorized Please Login");
            logout();
          } else reject("Operation Failed");
        } else reject("Unknown Error");
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Address Updated",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );

    // try {
    //   const resp = await api.post("settings/address-update", newAddress);
    //   // console.log("Address update success");
    //   // console.log(newAddress);
    //   const savedUser = JSON.parse(localStorage.getItem("user"));
    //   const updatedUser = {
    //     ...savedUser,
    //     address: newAddress,
    //   };
    //   // console.log(updatedUser);
    //   localStorage.setItem("user", JSON.stringify(updatedUser));

    //   toast.success(resp.data.response, {
    //     autoClose: 3000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } catch (error) {
    //   // console.log(error.response);
    //   if (error.response.data.status === 401) {
    //     // localStorage.clear();
    //     // navigate("/");
    //     logout();
    //   } else {
    //     toast.error(error.response.data.message, {
    //       autoClose: 3000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: false,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // }
  };

  return (
    <>
      <div className={styles.marginPermanent}>
        <AddressInfo
          checkState={false}
          checkText={null}
          header={`${t("SETTINGS.addressForm.permanentAddressHeader")}`}
          //   validation={isFormValid}
        />
      </div>
      <div className={styles.marginPresent}>
        <AddressInfo
          checkState={true}
          checkText={`${t("SETTINGS.addressForm.similarCheckedText")}`}
          header={`${t("SETTINGS.addressForm.presentAddressHeader")}`}
          //   validation={isFormValid}
        />
      </div>
      <div className="row mb-4">
        <div className="col-12 mt-5">
          <button
            type="submit"
            className={`btn ${styles.button} float-end`}
            onClick={() => addressUpdate()}
          >
            {t("SETTINGS.updateBtn")}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddressPage;
