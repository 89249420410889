import React, { useState, useEffect } from "react";
// import Logo from "../assets/images/caabLogo.png";
import AvSecID from "../assets/images/avsecID.png";

import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
import { useDispatch } from "react-redux";
import { resetSettingsData } from "../redux/actions";
import Background from "./Background/Background";
import Popup from "../components/Popup/Popup";
import Loader from "./Loader/Loader";
// import useHttp from "../Http/useHttp";
import { useAuth } from "../Authentication/AuthContext";

//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// import i18n from "i18next";
import { useTranslation } from "react-i18next";

// Validation Rules
// let schema = yup.object().shape({
//   email: yup.string().required("Valid Email Required!"),
//   password: yup.string().required("Valid Password Required!"),
// });

//custom css
import "./login.css";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [currentLng, setCurrentLng] = useState("");

  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  // const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setFormErrors(validate(formValues));
  //   setIsSubmit(true);
  // };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const { login } = useAuth();

  useEffect(() => {
    // localStorage.removeItem("token");
    // console.log(document.cookie);
    //i18next=bn-BD
    // console.log(formErrors);

    // setCurrentLng(document.cookie);
    // dispatch(formFillUpReset());
    dispatch(resetSettingsData());
  }, [dispatch]);

  //Login form values
  const [radioToggle, setRadioToggle] = useState(true);
  // const [email, setEmail] = useState("");
  // const [mobile, setMobile] = useState("");
  // const [password, setPassword] = useState("");

  //Font awesome initialization
  const [eyeClose, setEyeClose] = useState(true);
  const eye = <FontAwesomeIcon icon={faEye} style={{ color: "#0979F9" }} />;
  const eyeSlash = (
    <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#0979F9" }} />
  );

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });

  // const toggleRadio = (e) => {
  //   setRadioToggle(!radioToggle);
  //   e.target.checked = radioToggle;
  //   // console.log(radioToggle);
  // };

  // const handleRecaptcha = (value) => {
  //   console.log("Captcha value:", value);
  // };
  //
  // const api = useHttp();

  const onSubmit = async (e) => {
    // console.log("On submit");
    e.preventDefault();
    setFormErrors(validate(formValues));

    // console.log(validate(formValues));

    // Object.keys(validate(formValues)).length === 0
    //   ? setIsSubmit(true)
    //   : setIsSubmit(false);

    // setIsSubmit(true);
    // setIsLoading(true);
    // e.preventDefault();

    if (Object.keys(validate(formValues)).length === 0) {
      // if (Object.keys(formErrors).length === 0 && isSubmit) {
      setIsLoading(true);
      const respStatus = await login(formValues.email, formValues.password); // calling the authentication login
      // console.log(respStatus);
      if (respStatus === 200) {
        setIsLoading(false);
        setLoginSuccess(true);
      } else {
        setIsLoading(false);
        setLoginFailed(true);
        setTimeout(() => setLoginFailed(false), 1000);
      }
    }

    // setTimeout(() => {
    //   setIsLoading(false);
    //   if (email === "demo@five-r.com" && password === "12345678")
    //     setLoginSuccess(true);
    //   else {
    //     setLoginFailed(true);
    //     setTimeout(() => setLoginFailed(false), 1000);
    //   }
    // }, 500)
    // setIsLoading(true);
  };

  const body = (
    <Background>
      <div className="card-layout-current">
        {/* <img src={Logo} alt="Main Logo" className="logo-login" /> */}
        <img src={AvSecID} alt="AvSecID Logo" className="logo-avsecID" />
        {/* <div className="page-title">{t("LOGIN.header")}</div> */}
        <div className="page-subtitle mb-3">{t("LOGIN.subHeader")}</div>

        <Form onSubmit={onSubmit}>
          {/* <Row>
      <Col> */}
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
              {t("LOGIN.email")}
              <span className="text-danger">*</span>{" "}
              {/* Email or Mobile Number<span className="text-danger">*</span>{" "} */}
            </Form.Label>
            <Form.Control
              name="email"
              type="text"
              placeholder=""
              value={formValues.email}
              onChange={handleChange}
              isInvalid={!!formErrors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.email}
            </Form.Control.Feedback>
            {/* <p className="text-sm">
              Either E-mail or Mobile Number With Country Code Like
              +8801712345678
            </p> */}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
              {t("LOGIN.password")}
              <span className="text-danger">*</span>{" "}
            </Form.Label>
            <InputGroup>
              <FormControl
                name="password"
                type={!eyeClose ? "text" : "password"}
                placeholder=""
                value={formValues.password}
                onChange={handleChange}
                isInvalid={!!formErrors.password}
              />
              <InputGroup.Text
                id="basic-addon1"
                onClick={() => setEyeClose(!eyeClose)}
              >
                {eyeClose ? eyeSlash : eye}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {formErrors.password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          {/* </Col>
            </Row> */}
          <Row className="mb-3 mt-3">
            {/* <Col md={6} className="d-flex">
              <Form.Check
                type="radio"
                onClick={toggleRadio}
                // value={radioToggle}
              />
              <Form.Check.Label className="ms-1">
                {t("LOGIN.rememberMe")}{" "}
              </Form.Check.Label>
            </Col> */}
            <Col className="text-end">
              <Link to="/forgetPassword">{t("LOGIN.forgetPassword")}</Link>
            </Col>
          </Row>
          {/* <ReCAPTCHA
            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={handleRecaptcha}
          /> */}
          {/* <div className="d-flex justify-content-between mt-3"> */}
          <Row className="mt-2">
            <Col>
              <Button
                className="col-12 mt-2 fw-bold"
                variant="primary"
                type="submit"
              >
                {t("LOGIN.loginBtn")}
              </Button>
            </Col>
            <Col>
              <Button
                className="col-12 mt-2 fw-bold"
                variant="primary"
                type="button"
                onClick={() => navigate("/registration")}
              >
                {t("LOGIN.signUpBtn")}
              </Button>
            </Col>
          </Row>

          {/* </div> */}
        </Form>
      </div>
    </Background>
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {loginSuccess ? (
            <Popup
              // navigateTo={"dashboard"}
              title={"Login"}
              subTitle={"Successful"}
              variety={"success"}
            >
              {body}
            </Popup>
          ) : loginFailed ? (
            <Popup
              title={"Oh Snap!"}
              subTitle={"You Have Entered Wrong Credentials"}
              variety={"error"}
            >
              {body}
            </Popup>
          ) : (
            <>{body}</>
          )}
        </>
      )}
    </>
  );
}

export default Login;
