import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { resetFormFillUp } from "../../redux/actions";
import { Link } from "react-router-dom";
import useHttp from "../../Http/useHttp";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

// image
import bd from "../../assets/images/flags/bd.svg";

import styles from "./ResetPasswordForm1.module.css";

function ResetPasswordForm1() {
  const api = useHttp();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("");
  const [input, setInput] = useState("");

  const [errors, setErrors] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const requestOTP = async (value) => {
    let formData = {
      email: selected === "email" ? value : null,
      mobile: selected === "mobile" ? `+88${value}` : null,
    };

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        const resp = await api.post("forget-password", formData);
        // console.log(resp.data.forgetPasswordToken);
        dispatch(
          resetFormFillUp({
            pageTitle: `${t("OTP.header")}`,
            pageSubTitle: `${t("OTP.secondaryHeader")}`,
            activeForm: "form2",
            formData: { ...formData, tempToken: resp.data.forgetPasswordToken },
          })
        );
        resolve();
      } catch (error) {
        if (error.response) {
          console.log(error.response);
          reject(error.response.data.message);
        } else reject("Unknown Error");
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: `OTP Successfully Sent to ${value.substr(0, 5)}*****`,
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const validateMobile = (value) => {
    const mobile = value;
    if (mobile) {
      if (!new RegExp(/^01[3-9][0-9]+$/).test(mobile)) {
        setErrors(true);
        setErrorMsg("Enter a valid Mobile Number");
      } else {
        setErrors(false);
        requestOTP(mobile);
      }
    } else {
      setErrors(true);
      setErrorMsg("Mobile Number is required");
    }
  };

  const validateEmail = (value) => {
    const email = value;
    if (email) {
      if (
        !new RegExp(
          /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/
        ).test(email)
      ) {
        setErrors(true);
        setErrorMsg("Enter a valid Email Address");
      } else {
        setErrors(false);
        // console.log("Request Server for OTP for Email");
        requestOTP(email);
        // dispatch(
        //   resetFormFillUp({
        //     pageTitle: `${t("OTP.header")}`,
        //     pageSubTitle: `${t("OTP.secondaryHeader")}`,
        //     activeForm: "form2",
        //     formData: {
        //       // email,
        //     },
        //   })
        // );
      }
    } else {
      setErrors(true);
      setErrorMsg("Email Address is required");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(input);
    if (selected === "mobile") validateMobile(input);
    else validateEmail(input);
  };

  // useEffect(() => {
  //   console.log(selected);
  // }, [selected]);

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Row className="my-3">
          <Col>
            <Form.Check
              type="radio"
              name="resetPassword"
              id="radioMobile"
              label={`${t("FORGETPASSWORD.radio.mobile")}`}
              className="fw-bold"
              onClick={() => {
                setSelected("mobile");
                setErrors(false);
              }}
            />
          </Col>
          <Col>
            <Form.Check
              type="radio"
              name="resetPassword"
              id="radioEmail"
              label={`${t("FORGETPASSWORD.radio.email")}`}
              className="fw-bold"
              onClick={() => {
                setSelected("email");
                setErrors(false);
              }}
            />
          </Col>
        </Row>

        {selected === "mobile" && (
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
              {t("FORGETPASSWORD.mobile.label")}
              <span className="text-danger">*</span>{" "}
            </Form.Label>

            <Row>
              <div className="col-2">
                <img
                  className={styles.createAdminFlag}
                  src={bd}
                  alt="BG Flag"
                />
              </div>

              <div className={`col-2 ${styles.countryCode}`}>
                <input type="text" defaultValue="+88" disabled={true} />
              </div>

              <div className="col-8">
                <Form.Control
                  type="text"
                  name="mobile"
                  maxLength="11"
                  placeholder={`${t("FORGETPASSWORD.mobile.placeholder")}`}
                  onChange={(e) => setInput(e.target.value)}
                  className={`${errors ? "is-invalid" : "was-validated"}`}
                  isInvalid={errors}
                />
                <Form.Control.Feedback type="invalid">
                  {errorMsg}
                </Form.Control.Feedback>
              </div>
            </Row>
          </Form.Group>
        )}

        {selected === "email" && (
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
              {t("FORGETPASSWORD.email.label")}
              <span className="text-danger">*</span>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder={`${t("FORGETPASSWORD.email.placeholder")}`}
              onChange={(e) => setInput(e.target.value)}
              className={`${errors ? "is-invalid" : "was-validated"}`}
              isInvalid={errors}
            />
            <Form.Control.Feedback type="invalid">
              {errorMsg}
            </Form.Control.Feedback>
          </Form.Group>
        )}

        <Button className="col-12 mt-2 fw-bold" variant="primary" type="submit">
          {t("FORGETPASSWORD.nextBtn")}
        </Button>

        <div className="text-center mt-2">
          <span className="text-md">
            {t("FORGETPASSWORD.please")}{" "}
            <Link to="/">{t("FORGETPASSWORD.click")}</Link>{" "}
            {t("FORGETPASSWORD.goBack")}
          </span>
        </div>
      </Form>
    </>
  );
}

export default ResetPasswordForm1;
