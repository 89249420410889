import React, { useState, useEffect } from "react";
import AvSecID from "../assets/images/avsecID.png";

import Background from "./Background/Background";
import StepWrapper from "./StepProgress/StepWrapper";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { formFillUpReset } from "../redux/actions";
import ResetPasswordForm1 from "./ResetPasswordForm/ResetPasswordForm1";
import ResetPasswordForm2 from "./ResetPasswordForm/ResetPasswordForm2";
import OTPForm from "./OTPForm/OTPForm";
import { useNavigate } from "react-router-dom";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

//custom css
import "./forgetPassword.css";

function ForgetPassword() {
  const { t } = useTranslation();
  const myCurrentStates = useSelector((state) => state.resetPasswordReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentLng, setCurrentLng] = useState("");

  const { pageTitle } = myCurrentStates;
  const { pageSubTitle } = myCurrentStates;
  const { activeForm } = myCurrentStates;

  useEffect(() => {
    // localStorage.removeItem("token");
    // console.log(document.cookie);
    //i18next=bn-BD
    setCurrentLng(document.cookie);
  }, []);

  return (
    <Background>
      {/* <input
        type="checkbox"
        id="forgetSwitch"
        className="forget-checkbox"
        checked={currentLng === "i18next=bn-BD" ? false : true}
        onChange={(ev) => {
          // console.log(ev.target.checked);
          // console.log(currentLng);

          // ev.target.checked
          if (currentLng === "i18next=en-US") {
            i18n.changeLanguage("bn-BD");
            setCurrentLng("i18next=bn-BD");
          }
          if (currentLng === "i18next=en-GB") {
            i18n.changeLanguage("bn-BD");
            setCurrentLng("i18next=bn-BD");
          }
          if (currentLng === "i18next=bn-BD") {
            i18n.changeLanguage("en-US");
            setCurrentLng("i18next=en-US");
          }
        }}
      /> */}
      {/* <label htmlFor="forgetSwitch" className="forget-toggle">
        <p className="forget-textLanguage">বাং EN</p>
      </label> */}

      <div className="card-layout-current">
        <img src={AvSecID} alt="AvSecID Logo" className="logo-avsecID" />
        {/* <div className="page-title">{pageTitle}</div> */}
        <div className="page-subtitle">{pageSubTitle}</div>
        <div className="step-container">
          <StepWrapper activeForm={activeForm} />
        </div>
        {activeForm === "form1" ? <ResetPasswordForm1 /> : null}
        {activeForm === "form2" ? <OTPForm for="resetPassword" /> : null}
        {activeForm === "form3" ? <ResetPasswordForm2 /> : null}
        {activeForm === "form4" ? (
          <>
            <p className="text-md fw-bold">
              {t("FORGETPASSWORDSUCCESSPOPUP.successText")}
            </p>
            <Button
              className="col-12 fw-bold mt-3"
              variant="primary"
              type="button"
              // onClick={() => navigate(-1)}
              onClick={() => {
                dispatch(formFillUpReset());
                navigate("/");
              }}
            >
              {t("FORGETPASSWORDSUCCESSPOPUP.loginBtn")}
            </Button>
          </>
        ) : null}
      </div>
    </Background>
  );
}

export default ForgetPassword;
