import React from "react";
import InputField from "../InputField/InputField";
import {
  settingsPresentAddress,
  settingsPermanentAddress,
  settingsCopyPermanentToPresent,
  resetSettingsPresentAddress,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

// custom style
import styles from "./AddressInfo.module.css";

const AddressInfo = ({ header, checkState, checkText }) => {
  const { t } = useTranslation();
  const myCurrentStates = useSelector((state) => state.settingsData.address);

  const dispatch = useDispatch();

  //   useEffect(() => {
  //     // console.log(myCurrentStates);
  //     myCurrentStates.permanentAddress.city &&
  //     myCurrentStates.permanentAddress.dist &&
  //     myCurrentStates.permanentAddress.zip &&
  //     myCurrentStates.permanentAddress.house &&
  //     myCurrentStates.permanentAddress.ps &&
  //     myCurrentStates.permanentAddress.street &&
  //     myCurrentStates.presentAddress.city &&
  //     myCurrentStates.presentAddress.dist &&
  //     myCurrentStates.presentAddress.zip &&
  //     myCurrentStates.presentAddress.house &&
  //     myCurrentStates.presentAddress.ps &&
  //     myCurrentStates.presentAddress.street
  //       ? validation(true)
  //       : validation(false);

  //   }, [myCurrentStates, validation]);

  const house = (inputData) => {
    if (checkState) {
      dispatch(
        settingsPresentAddress({
          house: inputData,
        })
      );
    } else {
      dispatch(
        settingsPermanentAddress({
          house: inputData,
        })
      );
    }
  };

  const street = (inputData) => {
    if (checkState) {
      dispatch(
        settingsPresentAddress({
          street: inputData,
        })
      );
    } else {
      dispatch(
        settingsPermanentAddress({
          street: inputData,
        })
      );
    }
  };

  const city = (inputData) => {
    if (checkState) {
      dispatch(
        settingsPresentAddress({
          city: inputData,
        })
      );
    } else {
      dispatch(
        settingsPermanentAddress({
          city: inputData,
        })
      );
    }
  };

  const ps = (inputData) => {
    if (checkState) {
      dispatch(
        settingsPresentAddress({
          ps: inputData,
        })
      );
    } else {
      dispatch(
        settingsPermanentAddress({
          ps: inputData,
        })
      );
    }
  };

  const dist = (inputData) => {
    if (checkState) {
      dispatch(
        settingsPresentAddress({
          dist: inputData,
        })
      );
    } else {
      dispatch(
        settingsPermanentAddress({
          dist: inputData,
        })
      );
    }
  };

  const zip = (inputData) => {
    if (checkState) {
      dispatch(
        settingsPresentAddress({
          zip: inputData,
        })
      );
    } else {
      dispatch(
        settingsPermanentAddress({
          zip: inputData,
        })
      );
    }
  };

  const defaultValueDecider = (propertyName) => {
    if (checkState) {
      switch (propertyName) {
        case "house":
          return myCurrentStates.presentAddress.house
            ? myCurrentStates.presentAddress.house
            : "";
        case "street":
          return myCurrentStates.presentAddress.street
            ? myCurrentStates.presentAddress.street
            : "";
        case "city":
          return myCurrentStates.presentAddress.city
            ? myCurrentStates.presentAddress.city
            : "";
        case "ps":
          return myCurrentStates.presentAddress.ps
            ? myCurrentStates.presentAddress.ps
            : "";
        case "dist":
          return myCurrentStates.presentAddress.dist
            ? myCurrentStates.presentAddress.dist
            : "";
        case "zip":
          return myCurrentStates.presentAddress.zip
            ? myCurrentStates.presentAddress.zip
            : "";

        default:
          return null;
      }
    } else {
      switch (propertyName) {
        case "house":
          return myCurrentStates.permanentAddress.house
            ? myCurrentStates.permanentAddress.house
            : "";
        case "street":
          return myCurrentStates.permanentAddress.street
            ? myCurrentStates.permanentAddress.street
            : "";
        case "city":
          return myCurrentStates.permanentAddress.city
            ? myCurrentStates.permanentAddress.city
            : "";
        case "ps":
          return myCurrentStates.permanentAddress.ps
            ? myCurrentStates.permanentAddress.ps
            : "";
        case "dist":
          return myCurrentStates.permanentAddress.dist
            ? myCurrentStates.permanentAddress.dist
            : "";
        case "zip":
          return myCurrentStates.permanentAddress.zip
            ? myCurrentStates.permanentAddress.zip
            : "";

        default:
          return null;
      }
    }
  };

  return (
    <>
      <div className={styles.header}>{header}</div>
      <hr />
      <div className="row">
        {checkState ? (
          <div className="mt-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              onChange={(e) =>
                e.target.checked
                  ? dispatch(settingsCopyPermanentToPresent())
                  : dispatch(resetSettingsPresentAddress())
              }
            />
            <label className="form-check-label ms-2">{checkText}</label>
          </div>
        ) : null}

        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.addressForm.houseNumber.label")}`}
            name="houseNumber"
            placeholder={`${t("SETTINGS.addressForm.houseNumber.placeholder")}`}
            defaultValue={defaultValueDecider("house")}
            execute={house}
          />
        </div>

        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.addressForm.street.label")}`}
            name="roadNumber"
            placeholder={`${t("SETTINGS.addressForm.street.placeholder")}`}
            defaultValue={defaultValueDecider("street")}
            execute={street}
          />
        </div>

        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.addressForm.city.label")}`}
            name="city"
            placeholder={`${t("SETTINGS.addressForm.city.placeholder")}`}
            defaultValue={defaultValueDecider("city")}
            execute={city}
          />
        </div>

        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.addressForm.policeStation.label")}`}
            name="policeStation"
            placeholder={`${t(
              "SETTINGS.addressForm.policeStation.placeholder"
            )}`}
            defaultValue={defaultValueDecider("ps")}
            execute={ps}
          />
        </div>
        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                type="text"
                label={`${t("SETTINGS.addressForm.district.label")}`}
                name="district"
                placeholder={`${t(
                  "SETTINGS.addressForm.district.placeholder"
                )}`}
                defaultValue={defaultValueDecider("dist")}
                execute={dist}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                type="text"
                label={`${t("SETTINGS.addressForm.postCode.label")}`}
                name="postCode"
                placeholder={`${t(
                  "SETTINGS.addressForm.postCode.placeholder"
                )}`}
                defaultValue={defaultValueDecider("zip")}
                execute={zip}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressInfo;
