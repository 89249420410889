import React, { useState } from "react";
// components
import SettingTitle from "./Utilities/SettingTitle/SettingTitle";
import GeneralPage from "./Pages/GeneralPage/GeneralPage";
import AddressPage from "./Pages/AddressPage/AddressPage";
import PasswordPage from "./Pages/PasswordPage/PasswordPage";
import UploadPage from "./Pages/UploadPage/UploadPage";
// bootstraps
// import { Tabs, Tab } from "react-bootstrap";

import { useTranslation } from "react-i18next";

// custom style
import styles from "./settings.module.css";
function Settings() {
  const { t } = useTranslation();
  const [key, setKey] = useState("general");
  const tabLists = [
    { title: `${t("SETTINGS.tabs.general")}`, path: "general"},
    { title: `${t("SETTINGS.tabs.address")}`, path: "address"},
    { title: `${t("SETTINGS.tabs.password")}`, path: "password"},
    { title: `${t("SETTINGS.tabs.upload")}`, path: "upload"},
  ];
  return (
    <div className={styles.content}>
      <div className={styles.innerContent}>
        <SettingTitle
          header={`${t("SETTINGS.title")}`}
          subTitle={`${t("SETTINGS.subTitle")}`}
        />
        <div className="row">
          <div className="col-12">
            {/* <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="tabs-custom mb-3"
            >
              <Tab eventKey="general" title="General">
                <GeneralPage />
              </Tab>
              <Tab eventKey="address" title="Address">
                <AddressPage />
              </Tab>
              <Tab eventKey="password" title="Password">
                <PasswordPage />
              </Tab>
              <Tab eventKey="upload" title="Upload">
                <UploadPage />
              </Tab>
            </Tabs> */}

            {/* =================================================================== */}
            <ul className={styles.tabs}>
              {tabLists.map((item, index) => {
                return (
                  <li className={styles.tabItem} key={index}>
                    <span
                      className={`${styles.tabLink} ${
                        key === item.path ? styles.tabActive : null
                      }`}
                      onClick={() => {
                        setKey(item.path);
                      }}
                    >
                      {item.title}
                    </span>
                  </li>
                );
              })}
            </ul>
            {key === "general" && <GeneralPage />}
            {key === "address" && <AddressPage />}
            {key === "password" && <PasswordPage />}
            {key === "upload" && <UploadPage />}
            {/* ================================================================= */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
