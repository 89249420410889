import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { settingsData } from "../../../../../../redux/actions";
import { useAuth } from "../../../../../../Authentication/AuthContext";
import useHttp from "../../../../../../Http/useHttp";
import { toast } from "react-toastify";

import ImageUpload from "../../../../../../Utilities/ImageUpload/ImageUpload";
import ImageHolder from "../../Utilities/ImageHolder/ImageHolder";
import InputField from "../../../../../../Utilities/InputField/InputField";
import DateInput from "../../../../../../Utilities/DateInput/DateInput";
import RadioLabel from "../../../../../../Utilities/RadioLabel/RadioLabel";
import Selector from "../../../../../../Utilities/Selector/Selector";

import { useTranslation } from "react-i18next";

// custom style
import styles from "./GeneralPage.module.css";

const GeneralPage = () => {
  const { t } = useTranslation();
  const api = useHttp("authorized");
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const myApplication = useSelector((state) => state.settingsData);

  const bloodTypes = [
    { label: `${t("SETTINGS.generalForm.bloodGroup.placeholder")}`, value: "" },
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
  ];

  const textUploadSignature = (
    <>
      {t("SETTINGS.imageAdviceText.upload")}{" "}
      <span className={styles.boldText}>
        {t("SETTINGS.imageAdviceText.jpg")}
      </span>{" "}
      {t("SETTINGS.imageAdviceText.or")}{" "}
      <span className={styles.boldText}>
        {t("SETTINGS.imageAdviceText.png")}
      </span>{" "}
      {t("SETTINGS.imageAdviceText.file")}{" "}
      <span className={styles.boldText}>
        {t("SETTINGS.imageAdviceText.size")}.
      </span>
    </>
  );

  const updateGeneralInfo = async () => {
    const formData = {
      name: myApplication.name,
      // designation: myApplication.designation,
      designation: "",
      fathersName: myApplication.fathersName,
      mothersName: myApplication.mothersName,
      spouseName: myApplication.spouseName,
      nationality: myApplication.nationality,
      dob: myApplication.dob,
      gender: myApplication.gender,
      nid: myApplication.nid,
      // mobile: myApplication.mobile,
      bloodGroup: myApplication.bloodGroup,
      // email: myApplication.email,
    };

    if (!formData.name) {
      toast.warn("Name Can Not Be Blank", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const resolveAfterResponse = new Promise(async (resolve, reject) => {
      try {
        // const resp =
        await api.post("settings/general-update", formData);
        // console.log(resp.data);

        const savedUser = JSON.parse(localStorage.getItem("user"));
        const updatedUser = {
          ...savedUser,
          ...formData,
        };
        // console.log(updatedUser);
        localStorage.setItem("user", JSON.stringify(updatedUser));
        resolve();
      } catch (error) {
        console.log(error.response);
        if (error.response.data.status === 401) {
          reject("Not Authorized Please Login");
          logout();
        } else reject(error.response.data.message);
      }
    });

    toast.promise(
      resolveAfterResponse,
      {
        pending: "Please Wait...",
        success: "Information Updated",
        error: {
          render({ data }) {
            return data;
          },
        },
      },
      {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const name = (inputData) => {
    dispatch(
      settingsData({
        name: inputData,
      })
    );
  };

  const designation = (inputData) => {
    dispatch(
      settingsData({
        designation: inputData,
      })
    );
  };

  const fathersName = (inputData) => {
    dispatch(
      settingsData({
        fathersName: inputData,
      })
    );
  };

  const mothersName = (inputData) => {
    dispatch(
      settingsData({
        mothersName: inputData,
      })
    );
  };

  const spouseName = (inputData) => {
    dispatch(
      settingsData({
        spouseName: inputData,
      })
    );
  };

  const nationality = (inputData) => {
    dispatch(
      settingsData({
        nationality: inputData,
      })
    );
  };

  const nid = (inputData) => {
    dispatch(
      settingsData({
        nid: inputData,
      })
    );
  };

  const dob = (inputData) => {
    dispatch(
      settingsData({
        dob: inputData,
      })
    );
  };

  // const mobile = (inputData) => {
  //   dispatch(
  //     settingsData({
  //       mobile: inputData,
  //     })
  //   );
  // };

  // const email = (inputData) => {
  //   dispatch(
  //     settingsData({
  //       email: inputData,
  //     })
  //   );
  // };

  const bloodGroup = (selectedData) => {
    dispatch(
      settingsData({
        bloodGroup: selectedData,
      })
    );
  };

  const gender = (selectedData) => {
    dispatch(
      settingsData({
        gender: selectedData,
      })
    );
  };

  const updateSavedUser = (latestSignature) => {
    const savedUser = JSON.parse(localStorage.getItem("user"));
    const latestUserSignature = {
      ...savedUser,
      signature: latestSignature,
    };
    // console.log(latestUserSignature);
    localStorage.setItem("user", JSON.stringify(latestUserSignature));
  };

  const signature = (url) => {
    console.log(url);
    updateSavedUser(url);
    if (url) {
      dispatch(
        settingsData({
          signature: url,
        })
      );
    } else {
      dispatch(
        settingsData({
          signature: "",
        })
      );
    }
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-12 mt-3">
          <ImageHolder label={`${t("SETTINGS.generalForm.image.label")}`} />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.generalForm.name.label")}`}
            name="name"
            placeholder={`${t("SETTINGS.generalForm.name.placeholder")}`}
            defaultValue={myApplication.name ? myApplication.name : ""}
            execute={name}
            maxLen="30"
          />
        </div>
        {/* <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.generalForm.designation.label")}`}
            name="designation"
            placeholder={`${t("SETTINGS.generalForm.designation.placeholder")}`}
            defaultValue={
              myApplication.designation ? myApplication.designation : ""
            }
            execute={designation}
            maxLen="20"
          />
        </div> */}
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.generalForm.fatherName.label")}`}
            name="father_name"
            placeholder={`${t("SETTINGS.generalForm.fatherName.placeholder")}`}
            defaultValue={
              myApplication.fathersName ? myApplication.fathersName : ""
            }
            execute={fathersName}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.generalForm.motherName.label")}`}
            name="mother_name"
            placeholder={`${t("SETTINGS.generalForm.motherName.placeholder")}`}
            defaultValue={
              myApplication.mothersName ? myApplication.mothersName : ""
            }
            execute={mothersName}
          />
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.generalForm.spouseName.label")}`}
            name="spouse_name"
            placeholder={`${t("SETTINGS.generalForm.spouseName.placeholder")}`}
            defaultValue={
              myApplication.spouseName ? myApplication.spouseName : ""
            }
            execute={spouseName}
          />
        </div>
        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                type="text"
                label={`${t("SETTINGS.generalForm.nationality.label")}`}
                name="nationality"
                placeholder={`${t(
                  "SETTINGS.generalForm.nationality.placeholder"
                )}`}
                defaultValue={
                  myApplication.nationality ? myApplication.nationality : ""
                }
                execute={nationality}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                type="text"
                label={`${t("SETTINGS.generalForm.nationalIDNumber.label")}`}
                name="national_id_number"
                placeholder={`${t(
                  "SETTINGS.generalForm.nationalIDNumber.placeholder"
                )}`}
                defaultValue={myApplication.nid ? myApplication.nid : ""}
                execute={nid}
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <DateInput
                label={`${t("SETTINGS.generalForm.dateOfBirth.label")}`}
                name="dob"
                defaultValue={myApplication.dob ? myApplication.dob : ""}
                execute={dob}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <InputField
                type="text"
                label={`${t("SETTINGS.generalForm.mobileNumber.label")}`}
                name="mobile_number"
                placeholder={`${t(
                  "SETTINGS.generalForm.mobileNumber.placeholder"
                )}`}
                defaultValue={myApplication.mobile ? myApplication.mobile : ""}
                // execute={mobile}
                disable
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <div className={styles.genderArea}>
                <label className={`form-label ${styles.genderLabel}`}>{`${t(
                  "SETTINGS.generalForm.gender.label"
                )}`}</label>
                <div className="row mt-1">
                  <div className="col-md-4 col-12">
                    <RadioLabel
                      label={`${t("SETTINGS.generalForm.gender.male")}`}
                      id="gender"
                      value="Male"
                      name="gender"
                      defaultValue={
                        myApplication.gender ? myApplication.gender : ""
                      }
                      execute={gender}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <RadioLabel
                      label={`${t("SETTINGS.generalForm.gender.female")}`}
                      id="gender"
                      value="Female"
                      name="gender"
                      defaultValue={
                        myApplication.gender ? myApplication.gender : ""
                      }
                      execute={gender}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <RadioLabel
                      label={`${t("SETTINGS.generalForm.gender.other")}`}
                      id="gender"
                      value="Others"
                      name="gender"
                      defaultValue={
                        myApplication.gender ? myApplication.gender : ""
                      }
                      execute={gender}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <Selector
                label={`${t("SETTINGS.generalForm.bloodGroup.label")}`}
                placeholder={`${t(
                  "SETTINGS.generalForm.bloodGroup.placeholder"
                )}`}
                listItem={bloodTypes}
                defaultValue={
                  myApplication.bloodGroup ? myApplication.bloodGroup : ""
                }
                execute={bloodGroup}
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <InputField
            type="text"
            label={`${t("SETTINGS.generalForm.emailAddress.label")}`}
            name="emailAddress"
            placeholder={`${t(
              "SETTINGS.generalForm.emailAddress.placeholder"
            )}`}
            defaultValue={myApplication.email ? myApplication.email : ""}
            // execute={email}
            disable
          />
        </div>
        <div className="col-12 mt-3">
          <div className={styles.pictureArea}>
            <label className={`form-label ${styles.pictureLabel}`}>
              {`${t("SETTINGS.generalForm.uploadSignature.label")}`}
            </label>
            {/* <ImageUploader textAdvise={textUploadSignature} /> */}
            <ImageUpload
              instruction={textUploadSignature}
              execute={signature}
              // settingSignature={true}
              endpoint="settings/signature-update"
              formData={{ signature: "" }}
              dataKey="signature"
              defaultValue={myApplication.signature}
            />
          </div>
        </div>
        <div className="col-12 mt-5">
          <button
            type="submit"
            className={`btn ${styles.button} float-end`}
            onClick={() => updateGeneralInfo()}
          >
            {t("SETTINGS.updateBtn")}
          </button>
        </div>
      </div>
    </>
  );
};

export default GeneralPage;
