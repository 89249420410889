import React from "react";
import { useState } from "react";

// custom style
import styles from "./Selector.module.css";

const Selector = ({
  label,
  listItem,
  placeholder,
  defaultValue,
  execute,
  disable,
  isMandatory = true,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    execute(e.target.value);
  };

  return (
    <>
      <div className={styles.contentArea}>
        <label className={`form-label ${styles.title} text-start`}>
          {label}
        </label>
        {isMandatory && <span className="text-danger">*</span>}
        <select
          className={`form-select form-control ${styles.input}`}
          aria-label={placeholder}
          placeholder={placeholder}
          disabled={disable}
          value={selectedValue ? selectedValue : defaultValue}
          onChange={handleChange}
        >
          {listItem.map((c) => (
            <option className={styles.option} key={c.value} value={c.value}>
              {c.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default Selector;
